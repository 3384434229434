import React from "react";
import useSheet from "react-jss";
import { MenuItem, NavDropdown, NavItem } from "react-bootstrap";
import { bootstrap } from "toetag";
import { openSansStack } from "../typography";
import CheckoutCount from "../checkout/badge";

const style = {
	dropdown: {
		...openSansStack,
		padding: "10px 0px 10px 0px",
		"&>a:focus, &>a:not(:hover)": {
			backgroundColor: "transparent !important"
		},
		"&>ul:focus": {
			backgroundColor: "#525252 !important"
		},
		"&>a": {
			color: "white",
			...openSansStack,
			fontSize: "18px",
			textDecoration: "none",
			"&:hover": {
				backgroundColor: "transparent !important"
			}
		},
		"&>ul>li": {
			backgroundColor: "#525252"
		},
		"&>ul>li>a": {
			color: "#e7e7e7 !important",
			whiteSpace: "normal !important",
			paddingBottom: `${bootstrap.navbarPaddingVertical} !important`
		}
	},
	header: {
		...openSansStack,
		padding: "10px 0px 10px 0px",
		"&>a": {
			color: "white",
			fontSize: "18px"
		}
	},
	subHeader: {
		float: "left",
		color: "#6eb5e5"
	},
	search: {
		padding: "10px 0px 10px 0px"
	},
	shoppingCart: {
		padding: "10x 0px 10px 0px",
		composes: "fa-2x app-shopping-cart-icon"
	}
};

const MobileMenu = ({ tabItems, classes, shoppingCart, user }) => {
	if (!tabItems) return null;
	return (
		<div>
			{tabItems.map((link, idx) => {
				const cartLink =
					link.text === "Shopping Cart" ? (
						<span className="text-phrase">
							<span className="fa-stack">
								<i className={shoppingCart} />
								<CheckoutCount />
							</span>
						</span>
					) : null;

				return link.links && !link.search ? (
					<NavDropdown
						title={link.text}
						id={idx}
						className={classes.dropdown}
						key={link.text}
					>
						{link.links
							.reduce((a, b) => {
								// flatten links
								if (
									(!user && b.text === "Bidding") ||
									(!user && b.text === "Purchases")
								) {
									return a;
								}
								return [...a, ...(b.links ? [[...b.text], ...b.links] : [b])];
							}, [])
							.map(
								subLink =>
									!subLink.href ? (
										<MenuItem
											header
											key={subLink}
											className={classes.subHeader}
										>
											{subLink}
										</MenuItem>
									) : (
										<MenuItem key={subLink.text} href={subLink.href}>
											{subLink.text}
										</MenuItem>
									)
							)}
					</NavDropdown>
				) : !link.search ? (
					!user && link.text === "Shopping Cart" ? null : (
						<NavItem
							href={link.href}
							key={link.text}
							className={link.text === "Shopping Cart" ? null : classes.header}
						>
							{!link.icon ? link.text : cartLink}
						</NavItem>
					)
				) : (
					<a>{link.links[0].text}</a>
				);
			})}
		</div>
	);
};

export default useSheet(style)(MobileMenu);
