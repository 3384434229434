import * as api from "../api/admin";

import { createAjaxAction } from "redux-rsi";
import { getCurrentUser } from "@civicsource/users";

import { getUsersCurrentBidderListTermsByBidderListId } from "../reducers";

export function fetchCurrentBidderListTermsForUser(
	taxAuthority,
	bidderListId,
	userId
) {
	return createAjaxAction(
		{
			type: "FETCH_USER_CURRENT_BIDDER_LIST_TERMS",
			payload: { bidderListId, userId }
		},
		getState => {
			const state = getState();
			const terms = getUsersCurrentBidderListTermsByBidderListId(
				state,
				bidderListId
			);

			if (terms.isLoading) return;

			return api.fetchCurrentBidderListTermsForUser(
				taxAuthority,
				bidderListId,
				userId,
				getCurrentUser(state)
			);
		}
	);
}

export function agreeToBidderListTerms(
	taxAuthority,
	bidderListId,
	userId,
	termsId,
	lotNumber
) {
	return createAjaxAction(
		{
			type: "AGREE_TO_BIDDER_LIST_TERMS",
			payload: { bidderListId, userId, termsId, lotNumber }
		},
		getState => {
			const state = getState();
			const terms = getUsersCurrentBidderListTermsByBidderListId(
				state,
				bidderListId
			);

			if (terms.isAgreeingToTerms) return;

			return api.agreeToBidderListTerms({
				taxAuthority,
				bidderListId,
				userId,
				termsId,
				lotNumber,
				auth: getCurrentUser(state)
			});
		}
	);
}
