import React from "react";
import { PropTypes as t } from "prop-types";

const AlertBox = props => {
	const message = props.message;
	const severity = message.severity || "info";
	const isRichSubject = typeof message.subject !== "string";
	const isRichBody = typeof message.body !== "string";
	return (
		<div className={`alert alert-${severity}`}>
			{isRichSubject ? (
				message.subject
			) : (
				<span className="h3">
					{message.subject}
					<br />
				</span>
			)}
			{isRichBody ? message.body : <p>{message.body}</p>}
			{props.children}
		</div>
	);
};

AlertBox.propTypes = {
	message: t.shape({
		date: t.any,
		subject: t.oneOfType([t.string, t.element]).isRequired,
		body: t.oneOfType([t.string, t.element]),
		severity: t.oneOf(["success", "danger", "info", "warning"])
	}).isRequired
};

AlertBox.defaultProps = {
	message: {
		severity: "info",
		subject: "",
		body: ""
	}
};

export default AlertBox;
