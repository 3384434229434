import ko from "knockout";
import user from "./../user";

function Model(auction) {
	this.isAuthenticated = user ? true : false;

	this.feature = ko.computed(function() {
		var value = !auction.address.address1()
			? "No Address"
			: auction.address.formatted();
		return value;
	}, this);

	this.subFeature = ko.computed(function() {
		return auction.lotNumber();
	}, this);

	this.startingBid = ko.observable().extend({
		bidAmount: auction.bidding.isTraditional
	});
	this.currentBid = ko.observable();

	this.disabledReason = ko.computed(function() {
		if (!user) {
			return "NotLoggedIn";
		}

		if (user.disabledReason()) {
			return user.disabledReason();
		}

		if (auction.seller.username() === user.username()) {
			return "Seller";
		}

		if (
			!auction.bidding.isTraditional() &&
			this.currentBid() &&
			this.currentBid().amount() <= 0.01
		) {
			return "HighestBid";
		}
	}, this);

	this.note = ko.observable();
	this.isFavorite = ko.observable();
	this.isPurchaser = ko.observable(false);
	this.isInCart = ko.observable();
	this.isDepositor = ko.observable(false);
	this.isNotDepositor = ko.observable(false);
	this.bidderListDenialReason = ko.observable();
	this.bidderListUserId = ko.observable();
	this.hasAgreedToLatestTerms = ko.observable(false);
	this.canPlaceBids = ko.observable();
	this.actionRequired = ko.observable();
	this.bidderListActionRequired = ko.observable();
	this.bankAccountRequired = ko.observable();
	this.driversLicenseRequired = ko.observable();
	this.bidderListStatus = ko.observable();
}

export default Model;
