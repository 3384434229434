import ko from "knockout";
import WatchList from "./../summary";
import { some } from "lodash";

function ViewModel() {
	this.auction = ko.observable();
	this.lists = ko.observableArray();

	this.popText = ko.observable();

	this.newListName = ko.observable();

	this.isLoading = ko.observable(true);

	ko
		.computed(function() {
			return some(this.lists(), function(list) {
				return list.isInList();
			});
		}, this)
		.subscribe(
			function(value) {
				this.auction().user.isFavorite(value);
			}.bind(this)
		);
}

ViewModel.prototype.addToNewList = function() {
	if (this.newListName()) {
		WatchList.addNew(this.auction(), this.newListName()).done(
			function(list) {
				if (this.lists().indexOf(list) < 0) {
					this.lists.push(list);
				}

				list.isInList(true);

				this.newListName("");
			}.bind(this)
		);
	}
};

ViewModel.prototype.refresh = function() {
	if (this.auction()) {
		this.isLoading(true);

		WatchList.getByAuction(this.auction()).done(
			function(lists) {
				this.lists(lists);
				this.isLoading(false);
			}.bind(this)
		);
	}
};

export default ViewModel;
