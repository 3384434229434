import React from "react";
import cx from "classnames";
import msg from "./message-config";
import style from "./index.style";

const GoingMain = ({ classes, ...props }) => {
	const { auction } = props;

	if (!auction.isBiddingInfoLoaded) {
		return null;
	}

	const isEndingSoon = auction.isGoingOnce || auction.isGoingTwice;
	const isExtended =
		auction.schedule &&
		auction.schedule.endTime !== auction.schedule.originalEndTime;
	const isWinning = auction.winningBid && auction.winningBid.isYourBid;
	// non-participants are losers as far as we're concerned
	const isLosing =
		!auction.isEnded &&
		(!auction.winningBid ||
			(auction.winningBid && !auction.winningBid.isYourBid));

	const goingHtmlClasses = cx({
		[classes.base]: true,
		[classes.soon]: isEndingSoon,
		[classes.ended]: auction.isEnded,
		[classes.jumbo]: props.jumbo,
		[classes.winning]: isWinning,
		[classes.losing]: isLosing && auction.userCurrentBid
	});

	const goingStatus = evalGoingStatus(auction, isExtended);

	const goingShort = msg[goingStatus] ? msg[goingStatus].subject : null;
	const goingShortFormatted = props.jumbo ? (
		<h3 className={classes.heading}>{goingShort}</h3>
	) : (
		goingShort
	);

	const goingDescription =
		msg[goingStatus] && props.jumbo ? msg[goingStatus].body : null;

	return goingStatus ? (
		<div
			className={`${goingHtmlClasses} ${
				msg[goingStatus] ? classes[msg[goingStatus].htmlClass] : ""
			}`}
		>
			{goingShortFormatted} {goingDescription}
		</div>
	) : null;
};

const evalGoingStatus = (
	{
		isCanceled,
		isEnded,
		winningBid,
		userCurrentBid,
		isGoingOnce,
		isGoingTwice
	},
	isExtended
) => {
	if (isCanceled) {
		return "CANCELED";
	}

	if (isEnded) {
		if (!winningBid) {
			return "NOT_SOLD";
		}

		if (winningBid.isYourBid) {
			return "WON";
		}

		if (userCurrentBid) {
			return "LOST";
		}

		return "SOLD";
	}

	if (isGoingOnce) {
		return "GOING_ONCE";
	}

	if (isGoingTwice) {
		return "GOING_TWICE";
	}

	if (isExtended) {
		return "EXTENDED";
	}

	return null;
};

export default style(GoingMain);
