import { getConnection, getListeners } from "../channel/reducer";
import * as socket from ".";

import { auctionBiddingStatusChanged } from "../auction/actions";
import * as bid from "../bid/actions";
import * as channel from "../channel/actions";

import * as batcher from "./batcher";

export const PING_TIMEOUT = 20000; // ping after 20 seconds of inactivity
export const PING_RESPONSE_THRESHOLD = 3000; // after 3 seconds with no ping response, set the chronic threshold

let isSocketInitialized = false;

let chronicTimer = null;
let pingTimer = null;
let pingResponseTimer = null;

const MESSAGE_ACTION_TYPES = [
	"AUCTION_STATUS_CHANGED",
	"BID_ACCEPTED",
	"BID_REJECTED",
	"BID_CANCELED",
	"BID_NOT_CANCELED",
	"CHANNEL_PING_RECEIVED"
];

export default store => next => action => {
	if (!isSocketInitialized) {
		initSocket(store.dispatch);
		isSocketInitialized = true;
	}

	let result = next(action);

	const state = store.getState();
	const lotNumbersToListenFor = getListeners(state);
	const connection = getConnection(state);

	if (Array.isArray(action)) {
		action.forEach(ac => {
			handleAction(ac, lotNumbersToListenFor, connection, store.dispatch);
		});
	} else {
		handleAction(action, lotNumbersToListenFor, connection, store.dispatch);
	}

	return result;
};

function initSocket(dispatch) {
	batcher.init(dispatch);

	socket.addListeners({
		AuctionStatus: msg => batcher.queue(auctionBiddingStatusChanged(msg)),
		BidAccepted: msg => batcher.queue(bid.bidAccepted(msg)),
		BidRejected: msg => batcher.queue(bid.bidRejected(msg)),
		BidCanceled: msg => batcher.queue(bid.bidCanceled(msg)),
		BidNotCanceled: msg => batcher.queue(bid.bidNotCanceled(msg)),
		Ping: msg => batcher.queue(channel.pingReceived(msg)),
		opened: () => batcher.queue(channel.channelOpened()),
		closed: () => batcher.queue(channel.channelClosed())
	});
}

function handleAction(action, lotNumbersToListenFor, connection, dispatch) {
	if (action.type == "CHANNEL_OPENED") {
		onChannelOpened(lotNumbersToListenFor, connection, dispatch);
	}

	if (action.type == "CHANNEL_CLOSED") {
		onChannelClosed(lotNumbersToListenFor, connection, dispatch);
	}

	if (MESSAGE_ACTION_TYPES.indexOf(action.type) >= 0) {
		onAnyMessageReceived(lotNumbersToListenFor, connection, dispatch);
	}
}

function onChannelOpened(lotNumbersToListenFor, connection, dispatch) {
	if (chronicTimer) {
		clearTimeout(chronicTimer);
		chronicTimer = null;
	}

	resetPingTimer(connection, dispatch);
}

function onChannelClosed(lotNumbersToListenFor, connection, dispatch) {
	clearPingTimer();

	if (lotNumbersToListenFor.length < 1) {
		// this is expected; don't worry about it
		return;
	}

	// start the chronic problem timer
	if (!chronicTimer) {
		chronicTimer = setTimeout(() => {
			dispatch(channel.channelProblem());
		}, 5000);
	}

	// http://blog.johnryding.com/post/78544969349/how-to-reconnect-web-sockets-in-a-realtime-web-app
	var time = generateInterval(connection.connectionAttempts);

	setTimeout(() => {
		dispatch(channel.tryOpenChannel());
	}, time);

	function generateInterval(k) {
		var maxInterval = (Math.pow(2, k) - 1) * 1000;

		if (maxInterval > 15 * 1000) {
			maxInterval = 15 * 1000; // If the generated interval is more than 15 seconds, truncate it down to 15 seconds.
		}

		// generate the interval to a random number between 0 and the maxInterval determined from above
		return Math.random() * maxInterval;
	}
}

function onAnyMessageReceived(auctionIdsToListenFor, connection, dispatch) {
	resetPingTimer(connection, dispatch);
}

function clearPingTimer() {
	if (pingTimer) {
		clearTimeout(pingTimer);
		pingTimer = null;
	}
}

function resetPingTimer(connection, dispatch) {
	clearPingTimer();
	clearPingResponseTimer();

	pingTimer = setTimeout(() => {
		if (connection.isConnected) {
			socket.send({
				type: "Ping",
				body: "hello?"
			});
			resetPingResponseTimer(dispatch);
		}
	}, PING_TIMEOUT);
}

function clearPingResponseTimer() {
	if (pingResponseTimer) {
		clearTimeout(pingResponseTimer);
		pingResponseTimer = null;
	}
}

function resetPingResponseTimer(dispatch) {
	clearPingResponseTimer();

	pingResponseTimer = setTimeout(() => {
		dispatch(channel.channelProblem());
	}, PING_RESPONSE_THRESHOLD);
}
