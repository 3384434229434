import { createReducer, mergeWithCurrent } from "redux-rsi";
import Immutable from "seamless-immutable";
import { normalize } from "../../normalize";

export default createReducer(Immutable({}), {
	onBidHistoryFetch(state, lotNumber) {
		return mergeWithCurrent(
			state,
			normalize(lotNumber),
			{
				isLoading: true,
				isError: false
			},
			init
		);
	},

	onBidHistoryFetchCompleted(state, bids, lotNumber) {
		return mergeWithCurrent(
			state,
			normalize(lotNumber),
			{
				isLoading: false,
				isLoaded: true,
				ids: bids.map(b => b.id)
			},
			init
		);
	},

	onBidHistoryFetchFailed(state, err, lotNumber) {
		return mergeWithCurrent(
			state,
			normalize(lotNumber),
			{
				isLoading: false,
				isError: true
			},
			init
		);
	},

	onBidHistoryFetchMore(state, { lotNumber }) {
		return mergeWithCurrent(
			state,
			normalize(lotNumber),
			{
				isLoading: true,
				isErrorLoadingMore: false
			},
			init
		);
	},

	onBidHistoryFetchMoreCompleted(state, bids, { lotNumber }) {
		const current = state[normalize(lotNumber)] || init();

		return state.set(
			normalize(lotNumber),
			current.merge({
				isLoaded: true,
				isLoading: false,
				ids: [...current.ids, ...bids.map(b => b.id)]
			})
		);
	},

	onBidHistoryFetchMoreFailed(state, err, { lotNumber }) {
		return mergeWithCurrent(
			state,
			normalize(lotNumber),
			{
				isLoading: false,
				isErrorLoadingMore: true
			},
			init
		);
	},

	onAuctionBiddingStatusChanged(state, { lotNumber, latestBids }) {
		// just replace whatever data we have with the new auction status data
		const history = Immutable({
			isLoading: false,
			isLoaded: true,
			isError: false,
			ids: latestBids.map(b => b.id)
		});

		return state.set(normalize(lotNumber), history);
	},

	onBidCanceled(state, data) {
		return this.onAuctionBiddingStatusChanged(state, data);
	},

	onBidAccepted(state, data) {
		return this.onAuctionBiddingStatusChanged(state, data);
	}
});

function init() {
	return Immutable({
		isLoading: false,
		isLoaded: false,
		isError: false,
		isErrorLoadingMore: false,
		ids: []
	});
}

export function getBidHistory(state, lotNumber) {
	return state[normalize(lotNumber)] || init();
}
