define(['jquery'], function ($) {
	$.fn.popbox = function (options) {
		var settings = $.extend({
			selector: this.selector,
			open: '.popbox-open',
			box: '.popbox-box',
			arrow: '.popbox-arrow',
			arrow_border: '.popbox-arrow-border',
			close: '.popbox-close'
		}, options);

		var methods = {
			open: function (event) {
				event.preventDefault();

				//close any other open popups first
				methods.close();

				var pop = $(this);
				var box = $(this).parent().find(settings['box']);

				box.find(settings['arrow']).css({ 'left': box.width() / 2 - 10 });
				box.find(settings['arrow_border']).css({ 'left': box.width() / 2 - 10 });

				if (box.css('display') == 'block') {
					methods.close();
				} else {
					box.trigger('show.popbox');
					box.fadeIn("fast", function () {
						box.trigger('shown.popbox');
					});
					box.css({ 'display': 'block', 'top': 10, 'left': ((pop.parent().width() / 2) - box.width() / 2) });

					if (settings.openHandler) {
						settings.openHandler.call(pop, box);
					}
				}
			},

			close: function () {
				var box = $(settings['box']).filter(':visible');
				box.trigger('hide.popbox');
				box.fadeOut("fast", function () {
					box.trigger('hidden.popbox');
				});
			}
		};

		$(document).bind('keyup', function (event) {
			if (event.keyCode == 27) {
				methods.close();
			}
		});

		$(document).bind('click', function (event) {
			if (!$(event.target).closest(settings['selector']).length) {
				methods.close();
			}
		});

		return this.each(function () {
			$(settings['open'], this).bind('click', methods.open);
			$(settings['open'], this).parent().find(settings['close']).bind('click', methods.close);
		});
	}
});