import { createReducer, registerReducer } from "redux-rsi";
import Immutable from "seamless-immutable";
import { normalize } from "../normalize";

const TREE_ROOT = "bidding/channel";

const reducer = createReducer(
	Immutable({
		lotNumbers: {},
		isConnected: false,
		isProblem: false,
		connectionAttempts: 0
	}),
	{
		onChannelListen(state, { lotNumber }) {
			lotNumber = normalize(lotNumber);

			const lotNumCount = (state.lotNumbers[lotNumber] || 0) + 1;
			const newLotNumbers = state.lotNumbers.set(lotNumber, lotNumCount);

			return state.set("lotNumbers", newLotNumbers);
		},

		onChannelListenStop(state, { lotNumber }) {
			lotNumber = normalize(lotNumber);

			const lotNumCount = (state.lotNumbers[lotNumber] || 0) - 1;

			let newLotNumbers = state.lotNumbers;

			if (lotNumCount <= 0) {
				newLotNumbers = newLotNumbers.without(lotNumber);
			} else {
				newLotNumbers = state.lotNumbers.set(lotNumber, lotNumCount);
			}

			return state.set("lotNumbers", newLotNumbers);
		},

		onChannelTryOpen(state) {
			return state.set("connectionAttempts", state.connectionAttempts + 1);
		},

		onChannelOpened(state) {
			return state.merge({
				isConnected: true,
				isProblem: false,
				connectionAttempts: 0
			});
		},

		onChannelClosed(state) {
			return state.set("isConnected", false);
		},

		onChannelProblem(state) {
			return state.set("isProblem", true);
		},

		onAuctionBiddingStatusChanged(state) {
			return this.onAnyMessageReceived(state);
		},

		onBidAccepted(state) {
			return this.onAnyMessageReceived(state);
		},

		onBidRejected(state) {
			return this.onAnyMessageReceived(state);
		},

		onBidCanceled(state) {
			return this.onAnyMessageReceived(state);
		},

		onBidNotCanceled(state) {
			return this.onAnyMessageReceived(state);
		},

		onBidSubmitFailed(state, { response }) {
			if (!response) {
				return state.set("isProblem", true);
			} else {
				return this.onAnyMessageReceived(state);
			}
		},

		onChannelPingReceived(state) {
			return this.onAnyMessageReceived(state);
		},

		onAnyMessageReceived(state) {
			return state.merge({
				isConnected: true,
				isProblem: false,
				connectionAttempts: 0
			});
		}
	}
);

registerReducer(TREE_ROOT, reducer);

export const getConnection = state => {
	state = state[TREE_ROOT];

	return state
		.set(
			"isExpected",
			state.isConnected == Object.keys(state.lotNumbers).length > 0
		)
		.without("lotNumbers");
};

export const getListeners = state => Object.keys(state[TREE_ROOT].lotNumbers);
