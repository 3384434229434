import React from "react";
import { PropTypes as t } from "prop-types";
import cx from "classnames";
import formatBid from "../amount/format-bid";

const Validation = ({ userBid = 0, maxBid = 1000000000, minBid, bidType }) => {
	var overMax = userBid > maxBid;
	var underMin = userBid < minBid;

	var classNames = cx({
		"bid-place__validation": true,
		"text-danger": overMax || underMin,
		"bid-place__validation--over-max": overMax,
		"bid-place__validation--under-min": underMin
	});

	if (overMax) {
		return (
			<small className={classNames}>
				Maximum bid is {formatBid(maxBid, bidType)}
			</small>
		);
	}

	if (underMin) {
		return (
			<small className={classNames}>
				Minimum bid is {formatBid(minBid, bidType)}
			</small>
		);
	}

	return null;
};

Validation.propTypes = {
	userBid: t.number,
	minBid: t.number.isRequired,
	maxBid: t.number
};

export default Validation;
