import ko from "knockout";
import ListViewItemModel from "./model";
import "knockout.punches";
import "../info-block/main";
import "../../../knockout-integration";
import "./../../auction/note-editor/main";
import "./../../watchlist/popbox/main";
import "knockout-template?name=auction-list-view-item!html?-minimize!./index.html";
import "knockout-template?name=auction-list-view-item-price-time!html?-minimize!./price-time.html";
import "./index.less";
import "./../../less/user-actions.less";

ko.punches.enableAll();

ko.bindingHandlers.auctionListViewItem = {
	init: function() {
		return {
			controlsDescendantBindings: true
		};
	},
	update: function(element, valueAccessor) {
		var auction = ko.utils.unwrapObservable(valueAccessor());
		if (auction) {
			ko.renderTemplate(
				"auction-list-view-item",
				new ListViewItemModel({
					auction: auction
				}),
				null,
				element,
				"replaceChildren"
			);
		}
	}
};

ko.virtualElements.allowedBindings.auctionListViewItem = true;
