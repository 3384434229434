import createBiddingBinding from "./bidding";
import createCostViewerBinding from "./cost-viewer";
import createImageSectionBinding from "./image-section";
import createBidderListApplicationBinding from "./bidder-list-application";

import store from "../legacy/store";

createBiddingBinding(store);
createBidderListApplicationBinding(store);
createCostViewerBinding(store);
createImageSectionBinding(store);
