import ko from "knockout";
import $ from "jquery";
import { map, sortBy } from "lodash";
import PageAlert from "./page-alert";

function PageAlerts(data) {
	var alertData = sortBy(
		map(data, function(d) {
			return new PageAlert(d);
		}),
		function(a) {
			return ko.unwrap(a.date).valueOf();
		}
	).reverse();

	this.slideDown = function(el) {
		$(el)
			.hide()
			.slideDown(250);
	};
	this.slideUp = function(el) {
		$(el).slideUp(250, function() {
			$(el).remove();
		});
	};

	this.alerts = ko.observableArray(alertData);
}

export default PageAlerts;
