import ko from "knockout";
import $ from "jquery";
import BidTime from "./time";
import "knockout.moment";
import "bid/amount";

var URL = "/api/bidding";
var identityMap = {};

function Bid(data) {
	var existing = checkIdentityMap(data);
	if (existing) {
		return existing;
	}

	this.id = ko.observable();
	this.auctionId = ko.observable();

	this.bidder = ko.observable();
	this.bidder.shortName = ko.computed(function() {
		if (this.bidder()) {
			return typeof this.bidder() === "string"
				? this.bidder()
				: `${this.bidder().firstName[0]}. ${this.bidder().lastName}`;
		}
	}, this);
	this.bidder.isYou = ko.computed(function() {
		return typeof this.bidder() === "string"
			? this.bidder() === "You" ? true : false
			: false;
	}, this);

	this.isTraditional = ko.observable();
	this.amount = ko.observable().extend({
		bidAmount: this.isTraditional
	});

	this.bidTime = new BidTime();

	this.isCancelled = ko.observable();
	this.status = ko.observable();

	this.isWinning = ko.computed(function() {
		return this.status()
			? this.status().search(/Winning|Purchased/) !== -1
			: false;
	}, this);

	this.bidItemCssClass = ko.computed(function() {
		var cssClasses = {
			"bid-history__bid--old": this.bidTime.isOld(),
			"bid-history__bid--winning": this.isWinning() && this.bidder.isYou(),
			"bid-history__bid--winning-not-you":
				this.isWinning() && !this.bidder.isYou(),
			"bid-history__bid--you": this.bidder.isYou(),
			"bid-history__bid--cancelled": this.isCancelled()
		};

		return cssClasses;
	}, this);

	if (data) {
		refreshFromData(this, data);
		identityMap[data.id] = this;
	}
}

function checkIdentityMap(data) {
	if (data && identityMap[data.id]) {
		var bid = identityMap[data.id];
		refreshFromData(bid, data);
		return bid;
	}
}

function refreshFromData(bid, data) {
	for (var prop in data) {
		if (data.hasOwnProperty(prop) && bid[prop]) {
			bid[prop](data[prop]);
		}
	}
}

Bid.clearIdentityMap = function() {
	identityMap = {};
};

Bid.getByAuction = function(id, ta) {
	var promise = $.Deferred();

	$.ajax(URL, {
		data: {
			id: id,
			ta: ta
		},
		context: this,
		type: "GET"
	})
		.done(function(data) {
			promise.resolve(
				ko.utils.arrayMap(data.bids, function(bid) {
					return new Bid(bid);
				})
			);
		})
		.fail(promise.reject);

	return promise;
};

export default Bid;
