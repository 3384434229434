import $ from "jquery";
import ko from "knockout";
import "../knockout-integration";

$(function() {
	var $wrap = $("<div></div>");

	$wrap
		.attr("data-bind", "bidding: 'ChannelDisconnectWarning'")
		.appendTo("body");

	ko.applyBindings(null, $wrap[0]);
});
