import React from "react";
import { PropTypes as t } from "prop-types";
import formatBid from "./../amount/format-bid";

const AuctionBidSummary = props => {
	const { auction } = props;
	const { isEnded } = auction;
	const bidDescription =
		auction.bidType.toLowerCase() === "percentage"
			? "minimum bid"
			: "maximum bid";

	if (auction.winningBid && auction.winningBid.isYourBid) {
		var maxBidMessage = isEnded ? null : (
			<span style={{ display: "inline-block" }}>
				Your {bidDescription} is{" "}
				{formatBid(auction.userCurrentBid.amount, auction.bidType)}
				.
			</span>
		);

		return (
			<span>
				<span style={{ display: "inline-block" }}>
					You {!isEnded ? "are winning" : "won"}.
				</span>{" "}
				{maxBidMessage}
			</span>
		);
	}

	if (auction.userCurrentBid) {
		return <span>{!isEnded ? "You've been outbid" : "You did not win"}.</span>;
	}

	return null;
};

AuctionBidSummary.propTypes = {
	auction: t.object.isRequired
};

export default AuctionBidSummary;
