import { debounce } from "lodash";

const MESSAGE_BATCH_THRESHOLD = 300; // amount of time to wait for socket messages for batching

let pendingActions = [];
let dispatch = null;

export function init(dp) {
	dispatch = dp;
}

const batchDispatch = debounce(() => {
	dispatch(pendingActions);
	pendingActions = [];
}, MESSAGE_BATCH_THRESHOLD);

export function queue(action) {
	pendingActions.push(action);
	batchDispatch();
}
