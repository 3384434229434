import { createReducer, mergeWithCurrent } from "redux-rsi";
import Immutable from "seamless-immutable";
import { withContext } from "../../user-context";

export default createReducer(Immutable({}), {
	onUserAuctionsFetch(state, username) {
		return mergeWithCurrent(
			state,
			username.toLowerCase(),
			{
				isLoading: true
			},
			init
		);
	},

	onUserAuctionsFetchCompleted(state, auctions, username) {
		return mergeWithCurrent(
			state,
			username.toLowerCase(),
			{
				isLoading: false,
				isLoaded: true,
				isError: false,
				lotNumbers: auctions.map(a => withContext(a.lotNumber, username))
			},
			init
		);
	},

	onUserAuctionsFetchFailed(state, err, username) {
		return mergeWithCurrent(
			state,
			username.toLowerCase(),
			{
				isLoading: false,
				isError: true
			},
			init
		);
	}
});

function init(username) {
	return Immutable({
		username,
		isLoading: false,
		isLoaded: false,
		isError: false,
		lotNumbers: []
	});
}

export function getLotNumbersForUser(state, username) {
	username = username.toLowerCase();
	return state[username] || init(username);
}
