import ko from "knockout";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import {
	AuctionFixedPrice as _AuctionFixedPrice,
	AuctionGoing as _AuctionGoing,
	AuctionTime as _AuctionTime,
	BidPlace as _BidPlace,
	BidStats as _BidStats,
	BidSummary as _BidSummary,
	BidHistory as _BidHistory,
	ChannelDisconnectWarning,
	createListener,
	connectAuction,
	connectBidHistory,
	connectCurrentBid,
	createActiveAuctionsFetcher
} from "@civicsource/auctions";

const dic = {
	AuctionFixedPrice: connectAuction(_AuctionFixedPrice),
	AuctionGoing: connectAuction(_AuctionGoing),
	AuctionTime: connectAuction(_AuctionTime),

	BidPlace: createListener(connectAuction(connectCurrentBid(_BidPlace))),
	BidStats: createActiveAuctionsFetcher(_BidStats),
	BidSummary: createListener(connectAuction(_BidSummary)),

	BidHistory: createListener(connectBidHistory(_BidHistory)),

	ChannelDisconnectWarning
};

export default function(store) {
	ko.bindingHandlers.bidding = {
		init: function(element) {
			ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
				ReactDOM.unmountComponentAtNode(element);
			});

			return { controlsDescendantBindings: true };
		},
		update: function(element, valueAccessor, allBindings) {
			var value = ko.utils.unwrapObservable(valueAccessor());
			var props = ko.toJS(allBindings.get("props"));

			if (value) {
				ReactDOM.render(
					<Provider store={store}>
						<BiddingRoot name={value} {...props} />
					</Provider>,
					element
				);
			}
		}
	};
}

const BiddingRoot = ({ name, ...props }) => {
	const componentToRender = dic[name];

	if (!componentToRender) {
		return (
			<span style="text-color:red">
				The bidding component named '{name}' does not exist.
			</span>
		);
	}

	return React.createElement(componentToRender, props);
};
