import React from "react";
import useSheet from "react-jss";

const style = {
	menuItem: {
		color: "#cecece",
		display: "block",
		padding: "16px 0px 16px 40px",
		"&:hover": {
			background: "#525252",
			color: "#cecece",
			textDecoration: "none"
		},
		"&:focus": {
			textDecoration: "none",
			color: "#cecece"
		},
		height: "50px"
	},
	header: {
		color: "#6eb5e5",
		padding: "16px 12px 16px 40px"
	},
	search: {
		padding: "10px 0px 0px 10px"
	}
};

const MenuItems = ({ tabItems, classes, search, user }) => {
	if (!tabItems) return null;
	const [bidding, purchases, account] = tabItems;
	return tabItems.length > 1 ? (
		<div>
			{user ? (
				<>
					<div
						className="col-md-4"
						style={{ position: "inline-block" }}
						key={bidding.text}
					>
						<header className={classes.header}>{bidding.text}</header>
						<ul>
							{bidding.links.map(innerLink => (
								<li key={innerLink.text}>
									<a
										className={classes.menuItem}
										key={innerLink.text}
										href={innerLink.href}
									>
										{innerLink.text}
									</a>
								</li>
							))}
						</ul>
					</div>
					<div className={`col-md-4 ${classes.topItem}`} key={purchases.text}>
						<header className={classes.header}>{purchases.text}</header>
						<ul>
							{purchases.links.map(innerLink => (
								<li key={innerLink.text}>
									<a
										className={classes.menuItem}
										key={innerLink.text}
										href={innerLink.href}
									>
										{innerLink.text}
									</a>
								</li>
							))}
						</ul>
					</div>
				</>
			) : null}
			<div
				className={user ? "col-md-4" : "col-md-6"}
				style={{ position: "inline-block" }}
				key={account.text}
			>
				<header className={classes.header}>{account.text}</header>
				<ul>
					{account.links.map(innerLink => (
						<li key={innerLink.text}>
							<a
								className={classes.menuItem}
								key={innerLink.text}
								href={innerLink.href}
							>
								{innerLink.text}
							</a>
						</li>
					))}
				</ul>
			</div>
		</div>
	) : (
		<div>
			<li key={tabItems[0].text} style={{ padding: "8px 0px 16px 0px" }}>
				<a
					className={search ? classes.search : classes.menuItem}
					href={tabItems[0].href}
					style={{ paddingBottom: "10px" }}
				>
					{tabItems[0].text}
				</a>
			</li>
		</div>
	);
};

export default useSheet(style)(MenuItems);
