import { fetchOnUpdate } from "fetch-helpers";
import connect from "./connect";

export default function fetchBidHistory(component) {
	const Fetcher = fetchOnUpdate(({ fetchBidHistory, auction }) => {
		if (auction) {
			fetchBidHistory(auction.lotNumber);
		}
	}, "auction.lotNumber")(component);

	return connect(Fetcher);
}
