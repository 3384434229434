import { createReducer, mergeWithCurrent } from "redux-rsi";
import Immutable from "seamless-immutable";
import { createSelector } from "reselect";

import { normalizeLotNumber } from "@civicsource/auctions";
import { groupBy, sumBy } from "lodash";

export default createReducer(Immutable({}), {
	onCostsFetch(state, lotNumber) {
		return mergeWithCurrent(
			state,
			normalizeLotNumber(lotNumber),
			{
				isLoading: true
			},
			init
		);
	},

	onCostsFetchCompleted(state, results, lotNumber) {
		return mergeWithCurrent(
			state,
			normalizeLotNumber(lotNumber),
			{
				isLoading: false,
				isLoaded: true,
				isError: false,
				costs: defang(results)
			},
			init
		);
	},

	onCostsFetchFailed(state, err, lotNumber) {
		return mergeWithCurrent(
			state,
			normalizeLotNumber(lotNumber),
			{
				isLoading: false,
				isLoaded: false,
				isError: true
			},
			init
		);
	}
});

function init(lotNumber) {
	return Immutable({
		lotNumber,
		costs: [],
		isLoading: false,
		isLoaded: false,
		isError: false
	});
}

function defang(costs) {
	costs = costs.filter(c => !c.isDeleted);
	const grouped = groupBy(costs, c => c.code);

	let results = [];

	for (const code in grouped) {
		const group = grouped[code];

		results.push({
			id: group[0].id,
			code: Number(code),
			quantity: group.length,
			description: group[0].name,
			amount: sumBy(group, c => c.revenue)
		});
	}

	return results;
}

function getRawCostsForAuction(state, lotNumber) {
	lotNumber = normalizeLotNumber(lotNumber);
	return state[lotNumber] || init(lotNumber);
}

export const getCostsForAuction = createSelector(
	getRawCostsForAuction,
	results => results.set("total", sumBy(results.costs, c => c.amount))
);
