import React from "react";
import BidCount from "./main";

const BidCountWrapper = ({ auction, showLoading, ...props }) => {
	if (auction.isBiddingInfoMissing) {
		return null;
	}

	if (!auction.isBiddingInfoLoaded && showLoading) {
		if (auction.biddingInfoLoadError) {
			return <span>{auction.biddingInfoLoadError}</span>;
		}

		return <span>Loading bid count...</span>;
	}

	if (auction.bidCount <= 0) {
		return null;
	}

	return (
		<BidCount
			className="bid-count__container"
			bidCount={auction.bidCount}
			bidderCount={auction.bidderCount}
			{...props}
		/>
	);
};

export default BidCountWrapper;
