import React from "react";
import { PropTypes as t } from "prop-types";
import cx from "classnames";
import Bidder from "./bidder";
import Actions from "./actions";
import { Time } from "@civicsource/ui";

import { Paddle as IconPaddle } from "../icons";

import formatBid from "../../../amount/format-bid";

const BidHistoryItem = ({
	classes,
	auction,
	bid,
	getUserUrl,
	canManageTaxSale,
	...props
}) => {
	const isCurrentBid =
		auction.userCurrentBid && auction.userCurrentBid.id == bid.id;

	const showProxies = !bid.isProxy;

	var bidClasses = cx({
		[classes.item]: true,
		[classes.private]: !bid.isPublic,
		[classes.canceled]: bid.isCanceled,
		[classes.proxy]: bid.isProxy && showProxies,
		[classes.proxyPublic]: bid.isPublic && bid.isProxy,
		[classes.you]: bid.isYourBid,
		[classes.youPublic]: bid.isYourBid && bid.isPublic,
		[`success ${classes.youWinning}`]:
			bid.isWinning && bid.isYourBid && !canManageTaxSale,
		[`danger ${classes.youLosing}`]:
			bid.isPublic && !bid.isWinning && isCurrentBid && !canManageTaxSale,
		success: bid.isWinning && canManageTaxSale,
		"danger text-danger": bid.isRejected
	});

	const maxBidExplanation =
		"This is a bid placed by the user. Bids without this icon are proxy bids.";
	const proxyBidExplanation =
		"This is a proxy bid (a bid automatically placed on the user's behalf, as opposed to their maximum bid).";
	const helpText = bid.isProxy ? proxyBidExplanation : maxBidExplanation;

	var bidIcon = showProxies ? (
		<span>
			<IconPaddle />
		</span>
	) : null;
	var amountFormatted = formatBid(bid.amount, auction.bidType);

	const rejectedIcon = bid.isRejected ? (
		<i className="fa fa-exclamation-circle" title="Rejected" />
	) : null;

	const canceledIcon = bid.isCanceled ? (
		<i className="fa fa-times" title="Canceled" />
	) : null;

	return (
		<tr className={bidClasses}>
			<td>
				{rejectedIcon}
				{canceledIcon}{" "}
				<Bidder
					bid={bid}
					canManageTaxSale={canManageTaxSale}
					getUserUrl={getUserUrl}
				/>
			</td>
			<td className="text-right">
				<span title={helpText}>
					{bidIcon} {amountFormatted}
				</span>
			</td>
			<td className="text-center">
				<Time value={bid.time} format="MM/DD/YYYY hh:mm:ss.SSS A" />
			</td>
			<td className="text-right">
				<Actions auction={auction} bid={bid} {...props} />
			</td>
		</tr>
	);
};

BidHistoryItem.propTypes = {
	bid: t.object.isRequired,
	auction: t.object.isRequired,
	showCancelButton: t.bool,
	canManageTaxSale: t.bool,
	getUserUrl: t.func
};

export default BidHistoryItem;
