import ko from "knockout";
import $ from "jquery";
import ViewModel from "./model";
import "jquery.popbox";
import "knockout.toggle";
import "knockout-template?name=watchlist-popbox-main!html?-minimize!./templates/index.html";
import "knockout-template?name=watchlist-popbox-pop!html?-minimize!./templates/pop.html";

ko.bindingHandlers.watchListPopBox = {
	init: function(el) {
		el.model = new ViewModel();
		ko.renderTemplate(
			"watchlist-popbox-main",
			el.model,
			null,
			el,
			"replaceChildren"
		);

		$(".popbox-fav", $(el)).popbox({
			openHandler: function() {
				el.model.refresh();
			}
		});

		return {
			controlsDescendantBindings: true
		};
	},
	update: function(el, valueAccessor, allBindingsAccessor) {
		var auction = ko.utils.unwrapObservable(valueAccessor());
		var text = ko.utils.unwrapObservable(allBindingsAccessor().popText);

		if (text === undefined) {
			text = "Watch Lists";
		}

		el.model.auction(auction);
		el.model.popText(text);
	}
};
