import ko from "knockout";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import BidderListApplication from "../bidder-list-application/connected";
import { getConfig } from "@civicsource/runtime-config";

export default function createBidderListApplicationBinding(store) {
	ko.bindingHandlers.bidderListApplication = {
		init: function(element) {
			ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
				ReactDOM.unmountComponentAtNode(element);
			});

			return { controlsDescendantBindings: true };
		},
		update: function(element, valueAccessor, allBindings) {
			var value = ko.utils.unwrapObservable(valueAccessor());
			var props = ko.toJS(allBindings.get("props"));
			const user = getConfig("user");

			if (value) {
				ReactDOM.render(
					<Provider store={store}>
						<BidderListApplication
							{...props}
							taxAuthority={props.auction.taxAuthority.code}
							userId={user ? user.id : null}
						/>
					</Provider>,
					element
				);
			}
		}
	};
}
