import { getActiveAuctionsForUser } from "../../api";
import { getAuctionsForUser } from "../reducer";
import { getCurrentUser } from "@civicsource/users";
import { createAjaxAction } from "redux-rsi";

export function fetchUserAuctions(username) {
	return createAjaxAction(
		{
			type: "USER_AUCTIONS_FETCH",
			payload: username
		},
		getState => {
			const state = getState();
			const status = getAuctionsForUser(state, username);
			if (status.isLoaded || status.isLoading || status.isError) {
				return;
			}

			return getActiveAuctionsForUser(username, getCurrentUser(state));
		}
	);
}
