import React from "react";
import { AlertModal } from "@civicsource/ui";
import connect from "./connect";

class ChannelDisconnectWarning extends React.Component {
	constructor(props) {
		super(props);
	}

	isConnectionInBadState(props) {
		var connection = (props || this.props).connection;
		return connection.isProblem;
	}

	reloadHandler(ev) {
		ev.preventDefault();
		window.location.reload();
	}

	render() {
		if (!this.isConnectionInBadState()) {
			return null;
		}

		return (
			<AlertModal severity="danger">
				<div className="clearfix" style={{ position: "relative" }}>
					<i
						className="fa fa-4x fa-exclamation-circle"
						style={{ position: "absolute" }}
					/>
					<div style={{ marginLeft: "5em" }}>
						<span className="h3">Connection Problem</span>
						<p>
							<strong>Your connection to the server has been lost.</strong>
							<br />
							We're trying to reconnect, but you're not getting auction updates
							right now. You won't be able to place any bids until you
							reconnect.
							<br />
							<br />
							You can also{" "}
							<a className="alert-link" href="#" onClick={this.reloadHandler}>
								refresh the page
							</a>{" "}
							and try again.
						</p>
					</div>
				</div>
			</AlertModal>
		);
	}
}

export default connect(ChannelDisconnectWarning);
