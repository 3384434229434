import ko from "knockout";
import init from "knockout.init";
import payload from "./payload";

function User(data) {
	init(this, data, {
		dates: ["birthday"]
	});

	this.disabledReason = ko.computed(function() {
		if (this.status() !== "Normal") {
			return "BiddingDisabled";
		}
	}, this);
}

export default (payload.user ? new User(payload.user) : null);
