import React from "react";
import connect from "./connect";
import useSheet from "react-jss";

const styles = {
	labelCartCount: {
		textAlign: "center !important",
		left: "50% !important",
		width: "15px !important",
		marginLeft: "-3px !important",
		height: "15px !important",
		borderRadius: "4px !important",
		background: "#d05c5f !important",
		fontWeight: "bold !important",
		fontSize: "9px !important",
		lineHeight: "14px !important",
		fontStyle: "normal !important",
		"&:empty": {
			display: "none !important"
		},
		color: "#fff !important"
	}
};

const Root = ({ checkout: { count }, classes }) => {
	if (!count) {
		return null;
	}

	return <i className={`fa-stack-1x ${classes.labelCartCount}`}>{count}</i>;
};

export default connect(useSheet(styles)(Root));
