import { getBidHistory, getBidHistoryOlderThan } from "../../api";
import { createAjaxAction } from "redux-rsi";

import { getAuctionBiddingInfo } from "../../auction/reducer";
import { getCurrentUser } from "@civicsource/users";

export function fetchBidHistory(lotNumber) {
	return createAjaxAction(
		{
			type: "BID_HISTORY_FETCH",
			payload: lotNumber
		},
		getState => {
			const state = getState();

			const { bids } = getAuctionBiddingInfo(state, lotNumber);
			if (bids.isLoaded || bids.isLoading || bids.isError) {
				return;
			}

			return getBidHistory(lotNumber, getCurrentUser(state));
		}
	);
}

export function fetchMoreBidHistory(lotNumber, olderThanBidId) {
	return createAjaxAction(
		{
			type: "BID_HISTORY_FETCH_MORE",
			payload: {
				lotNumber,
				olderThanBidId
			}
		},
		getState => {
			const state = getState();

			const { bids } = getAuctionBiddingInfo(state, lotNumber);
			if (bids.isLoading) {
				return;
			}

			return getBidHistoryOlderThan(
				lotNumber,
				olderThanBidId,
				getCurrentUser(state)
			);
		}
	);
}
