import React from "react";
import { WaitingIndicator } from "@civicsource/ui";
import Gallery from "./gallery";

const Images = ({ images, isLoading, isError }) => {
	if (isLoading) {
		return <WaitingIndicator decorate>Loading images</WaitingIndicator>;
	}
	if (isError) {
		return (
			<div className="msg-empty">
				<p>Problem loading images.</p>
			</div>
		);
	}
	if (images.length) {
		var imageSet = images.map(({ downloadUrl, docTypeName }) => {
			return {
				src: downloadUrl,
				lightboxImage: {
					src: downloadUrl,
					caption: docTypeName
				}
			};
		});
		return <Gallery photos={imageSet} lightboxShowImageCount />;
	}
	return (
		<div className="msg-empty">
			<p>No images available.</p>
		</div>
	);
};

export default Images;
