import { fetchOnUpdate } from "fetch-helpers";
import connect from "./connect";

export default function fetchAuction(component) {
	const Fetcher = fetchOnUpdate(
		({
			fetchAuctionBiddingInfo,
			auction: {
				isBiddingInfoLoaded,
				biddingInfoLoadError,
				isBiddingInfoMissing,
				lotNumber
			}
		}) => {
			if (
				!isBiddingInfoLoaded &&
				!biddingInfoLoadError &&
				!isBiddingInfoMissing
			) {
				fetchAuctionBiddingInfo(lotNumber);
			}
		},
		"auction.lotNumber"
	)(component);

	return connect(Fetcher);
}
