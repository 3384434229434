﻿define(["knockout", "jquery", "knockout.toggle", "knockout.url"], function (ko, $, toggle, url) {
	ko.bindingHandlers.sorter = {
		init: function (el, viewModelAccessor) {
			var model = viewModelAccessor();

			if (!model.data.currentSort) {
				model.data.currentSort = {
					sortBy: ko.observable(),
					direction: ko.observable()
				};
			}

			initalizeSortElement.call(el);

			$(el).click(function () {
				model = viewModelAccessor();

				var sortBy = ko.utils.unwrapObservable(model.sortBy);
				var direction = model.data.currentSort.direction() == "asc" ? "desc" : "asc";

				model.data.currentSort.sortBy(sortBy);
				model.data.currentSort.direction(direction);

				return false;
			});
		},
		update: function (el, viewModelAccessor) {
			var model = viewModelAccessor();
			var sortBy = model.data.currentSort.sortBy();
			var direction = model.data.currentSort.direction();
			var linkSortBy = ko.utils.unwrapObservable(model.sortBy);

			if (sortBy && direction) {
				sortData.call(model.data, sortBy, direction);
			}

			updateCss.call(el, sortBy, direction, linkSortBy);
		}
	};

	ko.bindingHandlers.sortLink = {
		init: function (el, valueAccessor) {
			var model = ko.utils.unwrapObservable(valueAccessor());
			var sortValue = ko.utils.unwrapObservable(model.value);

			el.sortValues = [sortValue + ' asc', sortValue + ' desc'];

			initalizeSortElement.call(el);
		},
		update: function (el, valueAccessor) {
			var model = ko.utils.unwrapObservable(valueAccessor());
			var sortString = ko.utils.unwrapObservable(model.sort);
			var currentSort = parseSortString(sortString);

			var newSort = toggle(sortString, el.sortValues);

			var link = url.current();
			if (link) {
				link = link.clone()
					.removeSearch("page")
					.setSearch("sort", newSort);

				$(el).attr("href", link.toString());
				updateCss.call(el, currentSort.sortBy, currentSort.direction, ko.utils.unwrapObservable(model.value));
			}
		}
	};

	function initalizeSortElement() {
		$(this).addClass("sortable");
	}

	function parseSortString(sortString) {
		var current = {};

		if (sortString) {
			var parts = sortString.split(' ');

			if (parts.length !== 2) {
				throw new Error("Could not parse sort string '" + sortString + "'. Example 'AccountNumber desc'.");
			}

			current.sortBy = parts[0];
			current.direction = parts[1];
		}

		return current;
	}

	function sortData(sortBy, direction) {
		this.sort(function (left, right) {
			var props = sortBy.split(".");

			function evalPropValue(obj) {
				var current = obj;
				for (var i = 0; i < props.length; i++) {
					if (current) {
						current = ko.utils.unwrapObservable(current[props[i]]);
					}
				}

				return current;
			}

			var leftValue = evalPropValue(left);
			var rightValue = evalPropValue(right);

			if (leftValue == rightValue) {
				return 0;
			}

			if (leftValue < rightValue) {
				return direction == "asc" ? -1 : 1;
			} else {
				return direction == "asc" ? 1 : -1;
			}
		});
	}

	function updateCss(sortBy, direction, linkSortBy) {
		if (sortBy === linkSortBy) {
			var titleText = 'Sorted by: ' + $(this).text().trim() + ', ' + direction + 'ending';

			$(this)
				.removeClass("asc desc")
				.addClass(direction)
				.attr('title', titleText);
		} else {
			$(this)
				.removeClass("asc desc")
				.removeAttr('title');
		}
	}
});