import { debounce } from "lodash";

export const DEBOUNCE_THRESHOLD = 300; // don't send on socket until 300 milliseconds after changes to auctionIdsToListenFor

let pendingActions = [];

const batchDispatch = debounce(dispatch => {
	dispatch(pendingActions);
	pendingActions = [];
}, DEBOUNCE_THRESHOLD);

const queueListenAction = (action, dispatch, onDispatched) => {
	pendingActions.push(action);
	batchDispatch(dispatch, onDispatched);
};

export const listenFor = (lotNumber, sync) =>
	listen("CHANNEL_LISTEN", lotNumber, sync);

export const stopListening = (lotNumber, sync) =>
	listen("CHANNEL_LISTEN_STOP", lotNumber, sync);

const listen = (actionType, lotNumber, sync) => dispatch => {
	queueListenAction(
		{
			type: actionType,
			payload: {
				lotNumber,
				sync: sync === undefined ? true : !!sync
			}
		},
		dispatch
	);
};

export const tryOpenChannel = sync => ({
	type: "CHANNEL_TRY_OPEN",
	payload: {
		sync: sync === undefined ? true : !!sync
	}
});

export const channelOpened = () => ({ type: "CHANNEL_OPENED" });
export const channelClosed = () => ({ type: "CHANNEL_CLOSED" });
export const channelProblem = () => ({ type: "CHANNEL_PROBLEM" });

export const pingReceived = () => ({
	type: "CHANNEL_PING_RECEIVED",
	meta: {
		source: "websocket"
	}
});
