define(["knockout", "moment"], function (ko, moment) {
	//https://github.com/knockout/knockout/issues/1019
	ko.forcibleComputed = function (readFunc, context, options) {
		var trigger = ko.observable(),
			target = ko.computed(function () {
				trigger();
				return readFunc.call(context);
			}, null, options);

		target.evaluateImmediate = function () {
			trigger.valueHasMutated();
		};

		return target;
	};
});