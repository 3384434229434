import React, { Component } from "react";
import connect from "./connect";

export default function createListener(DecoratedComponent) {
	class ChannelListener extends Component {
		componentDidMount() {
			this.props.listen(this.lotNumber());
		}

		componentWillUnmount() {
			this.props.stop(this.lotNumber());
		}

		lotNumber() {
			if (this.props.auction) {
				return this.props.auction.lotNumber;
			}

			return this.props.lotNumber;
		}

		render() {
			return <DecoratedComponent {...this.props} />;
		}
	}

	return connect(ChannelListener);
}
