import ko from "knockout";
import ViewModel from "./model";
import "knockout.transitions";
import "./../../page-alerts/main";
import "knockout-template?name=auction-alerts-main!html?-minimize!./index.html";

ko.bindingHandlers.auctionAlerts = {
	init: function() {
		return {
			controlsDescendantBindings: true
		};
	},
	update: function(element, valueAccessor) {
		var model = ko.utils.unwrapObservable(valueAccessor());
		if (model) {
			ko.renderTemplate(
				"auction-alerts-main",
				new ViewModel({
					auction: model
				}),
				null,
				element,
				"replaceChildren"
			);
		}
	}
};

ko.virtualElements.allowedBindings.auctionAlerts = true;
