import React from "react";
import Lightbox from "react-images";
import Slider from "react-slick";
import { ResponsiveBackground } from "@civicsource/ui";
import { Col } from "react-bootstrap";

import "./slider.css";

class Gallery extends React.Component {
	constructor() {
		super();
		this.state = {
			currentImage: 0
		};
		this.closeLightbox = this.closeLightbox.bind(this);
		this.gotoNext = this.gotoNext.bind(this);
		this.gotoPrevious = this.gotoPrevious.bind(this);
		this.openLightbox = this.openLightbox.bind(this);
	}
	openLightbox = index => event => {
		event.preventDefault();
		this.setState({
			currentImage: index,
			lightboxIsOpen: true
		});
	};
	closeLightbox() {
		this.setState({
			currentImage: 0,
			lightboxIsOpen: false
		});
	}
	gotoPrevious() {
		this.setState(({ currentImage }) => ({
			currentImage: currentImage - 1
		}));
	}
	gotoNext() {
		this.setState(({ currentImage }) => ({
			currentImage: currentImage + 1
		}));
	}
	renderGallery(photoPreviewNodes, lightboxImages) {
		var sliderSettings = {
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 2,
			slidesToScroll: 1,
			lazyLoad: true
		};

		var width = window.innerWidth > 0 ? window.innerWidth : screen.width;

		if (width >= 1024) {
			sliderSettings.slidesToShow = 3;
		}

		if (this.props.disableLightbox) {
			return (
				<div id="Gallery" className="clearfix" ref={c => (this._gallery = c)}>
					{photoPreviewNodes}
				</div>
			);
		} else {
			return (
				<div id="Gallery" className="clearfix" ref={c => (this._gallery = c)}>
					<Slider {...sliderSettings}>{photoPreviewNodes}</Slider>
					<Lightbox
						currentImage={this.state.currentImage}
						images={lightboxImages}
						isOpen={this.state.lightboxIsOpen}
						onClose={this.closeLightbox}
						onClickPrev={this.gotoPrevious}
						onClickNext={this.gotoNext}
						width={1600}
						showImageCount={this.props.lightboxShowImageCount}
						backdropClosesModal={this.props.backdropClosesModal}
					/>
				</div>
			);
		}
	}
	render() {
		var photoPreviewNodes = [];
		var lightboxImages = [];

		for (var i = 0; i < this.props.photos.length; i++) {
			var src = this.props.photos[i].src;
			var rImageObj = { "300": src };

			lightboxImages.push(this.props.photos[i].lightboxImage);
			photoPreviewNodes.push(
				<Col md={4} key={i}>
					<div>
						<div style={{ paddingTop: 10, paddingBottom: 10 }}>
							<a href="#" className={i} onClick={this.openLightbox(i)}>
								<ResponsiveBackground
									style={{ paddingTop: "62%", position: "relative" }}
									sources={rImageObj}
								>
									<h4 className="auction-details__image-section__caption">
										{this.props.photos[i].lightboxImage.caption}
									</h4>
								</ResponsiveBackground>
							</a>
						</div>
					</div>
				</Col>
			);
		}
		return this.renderGallery(photoPreviewNodes, lightboxImages);
	}
}

export default Gallery;
