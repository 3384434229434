import ko from "knockout";
import BidCount from "./../bid/count";
import ntp from "ntp";
import moment from "moment";
import "bid/amount";
import "knockout.timeout";

function AuctionBidding(model) {
	this.isTraditional = ko.observable();

	this.winningBid = ko.observable().extend({
		bidAmount: this.isTraditional
	});
	this.startingBid = ko.observable().extend({
		bidAmount: this.isTraditional
	});

	this.bidCount = new BidCount();

	this.showHistory = ko.observable();

	this.isOpen = ko
		.forcibleComputed(function() {
			if (!model.status.isForSale()) {
				return false;
			}

			var now = moment(ntp.fixTime()),
				end = model.endDate(),
				start = model.startDate();

			if (!start || !end) {
				return false;
			}

			if (now.millisecond() > 0) {
				// round up milliseconds
				now.add("seconds", 1);
			}

			var isAfterStart =
				now.isAfter(start, "second") || now.isSame(start, "second"); // inclusive of start time
			var isBeforeEnd = now.isBefore(end, "second"); // exclusive of end time

			return isAfterStart && isBeforeEnd;
		}, this)
		.extend({
			timeout: [model.startDate, model.endDate]
		});

	this.hasStarted = ko
		.forcibleComputed(function() {
			if (!model.status.isForSale()) {
				return false;
			}

			var now = moment(ntp.fixTime()),
				end = model.endDate(),
				start = model.startDate();

			if (!start || !end) {
				return false;
			}

			if (now.millisecond() > 0) {
				// round up milliseconds
				now.add("seconds", 1);
			}

			var isAfterStart =
				now.isAfter(start, "second") || now.isSame(start, "second"); // inclusive of start time

			return isAfterStart;
		}, this)
		.extend({
			timeout: [model.startDate]
		});

	this.isPast = ko
		.forcibleComputed(function() {
			var now = moment(ntp.fixTime()),
				end = model.endDate();

			if (!end) {
				return false;
			}

			if (now.millisecond() > 0) {
				// round up milliseconds
				now.add("seconds", 1);
			}

			return end.isBefore(now, "second") || end.isSame(now, "second");
		}, this)
		.extend({
			timeout: model.endDate
		});

	// sync to the server clock
	ntp.sync().done(
		function() {
			// force reevaluation with correct server time
			this.isOpen.evaluateImmediate();
			this.isPast.evaluateImmediate();
		}.bind(this)
	);
}

export default AuctionBidding;
