define(["knockout", "moment", "timer", "knockout.forcible"], function (ko, moment, Timer) {
	ko.extenders.timeout = function (target) {
		if (typeof (target.evaluateImmediate) !== "function") {
			throw new Error("The knockout.timeout extender can only be used with forcible computeds (ko.forcibleComputed).");
		}

		var args = Array.prototype.slice.call(arguments, 1);
		var dates = args.length === 1 ? Array.isArray(args[0]) ? args[0] : [args[0]] : args;

		if (dates.length < 1) {
			throw new Error("At least one date to countdown to is required for the knockout.timeout extender.");
		}

		var i = ko.observable(0);
		var countDownDate = ko.computed(function () {
			//this computed depends on i and the passed-in date (if it is an observable)
			//so if either of those changes, this computed reevaluates and triggers new timers to start and so forth
			if (dates.length > i()) {
				return ko.utils.unwrapObservable(dates[i()]);
			}
		});

		countDownDate.subscribe(function (date) {
			if (date) {
				//date changed, start new timer to the new date
				startTimer(date);
			}
		});

		function startTimer(date) {
			var timer = new Timer(date.toDate());
			timer.on("done", onTimerDone);
			timer.start();
		}

		function onTimerDone() {
			target.evaluateImmediate(); //re-evaluate the target
			i(i() + 1); //move on to next date if there is one
		}

		return target;
	};
});