import React from "react";
import { PropTypes as t } from "prop-types";
import { Time } from "@civicsource/ui";
import { UserDisplay } from "@civicsource/users";
import cx from "classnames";
import AuctionInfo from "../info-block";
import formatBid from "../../bid/amount/format-bid";
import useSheet from "react-jss";
import styles from "./index.style";

const AuctionUserBidItem = ({
	classes,
	auction,
	canManageTaxSale,
	username,
	getUserUrl
}) => {
	// TODO: figure out a way to make the socket return data for the user in question, and not the authenticated user. until then, this component will not be real-time
	const { userCurrentBid, winningBid } = auction;
	const userCurrentBidTime = userCurrentBid ? userCurrentBid.time : null;
	const winningBidTime = winningBid ? winningBid.time : null;
	const winningBidder = winningBid ? winningBid.bidder : null;
	const isUserWinning = winningBidder && winningBidder === username;
	const url = getUserUrl ? getUserUrl(winningBidder, auction.id) : null;

	const userDisplay = winningBidder ? (
		canManageTaxSale ? (
			<UserDisplay username={winningBidder} />
		) : (
			<span>{winningBidder}</span>
		)
	) : null;
	const strongUserDisplay = <strong>{userDisplay}</strong>;
	const linkedUserDisplay =
		canManageTaxSale && url ? (
			<a href={url}>{userDisplay}</a>
		) : (
			strongUserDisplay
		);
	const winningUser = isUserWinning ? strongUserDisplay : linkedUserDisplay;

	const winningBidFormatted = winningBid
		? formatBid(winningBid.amount, auction.bidType)
		: null;
	const userCurrentBidFormatted = userCurrentBid
		? formatBid(userCurrentBid.amount, auction.bidType)
		: null;

	const classNames = cx({
		[classes.item]: true,
		[`${classes.winning} success`]: isUserWinning,
		[classes.canceled]: userCurrentBid && userCurrentBid.isCanceled
	});

	return (
		<tr className={classNames}>
			<td>
				<AuctionInfo auction={auction} />
			</td>
			<td>
				<div className="text-right">
					<span>
						<strong>{userCurrentBidFormatted}</strong>
					</span>
					<br />
					<small>
						{userCurrentBidTime ? <Time value={userCurrentBidTime} /> : null}
					</small>
				</div>
			</td>
			<td>
				<div className="text-right">
					<span>
						<strong>{winningBidFormatted}</strong>
					</span>
					<br />
					<small>
						{winningBidTime ? <Time value={winningBidTime} /> : null}
					</small>
					<br />
					{winningUser}
				</div>
			</td>
		</tr>
	);
};

AuctionUserBidItem.propTypes = {
	auction: t.object.isRequired,
	username: t.string.isRequired,
	auctionRender: t.func,
	getUserUrl: t.func,
	canManageTaxSale: t.bool
};

export default useSheet(styles)(AuctionUserBidItem);
