import React from "react";
import { Paddle } from "../history/entries/icons";
import acct from "accounting";
import cx from "classnames";

const BidCount = ({ bidCount, bidderCount }) => {
	const classNames = cx({
		"bid-count__count": true,
		"bid-count__count--has-bids": bidCount > 0,
		"bid-count__count--no-bids": bidCount === 0
	});

	return (
		<span
			className={classNames}
			style={{ display: "inline-block" }}
			title={descriptionText({ bidCount, bidderCount })}
		>
			<BidderCountIcon bidderCount={bidderCount} /> {acct.format(bidderCount)}{" "}
			<Paddle />
			{acct.format(bidCount)}
		</span>
	);
};

const BidderCountIcon = ({ bidderCount }) => {
	if (bidderCount == 0) {
		return <i className="fa fa-user-o" aria-hidden="true" />;
	}

	if (bidderCount == 1) {
		return <i className="fa fa-user" aria-hidden="true" />;
	}

	return <i className="fa fa-users" aria-hidden="true" />;
};

function descriptionText({ bidCount, bidderCount }) {
	return `${bidderCount} ${
		bidderCount == 1 ? "bidder" : "bidders"
	}, ${bidCount} ${bidCount == 1 ? "bid" : "bids"}`;
}

export default BidCount;
