import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { fetchUserAuctions } from "./actions";
import { getAuctionsForUser } from "../../auction/reducer";
import { getCurrentUser } from "@civicsource/users";

export default function connectActiveAuctions(component) {
	return connect(
		(state, { username }) => {
			const currentUser = getCurrentUser(state);
			const usernameToUse =
				username || (currentUser ? currentUser.username : null);

			const results = getAuctionsForUser(state, usernameToUse);
			return { ...results, currentUser };
		},
		dispatch =>
			bindActionCreators(
				{
					fetchUserAuctions
				},
				dispatch
			)
	)(component);
}
