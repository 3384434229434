import React from "react";
import { getSearchPreferencePath } from "../search-preference";
import { startCase } from "lodash";

const BASE = [
	{
		icon: "fa fa-2x fa-search fa-flip-horizontal app-search__icon",
		text: "search",
		search: true,
		rightLink: true,
		links: [
			{
				text: (
					<div className="container" data-ko-app="navbar-items">
						<div className="row" style={{ paddingRight: "30px" }}>
							<div className="col-md-12">
								<form
									className="app-search__container"
									action={`/${getSearchPreferencePath()}`}
									method="get"
									role="search"
									id="search-form"
									data-noroute
								>
									<div className="input-group">
										<input name="q" className="form-control" type="text" />
										<div className="input-group-btn">
											<button type="submit" className="btn btn-primary">
												<span className="hidden-xs">Search</span>
												<span className="visible-xs">
													<i className="fa fa-search fa-flip-horizontal" />
													<span className="sr-only">Search</span>
												</span>
											</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				),
				href: null
			}
		]
	},
	{
		icon: "fa-2x app-shopping-cart-icon",
		text: "Shopping Cart",
		href: "/checkout",
		rightLink: true
	}
];

const NOT_AUTHENTICATED = [
	{
		text: "account",
		href: "/login",
		rightLink: true,
		account: true
	}
];

const MENU = user =>
	user
		? [
				{
					text: startCase(user.firstName),
					rightLink: true,
					loggedIn: true,
					links: [
						{
							text: "Bidding",
							links: [
								{
									text: "Bids",
									href: "/bids"
								},
								{
									text: "Deposits",
									href: "/accountdetails/deposits/showdeposits"
								},
								{
									text: "Watch Lists",
									href: "/accountdetails/watchlists"
								}
							]
						},
						{
							text: "Purchases",
							links: [
								{
									text: "Checkout",
									href: "/checkout"
								},
								{
									text: "Purchases",
									href: "/accountdetails/purchases/showpurchasessummaries"
								}
							]
						},
						{
							text: "Account",
							links: [
								{
									text: "Settings",
									href: "/account/accountinfo"
								},
								{
									text: "Bank Accounts",
									href: "/account/bankaccounts"
								},
								{
									text: "Change Password",
									href: "/account/password"
								},
								{
									text: "Sign Out",
									href: "/login/logout"
								}
							]
						}
					]
				}
			]
		: [];

const COMMON_NAV = [
	{
		text: "property",
		href: "/auctions?saleType=Adjudication"
	},
	{
		text: "investment",
		href: "/auctions?saleType=TaxSale"
	},
	{
		text: "calendar",
		href: "/calendar"
	},
	{
		text: "support",
		href: "/support"
	}
];

const USER_AWARE_BASE = user => (user ? BASE : BASE.concat(NOT_AUTHENTICATED));

const navbar = user => [...COMMON_NAV, ...USER_AWARE_BASE(user), ...MENU(user)];

export default navbar;
