import React from "react";
import { PropTypes as t } from "prop-types";

import ButtonContents from "./button-contents";

const PlaceBidButton = props => {
	const isBidding = props.isSubmitting || props.isProcessing;

	const btnClasses = `btn btn-primary btn-lg btn-block ${
		isBidding ? "active" : ""
	}`;

	return (
		<button type="submit" className={btnClasses}>
			<ButtonContents {...props} />
		</button>
	);
};

PlaceBidButton.propTypes = {
	amount: t.number.isRequired,
	isSubmitting: t.bool.isRequired,
	isProcessing: t.bool.isRequired
};

export default PlaceBidButton;
