import $ from "jquery";
import url from "knockout.url";
import "jquery.cookie";

var DEFAULT = "/search";
var COOKIE_NAME = "user-search-preference";

function get() {
	var currentUrl = url.current();

	if (currentUrl && currentUrl.path().indexOf("search") >= 0) {
		return "/search";
	}

	if (currentUrl && currentUrl.path().indexOf("map") >= 0) {
		return "/map";
	}

	if ($.cookie(COOKIE_NAME) == "map") {
		return "/map";
	}

	return DEFAULT;
}

function set(value) {
	$.cookie(COOKIE_NAME, value, {
		expires: 30 // days
	});
}

function getText() {
	var pref = get();
	return pref == "/map" ? "Map" : "Search";
}

export default {
	get: get,
	getText: getText,
	set: set
};
