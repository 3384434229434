define(["knockout", "uri"], function (ko, Uri) {
	var url = ko.observable();

	var hasHistory = !!(window.history && history.pushState);

	//takes a sammy application as an init parameter
	function init(app) {
		app.before(function () {
			url(new Uri());
		});
	}

	function refresh() {
		url(new Uri());
	}

	return {
		supportsHistory: hasHistory,
		current: ko.computed(function () {
			var link = url();

			if (link) {
				var link2 = link.clone().protocol("").host("");

				var fragment = link2.fragment();
				if (fragment.indexOf("!") !== -1) {
					link2 = new Uri(fragment.replace("!", ""));
				}

				return link2;
			}
		}),
		init: init,
		refresh: refresh
	};
});