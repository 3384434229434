import { applyMiddleware } from "redux";
import { createStore } from "redux-rsi";
import { composeWithDevTools } from "redux-devtools-extension";
import Immutable from "seamless-immutable";

import generatorThunk from "redux-generator-thunk";
import thunk from "redux-thunk";
import { reduxBatch } from "@manaflair/redux-batch";

import { checkSocket, syncSocket } from "@civicsource/auctions";
import { userRuntimeHydration } from "@civicsource/users";

import { middleware as logger } from "@civicsource/logger";

export function configureClientStore(initialState) {
	return configureStore(
		initialState,
		logger,
		generatorThunk,
		thunk,
		userRuntimeHydration,
		checkSocket,
		syncSocket
	);
}

export function configureTestStore(initialState) {
	return configureStore(initialState, logger, generatorThunk, thunk);
}

function configureStore(initialState, ...middleware) {
	initialState = Immutable(initialState || {});

	return createStore(
		initialState,
		composeWithDevTools(reduxBatch, applyMiddleware(...middleware), reduxBatch)
	);
}
