import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { fetchBid } from "./actions";
import { getBid } from "./reducer";

export default function connectBid(component) {
	return connect(
		(state, { bidId }) => {
			let result = {};

			if (bidId) {
				result.bid = getBid(state, bidId);
			}

			return result;
		},
		dispatch =>
			bindActionCreators(
				{
					fetchBid
				},
				dispatch
			)
	)(component);
}
