import { uniq, debounce, get } from "lodash";
import * as socket from ".";

import { getListeners } from "../channel/reducer";
import { getCurrentUser } from "@civicsource/users";

import { DEBOUNCE_THRESHOLD } from "../channel/actions";

export default store => next => action => {
	let result = next(action);

	if (Array.isArray(action)) {
		action.forEach(ac => {
			handleAction(ac, store.getState);
		});
	} else {
		handleAction(action, store.getState);
	}

	return result;
};

function handleAction(action, getState) {
	const shouldSync = get(action, "payload.sync");

	if (shouldSync) {
		sync(getState);
	}
}

function sync(getState) {
	const state = getState();
	const lotNumbers = getListeners(state);
	const user = getCurrentUser(state);
	syncSocket(lotNumbers, user ? user.token : null);
}

const syncSocket = debounce((lotNumbersToListenFor, authToken) => {
	const lotNumbers = uniq(lotNumbersToListenFor);

	if (lotNumbers.length > 0) {
		for (let i = 0; i < lotNumbers.length; i++) {
			socket.send(
				{
					type: "Listen",
					body: lotNumbers[i]
				},
				authToken
			);
		}
	} else {
		socket.close();
	}
}, DEBOUNCE_THRESHOLD);
