import ko from "knockout";
import { union, indexOf } from "lodash";

var preResearch = ["Candidate", "Public"];
var preSale = ["Researching", "ResearchComplete"];
var duringSale = ["ForSale"];
var postSale = ["Sold", "NotSold", "Cancelled"];
var statuses = union(preResearch, preSale, duringSale, postSale);

function AuctionStatus() {
	var status = ko.observable();

	ko.utils.arrayForEach(statuses, function(name) {
		status[`is${name}`] = ko.computed(function() {
			return status() === name;
		});
	});

	status.isPreResearch = ko.pureComputed(function() {
		return indexOf(preResearch, status()) >= 0;
	});
	status.isPreSale = ko.pureComputed(function() {
		return indexOf(preSale, status()) >= 0;
	});
	status.isDuringSale = ko.pureComputed(function() {
		return indexOf(duringSale, status()) >= 0;
	});
	status.isPostSale = ko.pureComputed(function() {
		return indexOf(postSale, status()) >= 0;
	});

	return status;
}

export default AuctionStatus;
