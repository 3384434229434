import { bindActionCreators } from "redux";

import { getCostsForAuction, fetchCosts } from "@civicsource/auctioneer.core";

import { fetchOnUpdate } from "fetch-helpers";
import { connect } from "react-redux";

export default function createFetcher(component) {
	const CostsFetcher = fetchOnUpdate(({ fetchCosts, lotNumber }) => {
		if (lotNumber) {
			fetchCosts(lotNumber);
		}
	}, "lotNumber")(component);

	const CostsFetcherContainer = connect(
		(state, { lotNumber }) => getCostsForAuction(state, lotNumber),
		dispatch => bindActionCreators({ fetchCosts }, dispatch)
	)(CostsFetcher);

	return CostsFetcherContainer;
}
