import ko from "knockout";
import moment from "moment";
import "knockout.moment";

function BidTime() {
	var bidTime = ko.observable().extend({
		moment: true
	});

	bidTime.isOld = ko.computed(function() {
		if (bidTime()) {
			return moment().diff(bidTime(), "hours", true) >= 12;
		}
	});

	bidTime.isToday = ko.computed(function() {
		if (bidTime()) {
			return moment().isSame(bidTime(), "day");
		}
	});

	bidTime.isThisYear = ko.computed(function() {
		if (bidTime()) {
			return moment().isSame(bidTime(), "year");
		}
	});

	bidTime.display = ko.computed(function() {
		if (bidTime()) {
			var pattern = `${(bidTime.isToday() ? "[today] " : "MMM DD ") +
				(bidTime.isThisYear() ? "" : "YYYY ")}[at] hh:mm:ss A`;
			return bidTime().format(pattern);
		}
	});

	return bidTime;
}

export default BidTime;
