import React from "react";
import { PropTypes as t } from "prop-types";
import AlertBox from "./alertbox";
import { compact } from "lodash";

const AuctionAlerts = ({ auction }) => {
	const winningLosingAlert =
		auction.winningBid && auction.userCurrentBid
			? {
					subject: auction.winningBid.isYourBid
						? auction.isEnded ? "You have won this auction" : "Winning"
						: auction.isEnded ? "You did not win this auction" : "Not Winning",
					body: auction.winningBid.isYourBid ? (
						auction.isEnded ? null : (
							<span>
								You are <strong>currently</strong> the winning bidder on this
								auction.
							</span>
						)
					) : auction.isEnded ? null : (
						<span>
							You are <strong>currently</strong> not the winning bidder on this
							auction.
						</span>
					),
					severity: auction.winningBid.isYourBid ? "success" : "danger"
				}
			: null;

	const concludedAlert =
		auction.isEnded && !auction.userCurrentBid
			? {
					subject: "Auction Concluded",
					body: auction.winningBid
						? null
						: "Either no bids were placed, or winning bids were not paid for by the payment deadline.",
					severity: "info"
				}
			: null;

	const alerts = compact([winningLosingAlert, concludedAlert]);

	const alertBoxes = alerts.map(m => <AlertBox message={m} key={m.subject} />);

	return <div className="auction-alerts text-center">{alertBoxes}</div>;
};

AuctionAlerts.propTypes = {
	auction: t.object.isRequired
};

export default AuctionAlerts;
