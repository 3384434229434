import $ from "jquery";
import "jquery.ui/effect";
import "jquery.limit";
// http://stackoverflow.com/questions/7502581/jquery-show-function-applies-inline-block-sometimes-and-block-others
$.fn.extend({
	showMe: function() {
		return this.removeClass("js-hideme");
	},
	hideMe: function() {
		return this.addClass("js-hideme");
	}
});

$(function() {
	// legacy (non-knockout initilization)
	$(".user-note-container").each(function() {
		init(this);
	});
});

function init(element, onNoteSaved) {
	var container = $(element);
	var note = $(".user-note", container);
	var addLink = $(".user-note-add", container);
	var instructions = $(".user-note-ui-text", note);
	var editable = $(".user-note-edit", note);
	var tabHook = $(".tab-hook", container);

	var originalNoteText = editable.html();

	initDisplayState();

	addLink.click(switchToEditMode);
	tabHook.focusin(switchToEditMode);
	note.click(switchToEditMode);

	function switchToEditMode(ev) {
		ev.preventDefault();

		if (note.hasClass("is-editing") || note.hasClass("is-saving")) {
			return;
		}

		originalNoteText = editable.html();
		var txtAreaHeight = editable.height();

		addLink.hideMe();
		note
			.addClass("is-editing")
			.removeClass("was-saved")
			.removeClass("is-error")
			.showMe();
		editable.html(
			`<textarea cols='30' rows='3' name='note' maxlength='250' style='min-height:${txtAreaHeight}px'>${originalNoteText}</textarea>`
		);
		instructions.html(
			"<span class='btn-group'><a href='#' class='user-note-edit-save' title='Save changes (Enter)'>Save</a> <a href='#' class='user-note-edit-cancel' title='Cancel and revert (Esc)'>Cancel</a></span> <span class='addl-status'><span class='chars-left'></span></span>"
		);

		$("textarea", editable).limit(
			$("textarea", editable).attr("maxlength"),
			$(".chars-left")
		);
		$("textarea", editable).focus();
		// bind handlers to the new elements
		$(".user-note-edit-save", note)
			.click(save)
			.blur(saveOnBlur);
		$(".user-note-edit-cancel", note)
			.click(cancel)
			.blur(saveOnBlur);
		$("textarea", editable)
			.blur(saveOnBlur)
			.keydown(overrideEnterEscapeKeys)
			.focus();
	}

	function overrideEnterEscapeKeys(ev) {
		if (ev.keyCode == 27) {
			// escape key
			cancel.apply(this);
			return false;
		}

		if (ev.keyCode == 13 && !ev.shiftKey) {
			// enter key without shift (enter key with shift does default behavior)
			save.apply(this);
			return false;
		}

		return true;
	}

	function save() {
		var noteTextArea = $("textarea", editable);
		var strippedNote = noteTextArea.val().replace(/<[^>]*>/g, "");
		strippedNote = strippedNote.substring(0, 249);
		if (strippedNote == originalNoteText) {
			return cancel();
		}

		note.removeClass("is-editing").addClass("is-saving");
		noteTextArea.attr("readonly", "readonly");
		instructions.html('Saving<span class="throb">…</span>');

		$.ajax(container.data().postUrl, {
			type: "POST",
			dataType: "json",
			data: {
				id: container.attr("id"),
				note: strippedNote
			},
			success: function(hasNote) {
				originalNoteText = strippedNote;
				editable.html(originalNoteText);

				instructions.html('Saved! <i class="hicon fa fa-pencil"></i>');
				note
					.removeClass("is-saving")
					.removeClass("is-error")
					.addClass("is-saved");

				if (hasNote) {
					note.switchClass("is-saved", "was-saved", 1000);
				} else {
					note.removeClass("is-saved").addClass("was-saved");
					resetForEmptyNote();
				}

				if (onNoteSaved) {
					onNoteSaved(strippedNote);
				}
			},
			error: function() {
				instructions.html(
					'Error Saving <i class="hicon throb-2 fa fa-exclamation-circle"></i>'
				);
				note
					.removeClass("is-saving")
					.addClass("is-editing")
					.addClass("is-error");
				noteTextArea.removeAttr("readonly");
			}
		});

		return false;
	}

	function saveOnBlur() {
		window.setTimeout(function() {
			// only save on blur if the element is still in edit-mode (e.g., cancel wasn't clicked)
			// and if neither the save nor cancel button is focused
			if (note.hasClass("is-editing") && !$("a", note).is(":focus")) {
				save();
			}
		}, 200);
	}

	function cancel() {
		editable.html(originalNoteText);
		note.removeClass("is-editing");
		instructions.html(
			'<a href="#" class="tab-hook">Click to edit</a> <i class="hicon fa fa-pencil"></i>'
		);

		if (originalNoteText === "") {
			resetForEmptyNote();
		}

		return false;
	}

	function resetForEmptyNote() {
		note.hideMe();
		addLink.showMe();
	}

	function initDisplayState() {
		if (!editable.html()) {
			resetForEmptyNote();
		} else {
			note.showMe();
			addLink.hideMe();
		}
	}
}

export default {
	init: init
};
