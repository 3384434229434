﻿define(["./ntp", "./ticker", "./events"], function (ntp, ticker, Emitter) {
	//ticker is a singleton that will faithfully tick for us no matter how many separate timers we may instantiate
	var identityMap = {};

	function Timer(toDate) {
		if (!(this instanceof arguments.callee)) {
			throw new Error("Constructor called as a function");
		}

		if (!toDate || !(toDate instanceof Date)) {
			throw new Error("Timer start requires a toDate of type Date to be specified.");
		}

		var existing = checkIdentityMap(toDate);
		if (existing) {
			return existing;
		}

		this.toDate = toDate;
		identityMap[toDate.valueOf()] = this;
	}

	function checkIdentityMap(toDate) {
		var value = toDate.valueOf();

		if (identityMap[value]) {
			return identityMap[value];
		}
	}

	Timer.prototype = new Emitter();

	Timer.prototype.start = function () {
		if (!this.isRunning) {
			//check if already running - do nothing if we are
			this.isRunning = true;

			//sync the client's clock to the server clock
			ntp.sync().done(function (now) {
				this.events = new Events(this.toDate, function (ev) {
					var isDone = ev.totalSecondsLeft <= 0;

					if (isDone) {
						this.stop();
					}

					this.emit("tick", ev);

					if (isDone) {
						this.emit("done", ev);
					}
				}.bind(this));
			}.bind(this));
		}
	};

	Timer.prototype.stop = function () {
		if (this.isRunning) {
			if (this.events) {
				this.events.clear();
				this.events = null;
			}

			this.isRunning = false;
		}
	};

	function Events(toDate, callback) {
		if (!(this instanceof arguments.callee))
			throw new Error("Constructor called as a function");

		this.values = {};
		this.handlers = ['seconds', 'minutes', 'hours', 'days'];

		this.secondsLeft = calculateSecondsLeft(toDate);
		this.toDate = toDate;
		this.callback = callback;

		if (this.dispatch()) {
			this.subscription = this.trigger.bind(this);
			ticker.subscribe(this.subscription);
		}
	}

	Events.prototype.trigger = function () {
		// Calculate the time offset
		this.secondsLeft = calculateSecondsLeft(this.toDate);

		if (this.secondsLeft < 0) {
			this.secondsLeft = 0;
		}

		this.dispatch();
	};

	Events.prototype.dispatch = function () {
		if (this.callback) {
			var ev = {
				seconds: this.secondsLeft % 60,
				minutes: Math.floor(this.secondsLeft / 60) % 60,
				hours: Math.floor(this.secondsLeft / 60 / 60) % 24,
				days: Math.floor(this.secondsLeft / 60 / 60 / 24),
				toDate: this.toDate,
				totalSecondsLeft: this.secondsLeft
			};

			this.callback.call(this, ev);

			return this.secondsLeft > 0;
		}
	};

	Events.prototype.clear = function () {
		this.callback = null;
		setTimeout(function () {
			ticker.unsubscribe(this.subscription);
		}, 1);
	};

	function calculateSecondsLeft(toDate) {
		return Math.floor((toDate.valueOf() - ntp.fixTime()) / 1000);
	}

	return Timer;
});