import React from "react";
import CancelBid from "../../../cancel";
import { get, startCase } from "lodash";

function shouldShowCancelButton(bid, auction) {
	if (!auction.isClosed) {
		return bid.isMaxBid;
	}

	let winningBidAmount = get(auction, "winningBid.amount") || 0;
	let isGTEWinning = bid.amount >= winningBidAmount;

	let winningBidder = get(auction, "winningBid.bidder");
	let isBidderWinner = bid.bidder === winningBidder;

	return isGTEWinning && isBidderWinner;
}

const BidActions = ({ auction, bid, showCancelButton }) => {
	if (showCancelButton && shouldShowCancelButton(bid, auction)) {
		return <CancelBid bid={bid} auction={auction} />;
	}

	if (bid.isRejected) {
		return (
			<strong className="small">
				Rejected: {startCase(bid.rejectionReason)}
			</strong>
		);
	}

	if (bid.isCanceled) {
		return <strong className="small">Canceled: {bid.cancelReason}</strong>;
	}

	return null;
};

export default BidActions;
