export function withContext(id, context) {
	if (!context) {
		return id;
	}

	return `${context.toUpperCase()}_${id}`;
}

export function extractLotNumber(ctx) {
	if (!ctx) return ctx;

	const values = ctx.split("_");

	if (values.length !== 2) return ctx;

	return values[1];
}
