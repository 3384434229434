import ko from "knockout";
import $ from "jquery";

var identityMap = {};

function Model(auction, data) {
	var existing = checkIdentityMap(auction, data);
	if (existing) {
		return existing;
	}

	this.auction = auction;
	this.loadStatus = ko.observable();

	this.id = ko.observable();
	this.username = ko.observable();

	this.name = ko.observable();

	this.isInList = ko.observable();
	this.isInList.subscribe(
		function(isInList) {
			if (this.loadStatus() === "loaded") {
				this.loadStatus("saving");

				$.ajax("/api/watchlists/items", {
					type: isInList ? "POST" : "DELETE",
					data: {
						id: this.id(),
						auctionIds: [this.auction.id()]
					},
					context: this,
					traditional: true
				})
					.done(function() {
						this.loadStatus("loaded");
					})
					.fail(function() {
						this.loadStatus("error-saving");
					});
			}
		}.bind(this)
	);

	refreshFromData(this, data);
	this.loadStatus("loaded");

	identityMap[`${this.auction.id()}_${this.id()}`] = this;
}

function checkIdentityMap(auction, data) {
	if (auction && data && identityMap[`${auction.id()}_${data.id}`]) {
		var list = identityMap[`${auction.id()}_${data.id}`];

		list.loadStatus("loading");
		refreshFromData(list, data);
		list.loadStatus("loaded");

		return list;
	}
}

function refreshFromData(list, data) {
	for (var prop in data) {
		if (data.hasOwnProperty(prop) && list[prop]) {
			list[prop](data[prop]);
		}
	}
}

Model.addNew = function(auction, name) {
	var promise = $.Deferred();

	$.ajax("/api/watchlists", {
		type: "POST",
		data: {
			name: name
		},
		context: this
	})
		.done(function(data) {
			promise.resolve(new Model(auction, data));
		})
		.fail(promise.reject);

	return promise;
};

Model.getByAuction = function(auction) {
	var promise = $.Deferred();

	$.ajax("/api/watchlists", {
		type: "GET",
		data: {
			auctionId: auction.id()
		},
		context: this
	})
		.done(function(data) {
			promise.resolve(
				ko.utils.arrayMap(data.lists, function(listData) {
					return new Model(auction, listData);
				})
			);
		})
		.fail(promise.reject);

	return promise;
};

export default Model;
