import React, { Component } from "react";
import { PropTypes as t } from "prop-types";
import { get } from "lodash";
import PlaceBidForm from "./form";
import BidWaiting from "./waiting";
import Rejection from "./rejection";
import Validation from "./validation";
import Placeholders from "./placeholders";
import { Overlay } from "react-bootstrap";

import connect from "./connect";

const formStyle = { position: "relative" };

class PlaceBid extends Component {
	constructor(props) {
		super(props);

		let amount = get(props, "auction.userNextBidAmount", 0);
		if (get(props, "auction.bidType") == "Money" && isDecimal(amount)) {
			amount = toFixed(amount);
		}

		this.state = { amount };
	}

	submitBid = ev => {
		ev.preventDefault();
		let { amount } = this.state;
		// If its a money decimal amount, format it to 2 decimal places before submitting
		if (this.props.auction.bidType == "Money" && isDecimal(amount)) {
			amount = toFixed(amount);
			this.setState({
				amount: amount
			});
		}

		this.props.onSubmitBid(this.props.auction.lotNumber, amount);
	};

	onAmountChanged = amount => {
		// If money and they try to input a decimal number with precision great than 2 (ex 1500.135), dont change state
		if (
			this.props.auction.bidType == "Money" &&
			isDecimal(toFixed(amount * 100)) // Greater than 2 decimal places
		) {
			return;
		}

		this.setState({ amount: Number(amount) });
	};

	isTarget() {
		return (
			!!this.props.currentBid &&
			this.props.auction.lotNumber === this.props.currentBid.lotNumber
		);
	}

	isSubmitting = () => {
		return this.isTarget() && this.props.currentBid.isSubmitting;
	};

	isProcessing = () => {
		return (
			this.isTarget() &&
			this.props.currentBid.isSubmitted &&
			!this.props.currentBid.isAccepted &&
			!this.props.currentBid.isRejected
		);
	};

	isBidding = () => {
		return this.isSubmitting() || this.isProcessing();
	};

	isRejected = () => {
		return this.isTarget() && this.props.currentBid.isRejected;
	};

	render() {
		const {
			auction,
			isAuthenticated,
			showPlaceholder,
			showSignInButton,
			currentBid,
			tooltipPlacement,
			size
		} = this.props;

		if (!auction || !auction.isBiddingInfoLoaded) return null;

		const {
			userNextBidAmount,
			bidType,
			thresholds,
			isLoading,
			isError,
			isCanceled,
			isOpen,
			isEnded
		} = auction;
		const { amount } = this.state;

		if (!isOpen || isEnded || isError || isLoading || isCanceled) {
			return <Placeholders {...{ showPlaceholder }} {...auction} />;
		}

		if (!isAuthenticated) {
			return showSignInButton ? (
				<a
					className="btn btn-primary"
					href={`/login?ReturnUrl=${document.location.pathname}`}
				>
					Sign In to Place Bid
				</a>
			) : null;
		}

		const slowWarning = this.isBidding() ? (
			<BidWaiting
				isProcessing={this.isProcessing()}
				threshold={5000}
				tooltipPlacement={tooltipPlacement}
			/>
		) : null;
		const rejectedWarning = this.isRejected() ? (
			<Rejection
				rejectionReason={currentBid.rejectionReason}
				tooltipPlacement={tooltipPlacement}
			/>
		) : null;
		const tooltip = rejectedWarning || slowWarning;

		const isMoney = bidType === "Money";
		const min = isMoney ? userNextBidAmount : thresholds.terminalBid;
		const max = isMoney
			? thresholds.terminalBid || 1000000000
			: userNextBidAmount;

		const tooltipOverlay = (
			/* eslint-disable react/jsx-no-bind */
			<Overlay show container={this} target={() => this._placeBidForm}>
				<div>{tooltip}</div>
			</Overlay>
		);

		return (
			<div style={formStyle} ref={e => (this._placeBidForm = e)}>
				<PlaceBidForm
					{...{ amount, auction, max, min, size }}
					isSubmitting={this.isSubmitting()}
					isProcessing={this.isProcessing()}
					onAmountChanged={this.onAmountChanged}
					onSubmitBid={this.submitBid}
				/>
				<Validation {...{ min, max, bidType, amount }} />
				{tooltipOverlay}
			</div>
		);
	}
}

PlaceBid.propTypes = {
	auction: t.object.isRequired,
	isAuthenticated: t.bool.isRequired,
	currentBid: t.object,
	size: t.string,
	tooltipPlacement: t.oneOf(["top", "right", "bottom", "left"]),
	showPlaceholder: t.bool,
	showSignInButton: t.bool,
	onSubmitBid: t.func.isRequired
};

PlaceBid.defaultProps = {
	showPlaceholder: true,
	showSignInButton: false,
	tooltipPlacement: "bottom"
};

const isDecimal = amount => (Number(amount) ? Number(amount) % 1 != 0 : false);
const toFixed = amount => (Number(amount) ? Number(amount).toFixed(2) : amount);

const ConnectedPlaceBid = connect(PlaceBid);

const WrapPlaceBid = props => (
	<ConnectedPlaceBid key={get(props, "auction.userNextBidAmount")} {...props} />
);

export default WrapPlaceBid;
