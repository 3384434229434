import ko from "knockout";

function AuctionType(ta) {
	var type = ko.observable();

	type.friendly = ko.computed(function() {
		return type() ? type().replace(/([A-Z])/g, " $1") : "";
	}, this);

	type.isTaxSale = ko.computed(function() {
		return type() === "TaxSale";
	}, this);

	type.isAdjudicationSale = ko.computed(function() {
		return type() === "Adjudication";
	}, this);

	type.isSecondaryMarket = ko.computed(function() {
		return type() === "SecondaryMarket";
	}, this);

	type.verbose = ko.pureComputed(function() {
		if (type.isAdjudicationSale()) {
			return "Adjudicated Property Auction";
		}

		if (type.isTaxSale()) {
			return "Tax Certificate Sale";
		}

		// else
		return type();
	}, this);

	type.showReceivables = ko.computed(function() {
		// HACK:
		// TODO: Add this to TA or AuctionInfo settings.
		if (ta == "CCM" || ta == "HCT") {
			return false;
		}
		return true;
	}, this);

	type.css = ko.computed(function() {
		return type.isTaxSale()
			? "auction-type__icon--tax-sale fa-file"
			: type.isAdjudicationSale()
				? "auction-type__icon--adjudication-sale icon-noun--deed-sale"
				: type.isSecondaryMarket()
					? "auction-type__icon--secondary-market fa-bar-chart"
					: "";
	}, this);

	return type;
}

export default AuctionType;
