import React from "react";
import { PropTypes as t } from "prop-types";

import acct from "accounting";
import formatBid from "./../../amount/format-bid";

const PlaceBidConfirmationIntent = props => {
	const csAuction = props.csAuction;

	const isPercentage = props.auction.bidType.toLowerCase() === "percentage";

	const formattedBid = formatBid(props.amount, props.auction.bidType);

	const confirmMessageStyle = {
		marginTop: 0
	};

	var auctionSummary = auctionHasAddress(csAuction) ? (
		<span>
			<br />
			<small>on</small>
			<br />
			{csAuction.address.address1} {csAuction.address.address2}
		</span>
	) : null;

	const summary = isPercentage ? (
		<span>
			{formattedBid} interest
			{auctionSummary}
			<br />
			<small>at a price of</small>
			<br />
			{acct.formatMoney(props.auction.price)}
		</span>
	) : (
		<span>
			{formattedBid}
			{auctionSummary}
		</span>
	);

	return (
		<h1 style={confirmMessageStyle}>
			<small>You are about to place a bid for</small>
			<br />
			{summary}
		</h1>
	);
};

function auctionHasAddress(auc) {
	return (
		auc &&
		auc.isLoaded &&
		auc.address &&
		(auc.address.address1 || auc.address.address2)
	);
}

PlaceBidConfirmationIntent.propTypes = {
	auction: t.object.isRequired,
	csAuction: t.object,
	amount: t.number.isRequired
};

export default PlaceBidConfirmationIntent;
