import { fetchOnUpdate } from "fetch-helpers";
import connect from "./connect";

export default function fetchActiveAuctions(component) {
	const Fetcher = fetchOnUpdate(({ fetchUserAuctions, username }) => {
		if (username) {
			fetchUserAuctions(username);
		}
	}, "username")(component);

	return connect(Fetcher);
}
