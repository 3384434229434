import ko from "knockout";
import $ from "jquery";
import { debounce } from "lodash";
import legacy from "./legacy";
import "knockout-template?name=note-editor-main!html?-minimize!./index.html";
import "./../../less/user-notes.less";

ko.bindingHandlers.noteEditor = {
	init: function(el, valueAccessor) {
		el.initNoteArea = debounce(function() {
			legacy.init($(".user-note-container-ko", $(el)), function(newNote) {
				var auction = ko.utils.unwrapObservable(valueAccessor());
				auction.user.note(newNote);
			});
		}, 75);

		return {
			controlsDescendantBindings: true
		};
	},
	update: function(el, valueAccessor) {
		var auction = ko.utils.unwrapObservable(valueAccessor());

		$(el).empty();
		ko.renderTemplate("note-editor-main", auction, null, el, "replaceChildren");

		el.initNoteArea();
	}
};

ko.virtualElements.allowedBindings.noteEditor = true;
