import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import useSheet from "react-jss";
import { Navbar, Nav, Grid } from "react-bootstrap";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Headroom from "react-headroom";
import { bootstrap } from "toetag";
import { partition } from "lodash";
import NavOverlay from "./nav-overlay";
import links from "./links";
import DesktopMenu from "./desktop-menu";
import MobileMenu from "./mobile-menu";
import ReactPixel from "react-facebook-pixel";
import Intercom from "react-intercom";

import styles from "./styles";
import logo from "./logo.svg";

import { connectCurrentUser } from "@civicsource/users";

const timeout = { enter: 1000, exit: 350 };

const fbPixelOptions = {
	autoConfig: true,
	debug: false
};

/* eslint-disable react/no-find-dom-node */

// Removing lint for this rule: findDOMNode is used to for its .contains method
// which we are using to detect a click outside the menu. Using a ref returns
// an object reference to the node, not the node itself, which does not have the
// .contains method. If this needs to be changed in the future, possible alternatives
//  include implementing a .contains polyfill for refs, or using id's instead of refs.

class StatefulNav extends Component {
	state = {
		openMenu: null,
		mouseIn: null,
		navbarOpen: false
	};

	componentDidMount() {
		window.addEventListener("resize", this.windowCollapsed);
		window.addEventListener("click", this.navbarCollapsed);
		window.addEventListener("submit", this.handleSubmit);
		this.windowCollapsed();

		ReactPixel.init("402913840663871", {}, fbPixelOptions);
		ReactPixel.pageView();
	}

	componentDidUpdate() {
		window.onhashchange = this.setNavStyleForMap;
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.windowCollapsed);
		window.removeEventListener("click", this.navbarCollapsed);
		window.removeEventListener("submit", this.handleSubmit);
	}

	ugh = () => {
		// workaround for https://github.com/KyleAMathews/react-headroom/issues/109
		// it looks like someone was set to answer it but then died
		// and fell on the submit button
		const leftovers = document.getElementsByClassName("headroom--unpinned");
		const whyAreYouStillHere = leftovers ? leftovers[0] : null;

		if (whyAreYouStillHere) {
			whyAreYouStillHere.classList.remove([
				"headroom--unpinned",
				"headroom--scrolled"
			]);
			whyAreYouStillHere.style.transform = "translateY(0)";
		}
	};

	navbarCollapsed = e => {
		const container = findDOMNode(this._navbar);
		const clickInside = container ? container.contains(e.target) : null;
		if (!clickInside) this.hideMenu();
	};

	handleSubmit = e => {
		if (e.target === document.getElementById("search-form")) {
			this.hideMenu();
			e.target.submit();
		}
	};

	windowCollapsed = () => {
		const collapsed = window.matchMedia(
			`(max-width: ${bootstrap.gridFloatBreakpointMax})`
		).matches;

		this.setState({ windowCollapsed: collapsed });
	};

	setNavStyleForMap = () => {
		if (
			document
				.getElementsByTagName("html")[0]
				// this class is set by /src/client/legacy/map/fullscreen/main.js
				.classList.contains("map-search-mode")
		) {
			this.setState({ mapView: true });
			document
				.getElementById("app-navbar")
				.setAttribute("style", "height: 0px");

			const navbar = document.getElementsByClassName("navbar")[0];

			navbar.firstChild.classList.remove("container");
			navbar.setAttribute("style", "opacity: .75");
			document
				.getElementsByClassName("remove-on-maps")[0]
				.classList.remove("container");
		} else {
			this.setState({ mapView: false });
		}
	};

	navHover() {
		if (this.state.mapView) {
			document
				.getElementsByClassName("navbar")[0]
				.setAttribute("style", "opacity: 1");
		}
	}

	setOpenMenu = openMenu => {
		if (openMenu.href) return;
		this.state.mapView
			? this.setState(prevState => ({
					openMenu: !prevState.openMenu
						? openMenu
						: openMenu.text === prevState.openMenu.text ? null : openMenu
				}))
			: this.setState(prevState => ({
					openMenu: !prevState.openMenu
						? openMenu
						: openMenu.text === prevState.openMenu.text ? null : openMenu
				}));
	};

	hideMenu = mouseOut => {
		this.ugh();
		if (!this.state.openMenu && !this.state.windowCollapsed) {
			return;
		}
		if (
			(this.state.openMenu && !this.state.openMenu.search) ||
			(this.state.openMenu && this.state.openMenu.search && !mouseOut)
		) {
			this.setState({ openMenu: null });
		}
		if (!mouseOut) {
			this.hideMobileMenu();
		}
	};

	hideMenuTrue = () => this.hideMenu(true);

	hideMobileMenu() {
		const navToggle = findDOMNode(this._navToggle);
		if (navToggle && !navToggle.classList.contains("collapsed")) {
			navToggle.click();
		}
	}

	debounceHideMenu = () => {
		if (this.state.openMenu) {
			this.setState({ mouseIn: null });
			setTimeout(() => {
				if (!this.state.mouseIn) {
					this.hideMenu(true);
					if (this.state.mapView) {
						setTimeout(() => {
							document
								.getElementsByClassName("navbar")[0]
								.setAttribute("style", "opacity: .75");
						}, 300);
					}
				}
			}, 500);
		} else if (this.state.mapView) {
			document
				.getElementsByClassName("navbar")[0]
				.setAttribute("style", "opacity: .75");
		}
	};

	handleMouseEnter = () => {
		this.setState({ mouseIn: true });
		this.navHover();
	};

	render() {
		const { classes } = this.props;
		const menuLinks = links(this.props.user);
		const { openMenu } = this.state;

		const navOpacity =
			!this.state.openMenu && this.state.mapView
				? { opacity: 0.75 }
				: { opacity: 1 };

		const [leftLinks, rightLinks] = partition(
			menuLinks,
			({ rightLink }) => !rightLink
		);
		const topLinks = !this.state.windowCollapsed ? (
			<DesktopMenu
				setOpenMenu={this.setOpenMenu}
				shoppingCart={classes.shoppingCart}
				selected={openMenu ? openMenu.text : false}
				user={this.props.user}
				{...{ classes, leftLinks, rightLinks, menuLinks }}
			/>
		) : (
			<MobileMenu
				tabItems={menuLinks}
				shoppingCart={classes.shoppingCart}
				user={this.props.user}
			/>
		);

		const dropDownLinks =
			openMenu !== null ? (
				<CSSTransition
					timeout={timeout}
					classNames={{
						enter: classes.navOverlayEnter,
						enterActive: classes.navOverlayEnterActive,
						exit: classes.navOverlayExit,
						exitActive: classes.navOverlayExitActive,
						appear: classes.navOverlayAppear,
						appearActive: classes.navOverlayAppearActive
					}}
				>
					<NavOverlay
						tabItems={openMenu.links}
						search={openMenu.search}
						mapView={this.state.mapView}
						user={this.props.user}
					/>
				</CSSTransition>
			) : null;

		return (
			<Headroom
				onUnpin={this.hideMenuTrue}
				onPin={this.ugh}
				className={classes.headroom}
				downTolerance={20}
				upTolerance={10}
			>
				<div>
					<Navbar
						inverse
						className={classes.nav}
						onMouseLeave={this.debounceHideMenu}
						onMouseEnter={this.handleMouseEnter}
						ref={el => (this._navbar = el)}
						style={navOpacity}
					>
						{this.state.windowCollapsed ? (
							<div>
								<Grid
									fluid={this.state.mapView}
									style={{
										maxHeight: "80vh",
										overflowY: "auto",
										overflowX: "hidden"
									}}
								>
									<Navbar.Header>
										<div className="row" ref={el => (this._navArea = el)}>
											<div className="col-xs-9">
												<a href="/">
													<img
														src={logo}
														style={{ maxHeight: "51px", maxWidth: "100%" }}
													/>
												</a>
											</div>
											<div className="col-xs-2 pull-right">
												<Navbar.Toggle ref={el => (this._navToggle = el)} />
											</div>
										</div>
									</Navbar.Header>
									<Navbar.Collapse>
										<Nav>
											<div className="col-md-12">{topLinks}</div>
										</Nav>
									</Navbar.Collapse>
								</Grid>
							</div>
						) : (
							<div>
								<div className="container remove-on-maps">
									<div className="col-sm-3" style={{ height: "51px" }}>
										<a href="/">
											<img
												src={logo}
												style={{ height: "51px", maxWidth: "100%" }}
											/>
										</a>
									</div>
									<div className={classes.topLinks}>{topLinks}</div>
								</div>

								<div
									style={{
										position: "relative",
										overflow: "visible",
										height: 0,
										zIndex: 0.9
									}}
								>
									{!this.state.mapView ? (
										<TransitionGroup>{dropDownLinks}</TransitionGroup>
									) : (
										<TransitionGroup>{dropDownLinks}</TransitionGroup>
									)}
								</div>
							</div>
						)}
						<Intercom appID="spuexrad" />
					</Navbar>
				</div>
			</Headroom>
		);
	}
}

export default connectCurrentUser(useSheet(styles)(StatefulNav));
