import React from "react";

const BidLabelContainer = ({
	auction: { isBiddingInfoLoaded, biddingInfoLoadError, bidCount }
}) => {
	if (!isBiddingInfoLoaded) {
		return null;
	}

	if (biddingInfoLoadError) {
		return <span>Error loading auction...</span>;
	}

	return <span>{bidCount > 0 ? "Winning Bid" : "Starting Bid"}</span>;
};

export default BidLabelContainer;
