define(["es5-shim"], function () {
	var EventEmitter = function () { };

	// Begin wrap of nodejs implementation of EventEmitter

	var isArray = Array.isArray;

	EventEmitter.prototype.emit = function (type) {
		// If there is no 'error' event listener then throw.
		if (type === 'error') {
			if (!this._events || !this._events.error ||
				(isArray(this._events.error) && !this._events.error.length)) {
				if (arguments[1] instanceof Error) {
					throw arguments[1]; // Unhandled 'error' event
				} else {
					throw new Error("Uncaught, unspecified 'error' event.");
				}
				return false;
			}
		}

		if (!this._events) return false;
		var handler = this._events[type];
		if (!handler) return false;

		if (typeof handler == 'function') {
			switch (arguments.length) {
				// fast cases
				case 1:
					handler.call(this);
					break;
				case 2:
					handler.call(this, arguments[1]);
					break;
				case 3:
					handler.call(this, arguments[1], arguments[2]);
					break;
					// slower
				default:
					var args = Array.prototype.slice.call(arguments, 1);
					handler.apply(this, args);
			}
			return true;

		} else if (isArray(handler)) {
			var args = Array.prototype.slice.call(arguments, 1);

			var listeners = handler.slice();
			for (var i = 0, l = listeners.length; i < l; i++) {
				listeners[i].apply(this, args);
			}
			return true;

		} else {
			return false;
		}
	};

	// EventEmitter is defined in src/node_events.cc
	// EventEmitter.prototype.emit() is also defined there.
	EventEmitter.prototype.addListener = function (type, listener) {
		if ('function' !== typeof listener) {
			throw new Error('addListener only takes instances of Function');
		}

		if (!this._events) this._events = {};

		// To avoid recursion in the case that type == "newListeners"! Before
		// adding it to the listeners, first emit "newListeners".
		this.emit('newListener', type, listener);

		if (!this._events[type]) {
			// Optimize the case of one listener. Don't need the extra array object.
			this._events[type] = listener;
		} else if (isArray(this._events[type])) {
			// If we've already got an array, just append.
			this._events[type].push(listener);
		} else {
			// Adding the second element, need to change to array.
			this._events[type] = [this._events[type], listener];
		}

		return this;
	};

	EventEmitter.prototype.on = EventEmitter.prototype.addListener;

	EventEmitter.prototype.once = function (type, listener) {
		var self = this;
		self.on(type, function g() {
			self.removeListener(type, g);
			listener.apply(this, arguments);
		});

		return this;
	};

	EventEmitter.prototype.removeListener = function (type, listener) {
		if ('function' !== typeof listener) {
			throw new Error('removeListener only takes instances of Function');
		}

		// does not use listeners(), so no side effect of creating _events[type]
		if (!this._events || !this._events[type]) return this;

		var list = this._events[type];

		if (isArray(list)) {
			var i = list.indexOf(listener);
			if (i < 0) return this;
			list.splice(i, 1);
			if (list.length == 0)
				delete this._events[type];
		} else if (this._events[type] === listener) {
			delete this._events[type];
		}

		return this;
	};

	EventEmitter.prototype.removeAllListeners = function (type) {
		// does not use listeners(), so no side effect of creating _events[type]
		if (type && this._events && this._events[type]) this._events[type] = null;
		return this;
	};

	EventEmitter.prototype.listeners = function (type) {
		if (!this._events) this._events = {};
		if (!this._events[type]) this._events[type] = [];
		if (!isArray(this._events[type])) {
			this._events[type] = [this._events[type]];
		}
		return this._events[type];
	};

	// End nodejs implementation

	return EventEmitter;
});