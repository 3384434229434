import ko from "knockout";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import CostViewer from "../cost/viewer";

export default function(store) {
	ko.bindingHandlers.costViewer = {
		init: function(element) {
			ko.utils.domNodeDisposal.addDisposeCallback(element, function() {
				ReactDOM.unmountComponentAtNode(element);
			});

			return { controlsDescendantBindings: true };
		},
		update: function(element, valueAccessor, allBindings) {
			var value = ko.utils.unwrapObservable(valueAccessor());
			var props = ko.toJS(allBindings.get("props"));

			if (value) {
				ReactDOM.render(
					<Provider store={store}>
						<CostViewer {...props} />
					</Provider>,
					element
				);
			}
		}
	};
}
