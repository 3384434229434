import React from "react";
import { PropTypes as t } from "prop-types";
import { UserDisplay } from "@civicsource/users";

const Bidder = props => {
	const bid = props.bid;

	if (bid.isYourBid) {
		return <span>You</span>;
	}

	const url = props.getUserUrl ? props.getUserUrl(bid.bidder) : null;

	const adminDisplay = url ? (
		<a href={url}>
			<UserDisplay username={bid.bidder} />
		</a>
	) : (
		<UserDisplay username={bid.bidder} />
	);
	const userDisplay = url ? (
		<a href={url}>
			<span>{bid.bidder}</span>
		</a>
	) : (
		<span>{bid.bidder}</span>
	);

	if (props.canManageTaxSale) {
		return adminDisplay;
	}

	return userDisplay;
};

Bidder.propTypes = {
	bid: t.object.isRequired,
	canManageTaxSale: t.bool,
	getUserUrl: t.func
};

export default Bidder;
