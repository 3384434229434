import { createAjaxAction, asyncifyAction } from "redux-rsi";
import { getCurrentUser } from "@civicsource/users";
import { getCheckoutByUsername } from "@civicsource/auctioneer.core";
import {
	fetchCheckout as apifetchCheckout,
	fetchCheckoutCount as apifetchCheckoutCount,
	makeCheckoutPayment as apiMakeCheckoutPayment
} from "../api/admin";

export function fetchCheckout(username) {
	return createAjaxAction(
		{
			type: "CHECKOUT_FOR_USER_FETCH",
			payload: { username }
		},
		getState => {
			const state = getState();
			const user = getCurrentUser(state);

			const checkout = getCheckoutByUsername(state, username);

			if (checkout.isLoading) return;

			return apifetchCheckout(user);
		}
	);
}

export function fetchCheckoutCount(username) {
	return createAjaxAction(
		{
			type: "CHECKOUT_COUNT_FOR_USER_FETCH",
			payload: { username }
		},
		getState => {
			const state = getState();
			const user = getCurrentUser(state);

			const checkout = getCheckoutByUsername(state, username);

			if (checkout.isLoadingCount) return;

			return apifetchCheckoutCount(user);
		}
	);
}

export function makeCheckoutPayment({ username, items, bankAccount }) {
	const type = "CHECKOUT_MAKE_PAYMENT";
	const payload = { username };
	const actions = asyncifyAction({
		type,
		payload
	});

	return function*(getState) {
		const state = getState();
		const auth = getCurrentUser(state);
		const checkout = getCheckoutByUsername(state, username);
		if (checkout.isMakingPayment || checkout.isPaymentMade) return;

		yield actions.request;

		let response = null;

		try {
			response = yield apiMakeCheckoutPayment({ items, bankAccount, auth });
		} catch (err) {
			if (err.message == "Checkout items have changed.") {
				yield {
					type: `${type}_ITEMS_CHANGED`,
					payload: err,
					meta: payload // the original payload
				};
				yield fetchCheckout(username);
			} else {
				yield actions.failed(err);
			}
		}

		if (response) {
			yield actions.completed(response);
		}
	};
}
