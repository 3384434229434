import ko from "knockout";
import ViewModel from "./model";
import "knockout.punches";
import "knockout-template?name=page-alerts-main!html?-minimize!./index.html";

ko.punches.enableAll();
ko.punches.interpolationMarkup.enable();
ko.punches.attributeInterpolationMarkup.enable();

ko.bindingHandlers.pageAlerts = {
	init: function() {
		return {
			controlsDescendantBindings: true
		};
	},
	update: function(element, valueAccessor) {
		ko.renderTemplate(
			"page-alerts-main",
			new ViewModel(ko.unwrap(valueAccessor())),
			null,
			element,
			"replaceChildren"
		);
	}
};

ko.virtualElements.allowedBindings.pageAlerts = true;
