/* eslint-disable inflection/no-plural-filenames */
import { createReducer, mergeWithCurrent } from "redux-rsi";
import Immutable from "seamless-immutable";

function init() {
	return Immutable({
		isLoading: false,
		isLoaded: false,
		isError: false,
		isAgreeingToTerms: false,
		isAgreeingToTermsError: false,
		hasAgreedToTerms: false,
		terms: null
	});
}

const normalize = id => {
	return id ? id.toLowerCase() : id;
};
export default createReducer(Immutable({}), {
	onFetchUserCurrentBidderListTerms(state, { bidderListId }) {
		return mergeWithCurrent(
			state,
			normalize(bidderListId),
			{
				isLoading: true,
				isError: false
			},
			init
		);
	},

	onFetchUserCurrentBidderListTermsCompleted(state, results, { bidderListId }) {
		return mergeWithCurrent(
			state,
			normalize(bidderListId),
			Immutable(results).merge({
				isLoading: false,
				isLoaded: true
			}),
			init
		);
	},

	onFetchUserCurrentBidderListTermsFailed(state, err, { bidderListId }) {
		return mergeWithCurrent(
			state,
			normalize(bidderListId),
			{
				isLoading: false,
				isLoaded: false,
				isError: true
			},
			init
		);
	},

	onAgreeToBidderListTerms(state, { bidderListId }) {
		return mergeWithCurrent(
			state,
			normalize(bidderListId),
			{
				isAgreeingToTerms: true,
				isAgreeingToTermsError: false
			},
			init
		);
	},

	onAgreeToBidderListTermsCompleted(state, results, { bidderListId }) {
		return mergeWithCurrent(
			state,
			normalize(bidderListId),
			Immutable(results).merge({
				isAgreeingToTerms: false
			}),
			init
		);
	},

	onAgreeToBidderListTermsFailed(state, err, { bidderListId }) {
		return mergeWithCurrent(
			state,
			normalize(bidderListId),
			{
				isAgreeingToTerms: false,
				isAgreeingToTermsError: true
			},
			init
		);
	}
});

export function getUsersCurrentBidderListTermsByBidderListId(state, id) {
	return state[normalize(id)] || state.set(id, init())[normalize(id)];
}
