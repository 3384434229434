import React, { Component } from "react";
import { PropTypes as t } from "prop-types";
import { Modal } from "react-bootstrap";
import YouTube from "react-youtube";
import useSheet from "react-jss";
import styles from "./index.style";

class ExtensionHelp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isModalOpen: false
		};
	}

	showModal = ev => {
		if (ev) {
			ev.preventDefault();
		}
		this.setState({
			isModalOpen: true
		});
	};

	hideModal = ev => {
		if (ev) {
			ev.preventDefault();
		}
		this.setState({
			isModalOpen: false
		});
	};

	render() {
		const { classes } = this.props;

		const helpWhatsThis = <u className="small">What is this?</u>;
		const helpIcon = <i className="fa fa-question-circle text-primary" />;
		const canExtendLinkContent = (
			<span>{helpIcon} End time may be extended</span>
		);

		const helpLink = (
			<a className={classes.helpLink} href="#" onClick={this.showModal}>
				{canExtendLinkContent} {helpWhatsThis}
			</a>
		);

		const youtubeOptions = {
			width: "100%",
			playerVars: {
				autoplay: 1,
				rel: 0
			}
		};

		const extendHelp = (
			<Modal
				bsSize="large"
				show={this.state.isModalOpen}
				onHide={this.hideModal}
			>
				<Modal.Header closeButton>End Time May Be Extended</Modal.Header>
				<div className="modal-body">
					<div className={classes.videoContainer}>
						<YouTube
							videoId="TDQAZ7Qqw7s"
							opts={youtubeOptions}
							className={classes.iframe}
						/>
					</div>
				</div>
				<div className="modal-footer">
					<button className="btn btn-default" onClick={this.hideModal}>
						Close
					</button>
				</div>
			</Modal>
		);

		return (
			<div>
				{helpLink}
				{extendHelp}
			</div>
		);
	}
}

ExtensionHelp.propTypes = {
	endTime: t.string.isRequired
};

export default useSheet(styles)(ExtensionHelp);
