import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { listenFor, stopListening } from "./actions";
import { getConnection } from "./reducer";

export default function connectChannel(component) {
	return connect(
		state => ({
			connection: getConnection(state)
		}),
		dispatch =>
			bindActionCreators(
				{
					listen: listenFor,
					stop: stopListening
				},
				dispatch
			)
	)(component);
}
