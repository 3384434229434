﻿define(["knockout", "accounting"], function (ko, acct) {
	ko.extenders.bidAmount = function (target, isTraditional) {
		var result = ko.computed({
			read: target,
			write: function (value) {
				value = parseFloat(value);
				target(!isNaN(value) ? value : undefined);
			}
		});

		result.formatted = ko.computed({
			read: function () {
				var amount = result();
				if (!amount) {
					return "";
				}

				return ko.utils.unwrapObservable(isTraditional) ? acct.formatMoney(amount) : ((amount * 100).toFixed(0) + "%");
			},
			write: function (value) {
				value = ("" + value).trim();

				if (!value) {
					result(undefined);
				} else {
					if (ko.utils.unwrapObservable(isTraditional)) {
						result(acct.unformat(value));
					} else {
						value = value.replace("%", "").trim();
						value = fuckingJavascript(value / 100.0);
						result(value);
					}
				}
			}
		});

		result.incremented = ko.computed(function () {
			if (ko.utils.unwrapObservable(isTraditional)) {
				return fuckingJavascript(target() + 0.01);
			} else {
				return fuckingJavascript(target() - 0.01);
			}
		});

		function fuckingJavascript(value) {
			//ehhh: http://stackoverflow.com/questions/1458633/elegant-workaround-for-javascript-floating-point-number-problem
			return parseFloat(value.toFixed(2));
		}

		return result;
	};
});