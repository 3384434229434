import { bootstrap } from "toetag";

export default {
	item: {},
	winning: {
		borderLeft: `4px solid ${bootstrap.btnSuccessBorder}`
	},
	canceled: {
		textDecoration: "line-through"
	}
};
