import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { fetchBidHistory, fetchMoreBidHistory } from "./actions";
import { getCurrentUser } from "@civicsource/users";

import connectAuction from "../../auction/connect";

export default function connectBidHistory(component) {
	const Connector = connect(
		state => {
			const currentUser = getCurrentUser(state);
			const canManageTaxSale =
				currentUser &&
				currentUser.permissions &&
				currentUser.permissions.includes("Manage Tax Sales");

			const canCancelBids =
				currentUser &&
				currentUser.permissions &&
				currentUser.permissions.includes("Cancel Bids");

			return { canManageTaxSale, canCancelBids };
		},
		dispatch =>
			bindActionCreators(
				{
					fetchBidHistory,
					fetchMoreBidHistory
				},
				dispatch
			)
	)(component);

	return connectAuction(Connector);
}
