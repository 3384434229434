import ko from "knockout";
import { compact } from "lodash";
import Config from "./config";

function AuctionAlerts(data) {
	var auction = data.auction;

	var cfg = new Config(auction);

	this.canPlaceBid = ko.computed(function() {
		return auction && auction.user && auction.user.canPlaceBids();
	}, this);

	this.cantBidAlert = ko.computed(function() {
		var reason = !this.canPlaceBid()
			? auction.user.disabledReason ? auction.user.disabledReason() : null
			: null;
		return !this.canPlaceBid() && cfg[reason] ? cfg[reason] : null;
	}, this);

	this.researchStatusAlert = ko.computed(function() {
		return auction.status() === "Researching" ||
			auction.status() === "ResearchComplete"
			? cfg.RESEARCH_STATUS
			: null;
	}, this);

	this.linkedSaleMessageAlert = ko.computed(() => {
		return auction.hasLinkedSaleMessage()
			? {
					severity: "warning",
					date: new Date(0),
					subject:
						"Additional taxes for this property are being sold in another auction. See the seller notes below for details.",
					body: null
				}
			: null;
	});

	this.alerts = ko.computed(() => {
		return compact([
			this.cantBidAlert(),
			this.researchStatusAlert(),
			this.linkedSaleMessageAlert()
		]);
	});
}

export default AuctionAlerts;
