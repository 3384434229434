import React, { Component } from "react";
import { Collapse } from "react-bootstrap";
import { get } from "lodash";
import ko from "knockout";
import "knockout-postbox";
import useSheet from "react-jss";
import { bootstrap } from "toetag";
import Apply from "./apply";
import Pending from "./pending";
import Error from "./error";
import Modal from "./modal";

class BidderListApplication extends Component {
	state = { isModalOpen: false, isTermsAgreementChecked: false };

	componentDidUpdate(prevProps) {
		// reload the auction if the agree to terms request just completed
		if (
			!get(this.props, "terms.isAgreeingToTerms") &&
			get(prevProps, "terms.isAgreeingToTerms")
		) {
			this.setState({ isSubmitted: true });
			ko.postbox.publish("bidderListApplicationAgreed");
		}
	}

	onPrompt = (e, readOnly) => {
		if (e && e.preventDefault) {
			e.preventDefault();
		}
		this.setState({ isModalOpen: true, isModalReadOnly: readOnly });
	};

	onAgreementCheckChange = ({ target: { checked } }) =>
		this.setState({ isTermsAgreementChecked: checked });

	onCancel = () =>
		this.setState({ isModalOpen: false, isTermsAgreementChecked: false });

	onAgree = () => {
		this.setState({ isModalOpen: false });
		this.props.agreeToBidderListTerms(
			this.props.taxAuthority,
			this.props.bidderListId,
			this.props.userId,
			this.props.terms.termsId,
			this.props.auction.lotNumber
		);
	};

	render() {
		const { auction, classes, terms } = this.props;
		const { bidderListDeadline, lotNumber, user } = this.props.auction;

		const requiresBidderListTerms = auction.bidderListActionRequired;

		if (
			!this.props.bidderListId ||
			(!requiresBidderListTerms &&
				get(auction, "user.bidderListStatus") != "Pending" &&
				!terms.isAgreeingToTermsError)
		) {
			return null;
		}

		return (
			<div>
				<Collapse
					in={requiresBidderListTerms && !this.state.isSubmitted}
					unmountOnExit
				>
					<div>
						<Apply
							onPrompt={this.onPrompt}
							{...{ terms, lotNumber, bidderListDeadline, user }}
						/>
					</div>
				</Collapse>
				<Collapse
					in={
						!requiresBidderListTerms &&
						get(auction, "user.bidderListStatus") == "Pending"
					}
					unmountOnExit
				>
					<div>
						<Pending
							onPrompt={this.onPrompt}
							{...{ terms, bidderListDeadline }}
						/>
					</div>
				</Collapse>
				<Collapse in={terms.isAgreeingToTermsError} unmountOnExit>
					<div>
						<Error />
					</div>
				</Collapse>
				<Modal
					isModalOpen={this.state.isModalOpen}
					onCancel={this.onCancel}
					onAgree={this.onAgree}
					isTermsAgreementChecked={this.state.isTermsAgreementChecked}
					onAgreementCheckChange={this.onAgreementCheckChange}
					readOnly={this.state.isModalReadOnly}
					{...{ classes, terms }}
				/>
			</div>
		);
	}
}

const styles = {
	termsContent: {
		padding: [0, bootstrap.gridGutterWidth]
	}
};

export default useSheet(styles)(BidderListApplication);
