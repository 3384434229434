import ko from "knockout";
import $ from "jquery";
import "knockout.punches";
import "knockout-template?name=auction-info-block!html?-minimize!./index.html";
import "knockout-template?name=auction-type!html?-minimize!./auction-type.html";
import "knockout-template?name=info-block-address!html?-minimize!./address.html";
import "knockout-template?name=info-block-descriptions!html?-minimize!./descriptions.html";
import "knockout-template?name=info-block-sub-feature!html?-minimize!./sub-feature.html";
import "./info-block.less";
import "./../../less/auction.less";
import "less/tldr.less";

ko.punches.enableAll();

ko.bindingHandlers.auctionInfoBlock = {
	init: function() {
		return {
			controlsDescendantBindings: true
		};
	},
	update: function(element, valueAccessor, allBindings) {
		var model = ko.utils.unwrapObservable(valueAccessor());
		if (model) {
			if (allBindings.has("showLegalDescription")) {
				model.showLegalDescription(!!allBindings.get("showLegalDescription"));
			}
			if (allBindings.has("showSaleTypeIcon")) {
				model.showSaleTypeIcon(!!allBindings.get("showSaleTypeIcon"));
			}
			model.isMapMode(!!allBindings.get("isMapMode"));
			ko.renderTemplate(
				"auction-info-block",
				model,
				{
					afterRender: function() {
						$(document).trigger("rendered.property-info-block");
					}
				},
				element,
				"replaceChildren"
			);
		}
	}
};

ko.virtualElements.allowedBindings.auctionInfoBlock = true;
