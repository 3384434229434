import React, { Component } from "react";
import { PropTypes as t } from "prop-types";
import AmountInput from "./input";
import AmountInputAddon from "./addon";
import ConfirmModal from "./confirmation";
import ButtonContents from "./button-contents";

export default class PlaceBidForm extends Component {
	state = {
		isModalOpen: false
	};

	isModalEnabled = () => {
		const { amount, isProcessing, isSubmitting, max, min } = this.props;
		var isBusy = isSubmitting || isProcessing;
		var isAmountOutOfRange = !amount || amount < min || amount > max;
		return !(isBusy || isAmountOutOfRange);
	};

	onBidIntent = ev => {
		ev.preventDefault();
		if (this.isModalEnabled()) {
			this.setState({
				isModalOpen: true
			});
		}
	};

	hideModal = ev => {
		// bs react modal's handleDocumentKeyup method doesn't pass event
		if (ev) {
			ev.preventDefault();
		}
		this.setState({
			isModalOpen: false
		});
	};

	render() {
		const {
			amount,
			auction,
			isProcessing,
			isSubmitting,
			max,
			min,
			onAmountChanged,
			size
		} = this.props;
		const { isModalOpen } = this.state;
		var isBidding = isSubmitting || isProcessing;

		var confirmModal = isModalOpen ? (
			<ConfirmModal
				{...this.props}
				onHide={this.hideModal}
				showModal={isModalOpen}
			/>
		) : null;

		const isMoney = auction.bidType.toLowerCase() === "money";
		const addon = <AmountInputAddon {...{ isMoney }} />;
		const prefix = isMoney ? addon : null;
		const suffix = isMoney ? null : addon;

		const buttonCx = this.isModalEnabled() ? "" : "disabled";

		const modalButton = (
			<span className="input-group-btn">
				<button type="submit" className={`${buttonCx} btn btn-primary`}>
					<ButtonContents {...this.props} />
				</button>
			</span>
		);

		const sizeClass = INPUT_GROUP_SIZES[size] || null;
		const inputGroupCx = `${BASE} ${sizeClass}`;

		return (
			<div className="place-bid-form">
				<form data-noroute noValidate onSubmit={this.onBidIntent}>
					<div className={inputGroupCx}>
						{prefix}
						<AmountInput
							onChange={onAmountChanged}
							isDisabled={isBidding}
							{...{ auction, amount, min, max }}
						/>
						{suffix}
						{modalButton}
					</div>
				</form>
				{confirmModal}
			</div>
		);
	}
}

PlaceBidForm.propTypes = {
	auction: t.object.isRequired,
	isSubmitting: t.bool.isRequired,
	isProcessing: t.bool.isRequired,
	size: t.oneOf(["sm", "small", "lg", "large"]),
	onAmountChanged: t.func,
	onSubmitBid: t.func,
	min: t.number.isRequired,
	max: t.number.isRequired,
	amount: t.number.isRequired
};

const BASE = "input-group";

const INPUT_GROUP_SIZES = {
	small: `${BASE}-sm`,
	sm: `${BASE}-sm`,
	large: `${BASE}-lg`,
	lg: `${BASE}-l`
};
