import EventEmitter from "eventemitter3";
import { getRequiredConfig } from "@civicsource/runtime-config";

function checkUrl() {
	return `${getRequiredConfig("urls.bidding").replace(/http/, "ws")}activity`;
}

let stubs = null;

let isOpen = false;
let queuedMessages = [];
let socket = null;

let emitter = new EventEmitter();
let messageListeners = {};

subscribeToSocketMessages();

export function stub(theStubs) {
	stubs = theStubs;
}

export function unstub() {
	stubs = null;
}

export function open(authToken) {
	if (stubs && stubs.open) {
		return stubs.open(authToken);
	}

	ensureOpenSocket(authToken);
}

export function close() {
	if (stubs && stubs.close) {
		return stubs.close();
	}

	ensureClosedSocket();
}

export function send(data, authToken) {
	if (stubs && stubs.send) {
		return stubs.send(data, authToken);
	}

	if (!isOpen) {
		ensureOpenSocket(authToken);
	}

	if (socket && socket.readyState === WebSocket.OPEN) {
		socket.send(JSON.stringify(data));
	} else {
		queuedMessages.push(data);
	}
}

export function addListeners(actions) {
	messageListeners = actions;
}

function subscribeToSocketMessages() {
	emitter.addListener("message", msg => {
		var cb = messageListeners[msg.type];
		if (cb) {
			cb(msg);
		}
	});

	emitter.addListener("opened", () => messageListeners.opened());
	emitter.addListener("closed", () => messageListeners.closed());
}

function ensureOpenSocket(authToken) {
	if (socket) {
		if (socket.readyState === WebSocket.OPEN) {
			// somebody doesn't think the socket is open even though we are open, re-raise all the events again
			emitter.emit("opened");
		}
		return;
	}

	let url = checkUrl();
	if (authToken) {
		url += `?auth=${encodeURIComponent(authToken)}`;
	}

	socket = new WebSocket(url);

	socket.onopen = () => {
		isOpen = true;
		emitter.emit("opened");

		while (queuedMessages.length > 0) {
			// send any queued messages on the socket now that it is open
			var msg = queuedMessages.shift();
			send(msg);
		}
	};

	socket.onclose = () => {
		socket = null;
		isOpen = false;
		emitter.emit("closed");
	};

	socket.onerror = err => {
		if (socket.readyState === WebSocket.OPEN) {
			// clear any queued messages - don't want to get stuck in an infinite loop
			queuedMessages = [];
			emitter.emit("error", err);

			// close the connection and try to reconnect
			socket.close();
		}
	};

	socket.onmessage = ev => {
		var msg = JSON.parse(ev.data);
		emitter.emit("message", msg);
	};
}

function ensureClosedSocket() {
	if (!socket) {
		// somebody doesn't think the socket is closed even though it is, re-raise all the events again
		emitter.emit("closed");
		return;
	}

	if (socket && socket.readyState === WebSocket.OPEN) {
		socket.close();
	}
}
