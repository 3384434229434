import { getAuction as apiGetAuction } from "../api/admin";

import { createAjaxAction } from "redux-rsi";
import { getCurrentUser } from "@civicsource/users";

import { getAuctionByLotNumber } from "../reducers";

export function fetchAuction(lotNumber) {
	return createAjaxAction(
		{
			type: "AUCTION_FETCH",
			payload: lotNumber
		},
		getState => {
			const state = getState();
			const { isLoading } = getAuctionByLotNumber(state, lotNumber);

			if (isLoading) return; // don't try to reload if already loading

			return apiGetAuction(lotNumber, getCurrentUser(state));
		}
	);
}
