import { checkStatus, parseJSON } from "fetch-helpers";
import { getRequiredConfig } from "@civicsource/runtime-config";

function checkUrl() {
	return getRequiredConfig("urls.admin");
}

export function getAuction(lotNumber, auth) {
	return fetch(`${checkUrl()}auctions/${lotNumber}/`, {
		method: "GET",
		headers: populateHeaders(auth)
	})
		.then(checkStatus)
		.then(parseJSON);
}

export function getCostsForAuction(lotNumber, auth) {
	return fetch(`${checkUrl()}auctions/${lotNumber}/costs/`, {
		method: "GET",
		headers: populateHeaders(auth)
	})
		.then(checkStatus)
		.then(parseJSON);
}

export function fetchCheckout(auth) {
	return fetch(`${checkUrl()}checkout/`, {
		method: "GET",
		headers: populateHeaders(auth)
	})
		.then(checkStatus)
		.then(parseJSON);
}

export function fetchCheckoutCount(auth) {
	return fetch(`${checkUrl()}checkoutcount/`, {
		method: "GET",
		headers: populateHeaders(auth)
	})
		.then(checkStatus)
		.then(parseJSON);
}

export function makeCheckoutPayment({ items, bankAccount, auth }) {
	return fetch(`${checkUrl()}checkout/`, {
		method: "POST",
		headers: populateHeaders(auth),
		body: JSON.stringify({
			items,
			bankAccount
		})
	})
		.then(checkStatus)
		.then(parseJSON);
}

export function fetchCurrentBidderListTermsForUser(
	taxAuthority,
	bidderListId,
	userId,
	auth
) {
	return fetch(
		`${checkUrl()}${taxAuthority}/bidderLists/${bidderListId}/users/${userId}`,
		{
			method: "GET",
			headers: populateHeaders(auth)
		}
	)
		.then(checkStatus)
		.then(parseJSON);
}

export function agreeToBidderListTerms({
	taxAuthority,
	bidderListId,
	userId,
	termsId,
	lotNumber,
	auth
}) {
	return fetch(
		`${checkUrl()}${taxAuthority}/bidderLists/${bidderListId}/users/${userId}/terms`,
		{
			method: "POST",
			headers: populateHeaders(auth),
			body: JSON.stringify({ lotNumber, termsId })
		}
	)
		.then(checkStatus)
		.then(parseJSON);
}

function populateHeaders(auth) {
	let headers = {
		Accept: "application/json",
		"Content-type": "application/json"
	};

	if (auth) {
		headers.Authorization = `Bearer ${auth.token}`;
	}

	return headers;
}
