import { getCostsForAuction as apiGetCostsForAuction } from "../api/admin";

import { createAjaxAction } from "redux-rsi";
import { getCurrentUser } from "@civicsource/users";

import { getCostsForAuction } from "../reducers";

export function fetchCosts(lotNumber) {
	return createAjaxAction(
		{
			type: "COSTS_FETCH",
			payload: lotNumber
		},
		getState => {
			const state = getState();
			const costs = getCostsForAuction(state, lotNumber);

			if (costs.isLoaded || costs.isLoading) {
				return; // don't try to reload if already loaded or loading
			}

			return apiGetCostsForAuction(lotNumber, getCurrentUser(state));
		}
	);
}
