import ko from "knockout";
import "knockout.validation";

function Address(data) {
	this.address1 = ko.observable();
	this.address2 = ko.observable();
	this.city = ko.observable();
	var state = ko.observable();
	this.state = ko
		.computed({
			read: state,
			write: function(value) {
				if (typeof value === "string") {
					value = {
						value: value,
						description: value
					};
				}
				state(value);
			}
		})
		.extend({
			required: true
		});
	this.postalCode = ko.observable();
	var country = ko.observable();
	this.country = ko.computed({
		read: country,
		write: function(value) {
			if (typeof value === "string") {
				value = {
					value: value,
					description: value
				};
			}
			country(value);
		}
	});

	this.isEmpty = ko.computed(function() {
		return !(
			this.address1() ||
			this.address2() ||
			this.city() ||
			this.postalCode()
		);
	}, this);

	this.formatted = ko.computed(this.toString.bind(this));

	if (data) {
		refreshFromData(this, data);
	}
}

function refreshFromData(addr, data) {
	for (var prop in data) {
		if (data.hasOwnProperty(prop) && addr[prop]) {
			addr[prop](data[prop]);
		}
	}
}

Address.prototype.toString = function() {
	var hasStuff = false;
	var temp = "";

	if (this.address1()) {
		temp = this.address1();
		hasStuff = true;
	}

	if (this.address2()) {
		if (hasStuff) {
			temp += " ";
		}

		temp += this.address2();
		hasStuff = true;
	}

	// if (this.city()) {
	//	if (hasStuff) {
	//		temp += ", ";
	//	}

	//	temp += this.city();
	//	hasStuff = true;
	// }

	// if (this.state()) {
	//	if (hasStuff) {
	//		temp += ", ";
	//	}

	//	temp += this.state().value;
	//	hasStuff = true;
	// }

	// if (this.postalCode()) {
	//	if (hasStuff) {
	//		temp += " ";
	//	}

	//	temp += this.postalCode();
	//	hasStuff = true;
	// }

	var country = this.country()
		? typeof this.country() === "string"
			? this.country()
			: this.country().value || ""
		: "";

	if (country && country !== "US" && country.toLowerCase() !== "unspecified") {
		if (hasStuff) {
			temp += ", ";
		}

		temp += this.country().value;
	}

	return temp;
};

Address.prototype.toSearchString = function() {
	var hasStuff = false;
	var temp = "";

	if (this.address1()) {
		temp = this.address1();
		hasStuff = true;
	}

	if (this.address2()) {
		if (hasStuff) {
			temp += " ";
		}

		temp += this.address2();
		hasStuff = true;
	}

	if (this.city()) {
		if (hasStuff) {
			temp += ", ";
		}

		temp += this.city();
		hasStuff = true;
	}

	if (this.state()) {
		if (hasStuff) {
			temp += ", ";
		}

		temp += this.state().value;
		hasStuff = true;
	}

	if (this.postalCode()) {
		if (hasStuff) {
			temp += " ";
		}

		temp += this.postalCode();
		hasStuff = true;
	}

	if (this.country() && this.country().value.toLowerCase() !== "unspecified") {
		if (hasStuff) {
			temp += ", ";
		}

		temp += this.country().value;
	}

	return temp;
};

export default Address;
