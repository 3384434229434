import React from "react";
import BidAmount from "../amount";
import UserInfo from "./user";
import BidCount from "./../count";
import BidHistoryModal from "./../history/modal";
import cx from "classnames";

import statify from "./state-container";

const AmountAndCount = ({ auction, horizontal, onShowModal }) => {
	if (horizontal) {
		return (
			<div>
				<a className="small" href="#" onClick={onShowModal}>
					<BidCount auction={auction} showText />
				</a>{" "}
				<BidAmount auction={auction} />
			</div>
		);
	}

	return (
		<div>
			<BidAmount auction={auction} />
			<br />
			<a className="small" href="#" onClick={onShowModal}>
				<BidCount auction={auction} showText />
			</a>
		</div>
	);
};

const BidSummary = ({ auction, showModal, onHideModal, ...props }) => {
	if (!auction || !auction.isBiddingInfoLoaded) {
		return null;
	}

	const classNames = cx({
		"bid-summary": true,
		"bid-summary--none": !auction.userCurrentBid,
		"bid-summary--winning text-success":
			!!auction.winningBid && auction.winningBid.isYourBid,
		"bid-summary--losing text-danger":
			auction.userCurrentBid &&
			!!auction.winningBid &&
			!auction.winningBid.isYourBid
	});

	const user =
		auction.bidCount > 0 ? (
			<div className="small">
				<UserInfo auction={auction} />
			</div>
		) : null;

	return (
		<div className={classNames}>
			<AmountAndCount auction={auction} {...props} />
			{user}
			<BidHistoryModal
				show={showModal}
				onHide={onHideModal}
				auction={auction}
			/>
		</div>
	);
};

export default statify(BidSummary);
