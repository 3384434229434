import React from "react";
import formatBid from "./format-bid";
import cx from "classnames";
import { HighlightOnChange } from "@civicsource/ui";

const BidAmount = ({ auction, bid, showText, showLoading }) => {
	if (!auction.isBiddingInfoLoaded) {
		if (auction.biddingInfoLoadError) {
			return <small>Error loading bid data.</small>;
		}

		if (auction.isBiddingInfoMissing) {
			return null;
		}

		return showLoading ? <small>Loading bid data...</small> : null;
	}

	if (bid) {
		if (bid.isLoading) {
			return showLoading ? <small>Loading bid data...</small> : null;
		}

		if (bid.isError) {
			return <small>Error loading bid data.</small>;
		}
	}

	const amount = bid
		? bid.amount
		: auction.winningBid
			? auction.winningBid.amount
			: auction.thresholds.startingBid || null;

	const htmlClasses = cx({
		"bid-amount__amount": true,
		"bid-amount__amount--money": auction.bidType.toLowerCase() === "money",
		"bid-amount__amount--no-bids": !amount
	});

	const contents = !amount ? (
		showText ? (
			<span className={htmlClasses}>No Bids</span>
		) : null
	) : (
		<span className={htmlClasses}>{formatBid(amount, auction.bidType)}</span>
	);

	return <HighlightOnChange subscribeTo={amount}>{contents}</HighlightOnChange>;
};

export default BidAmount;
