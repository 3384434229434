// TODO: (Improvement) Put real dates based on stuff like auction endDate on the alerts so they sort.

function Config(auction) {
	return {
		// Sale Status
		Cancelled: {
			severity: "danger",
			date: new Date(0),
			subject: "Auction Canceled",
			body: `This auction and all bids has been canceled.${
				!auction.auctionType.isAdjudicationSale()
					? " This is usually due to the tax payer making a payment to satisfy their delinquency."
					: ""
			}`
		},
		NotSold: {
			severity: "danger",
			date: new Date(0),
			subject: auction.auctionType.isAdjudicationSale()
				? "Not Sold"
				: "Adjudicated",
			body: auction.auctionType.isAdjudicationSale()
				? "No bids were placed during the auction."
				: "This property has been adjudicated to the taxing authority. Either no bids were placed or the winning bid was not paid by the payment deadline."
		},
		Candidate: {
			severity: "warning",
			date: new Date(0),
			subject: "This property is no longer available for auction",
			body:
				'If you have any questions, please <a href="/content/contact">contact us</a>.'
		},
		// Can't bid
		BiddingDisabled: {
			severity: "danger",
			date: new Date(0),
			subject: "Bidding Disabled",
			body:
				'Bidding has been disabled for your account. Typically, this is a result of non-payment from a previous auction. If you have questions please contact <a href="/content/contact" title="Contact CivicSource Support">support</a>.'
		}
	};
}

export default Config;
