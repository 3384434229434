import { bindActionCreators } from "redux";
import { fetchOnUpdate } from "fetch-helpers";
import { connect } from "react-redux";

import {
	fetchCurrentBidderListTermsForUser,
	agreeToBidderListTerms,
	getUsersCurrentBidderListTermsByBidderListId
} from "@civicsource/auctioneer.core";

export default function connectRoot(Component) {
	const FetchingComponent = fetchOnUpdate(
		({
			auction: { bidderListId },
			userId,
			fetchCurrentBidderListTermsForUser,
			taxAuthority
		}) => {
			if (bidderListId && userId) {
				fetchCurrentBidderListTermsForUser(taxAuthority, bidderListId, userId);
			}
		},
		"auction.bidderListId",
		"auction.userId"
	)(Component);

	return connect(
		(state, { auction: { bidderListId } }) => ({
			bidderListId,
			terms: getUsersCurrentBidderListTermsByBidderListId(state, bidderListId)
		}),
		dispatch =>
			bindActionCreators(
				{
					fetchCurrentBidderListTermsForUser,
					agreeToBidderListTerms
				},
				dispatch
			)
	)(FetchingComponent);
}
