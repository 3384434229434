import { fetchOnUpdate } from "fetch-helpers";

import connectAuction from "../auction/connect";
import connectBid from "./connect";

export default function fetchBid(component) {
	const BidFetcher = fetchOnUpdate(
		({ fetchBid, auction, bidId }) => {
			if (auction && bidId) {
				fetchBid(auction.lotNumber, bidId);
			}
		},
		"auction.lotNumber",
		"bidId"
	)(component);

	return connectBid(connectAuction(BidFetcher));
}
