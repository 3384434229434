import React from "react";
import { Alert, Button } from "react-bootstrap";
import { BusyButton, TimeDisplay } from "@civicsource/ui";
import { withHandlers } from "recompose";

const ApplyBanner = ({
	bidderListDeadline,
	handleApply,
	lotNumber,
	user,
	terms
}) => {
	return (
		<Alert bsStyle="danger">
			<div className="text-center">
				<h4>Approval Required</h4>
				{bidderListDeadline ? (
					<p>
						The seller requires additional steps to participate be met prior to{" "}
						<TimeDisplay
							longDateFormat="dddd, MMMM Do"
							showYear
							time={bidderListDeadline}
							timeFirst
						/>.
					</p>
				) : (
					<p>The seller requires additional steps to participate be met.</p>
				)}
				{user && user.isAuthenticated ? (
					terms && terms.isAgreeingToTerms ? (
						<BusyButton>Loading...</BusyButton>
					) : (
						<Button bsStyle="default" onClick={handleApply}>
							Sale Terms
						</Button>
					)
				) : (
					<Button bsStyle="default" href={`/login?returnUrl=/${lotNumber}`}>
						Sign in
					</Button>
				)}
			</div>
		</Alert>
	);
};

export default withHandlers({
	handleApply: ({ onPrompt }) => e => onPrompt(e, false)
})(ApplyBanner);
