import ko from "knockout";

function ListViewItemModel(data) {
	this.auction = data.auction;

	this.showBidSummary = ko.pureComputed(function() {
		if (!this.auction) {
			return false;
		}

		if (!this.auction.auctionType.isAdjudicationSale()) {
			return true;
		}

		if (
			this.auction.hasDeposit() &&
			!this.auction.status.isPreSale() &&
			!this.auction.status.isPreResearch()
		) {
			return true;
		}

		if (
			this.auction.status.isPreSale() ||
			this.auction.status.isPreResearch()
		) {
			return false;
		}

		return true;
	}, this);
}

export default ListViewItemModel;
