import useSheet from "react-jss";
import { bootstrap } from "toetag";

export default useSheet({
	container: {
		display: "block",
		fontSize: bootstrap.fontSizeBase
	},
	inner: {
		display: "inline-block",
		verticalAlign: "top"
	},
	silentLink: {
		textDecoration: "none"
	},
	titleLink: {
		extend: "silentLink",
		"&:hover, &:focus": {
			extend: "silentLink"
		}
	},
	primary: {
		textDecoration: "none",
		color: "#000",
		borderBottom: "1px solid #c3d2eb",
		textTransform: "uppercase",
		fontWeight: "700",
		"$titleLink:hover &, $titleLink:focus &": {
			borderBottomColor: "#919191"
		}
	},
	address: {
		extend: "primary",
		display: "inline-block"
	},
	lotNumber: {
		color: bootstrap.grayLight,
		fontSize: bootstrap.fontSizeSmall
	},
	taInfo: {
		fontSize: bootstrap.fontSizeSmall
	},
	accountNumber: {
		color: bootstrap.grayLight,
		textTransform: "uppercase"
	}
});
