import React from "react";
import AuctionUserBidItem from "./auction-user-bid";
import { WaitingIndicator as Indicator } from "@civicsource/ui";

const UserHistory = ({
	auctions,
	isLoaded,
	username,
	currentUser,
	getUserUrl,
	children
}) => {
	const canManageTaxSale =
		currentUser &&
		currentUser.permissions &&
		currentUser.permissions.includes("Manage Tax Sales");

	if (!isLoaded) {
		return <Indicator showWaitingIndicator message="Loading Auctions..." />;
	}

	if (!auctions.length) {
		return <span>User has no active auctions.</span>;
	}

	return (
		<table className="table" role="log">
			<thead>
				<tr>
					<th>Auction</th>
					<th className="text-right">Max Bid</th>
					<th className="text-right">Winning Bid</th>
				</tr>
			</thead>
			<tbody>
				{auctions.map(auc => {
					return (
						<AuctionUserBidItem
							auction={auc}
							key={auc.lotNumber}
							username={username}
							getUserUrl={getUserUrl}
							canManageTaxSale={canManageTaxSale}
						>
							{children}
						</AuctionUserBidItem>
					);
				})}
			</tbody>
		</table>
	);
};

export default UserHistory;
