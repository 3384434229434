import React from "react";
import acct from "accounting";
import { filter, sumBy } from "lodash";

const BidStats = ({ bids }) => {
	if (!bids.length) {
		return <div className="well-sm">No bids found.</div>;
	}

	const winningBids = filter(bids, bid => isWinningBid(bid));

	const losingBids = filter(bids, bid => !isWinningBid(bid));

	const winningPrice = sumBy(winningBids, bid => bid.auction.price);

	const winningPriceAvg = winningBids.length
		? winningPrice / winningBids.length
		: 0;

	const losingPrice = sumBy(losingBids, bid => bid.auction.price);

	const losingPriceAvg = losingBids.length
		? losingPrice / losingBids.length
		: 0;

	const stats = [
		{
			status: "Winning",
			rowClass: "text-success",
			count: winningBids.length,
			price: winningPrice,
			priceAverage: winningPriceAvg
		},
		{
			status: "Not Winning",
			rowClass: "text-danger",
			count: losingBids.length,
			price: losingPrice,
			priceAverage: losingPriceAvg
		}
	];

	return (
		<table className="table">
			<thead>
				<tr>
					<th className="text-center">Status</th>
					<th className="text-right">Count</th>
					<th className="text-right">Price</th>
					<th className="text-right">Avg. Price/Property</th>
				</tr>
			</thead>
			<tbody>
				{stats.map(function(stat) {
					return (
						<tr key={stat.status} className={stat.rowClass}>
							<td className="text-center">{stat.status}</td>
							<td className="text-right">{stat.count}</td>
							<td className="text-right">{acct.formatMoney(stat.price)}</td>
							<td className="text-right">
								{acct.formatMoney(stat.priceAverage)}
							</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

function isWinningBid(bid) {
	var winningBid = bid.auction.winningBid;

	return (
		(isYourValidBid(bid) && bid.isWinning) ||
		(isYourValidBid(winningBid) && winningBid.isWinning)
	);
}

function isYourValidBid(bid) {
	return bid && bid.isPublic & bid.isYourBid;
}

export default BidStats;
