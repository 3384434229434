import ko from "knockout";
import "knockout.moment";

function Certificate(taCode) {
	this.status = ko.observable();

	this.isFiled = ko.computed(function() {
		return this.status() === "Filed";
	}, this);

	this.filedOn = ko.observable().extend({
		moment: true
	});

	this.displayStatus = ko.computed(function() {
		if (this.status()) {
			var friendly = this.status()
				.replace(/([A-Z])/g, " $1")
				.toLowerCase();
			return `Certificate ${friendly}${
				this.isFiled() ? ` on ${this.filedOn.usFormat()}` : ""
			}.`;
		}
	}, this);

	this.documentId = ko.observable();

	this.link = ko.computed(function() {
		return this.documentId()
			? `/api/documents/${this.documentId()}?taxAuthority=${taCode()}`
			: null;
	}, this);
}

export default Certificate;
