import React, { Component } from "react";
import { Tab, Tabs, Row, Col, Nav, NavItem } from "react-bootstrap";
import { uniqueId } from "lodash";

import { WaitingIndicator as Indicator, Empty } from "@civicsource/ui";

import BidSummary from "./summary";
import BidDetails from "./entries";
import style from "./styles";

const BidHistoryHorizontal = ({ activeTab, onSelectTab, ...props }) => (
	<Tabs
		id={uniqueId("bid-history-tabs-horizontal")}
		activeKey={activeTab}
		onSelect={onSelectTab}
	>
		<Tab eventKey="summary" title="Summary">
			<BidSummary {...props} />
		</Tab>
		<Tab eventKey="details" title="Detail">
			<BidDetails {...props} />
		</Tab>
	</Tabs>
);

const BidHistoryVertical = ({ activeTab, onSelectTab, ...props }) => (
	<Tab.Container
		id={uniqueId("bid-history-tabs-vertical")}
		activeKey={activeTab}
		onSelect={onSelectTab}
	>
		<Row className="clearfix">
			<Col sm={2}>
				<Nav bsStyle="pills" stacked>
					<NavItem eventKey="summary">Summary</NavItem>
					<NavItem eventKey="details">Detail</NavItem>
				</Nav>
			</Col>
			<Col sm={10}>
				<Tab.Content animation>
					<Tab.Pane eventKey="summary">
						<BidSummary {...props} />
					</Tab.Pane>
					<Tab.Pane eventKey="details">
						<BidDetails {...props} />
					</Tab.Pane>
				</Tab.Content>
			</Col>
		</Row>
	</Tab.Container>
);

class BidHistoryStateContainer extends Component {
	state = {
		activeTab: "summary"
	};

	onSelectTab = key => {
		this.setState({ activeTab: key });
	};

	render() {
		const { vertical, ...props } = this.props;

		const {
			isBiddingInfoLoading,
			isBiddingInfoLoaded,
			isBiddingInfoMissing,
			biddingInfoLoadError,
			bids
		} = props.auction;

		if (isBiddingInfoMissing) {
			return null;
		}

		if (isBiddingInfoLoading && !isBiddingInfoLoaded) {
			return <Indicator showWaitingIndicator message="Loading bids..." />;
		}

		if (bids.isLoading && !bids.isLoaded) {
			return <Indicator showWaitingIndicator message="Loading bids..." />;
		}

		if (biddingInfoLoadError || bids.isError) {
			return <Indicator message="Error loading bids." />;
		}

		if (!bids.items.length) {
			return (
				<Empty>
					{props.auction.isEnded
						? "No bids were placed."
						: "No bids placed yet."}
				</Empty>
			);
		}

		if (vertical) {
			return (
				<BidHistoryVertical
					{...props}
					{...this.state}
					onSelectTab={this.onSelectTab}
				/>
			);
		}

		return (
			<BidHistoryHorizontal
				{...props}
				{...this.state}
				onSelectTab={this.onSelectTab}
			/>
		);
	}
}

export default style(BidHistoryStateContainer);
