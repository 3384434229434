import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchOnUpdate } from "fetch-helpers";

import {
	getCheckoutByUsername,
	fetchCheckoutCount
} from "@civicsource/auctioneer.core";

import { getCurrentUser } from "@civicsource/users";

export default function connectCheckoutCount(Component) {
	const Fetcher = fetchOnUpdate(({ fetchCheckoutCount, username }) => {
		if (username) {
			fetchCheckoutCount(username);
		}
	}, "username")(Component);

	const Container = connect(
		state => {
			const user = getCurrentUser(state);

			if (user) {
				return {
					username: user.username,
					checkout: getCheckoutByUsername(state, user.username)
				};
			}
		},
		dispatch => bindActionCreators({ fetchCheckoutCount }, dispatch)
	)(Fetcher);

	return Container;
}
