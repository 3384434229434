import React from "react";
import Images from "./images";

const ImageSectionChrome = props => {
	return (
		<div className="line-items__container prop-details__section">
			<div className="section_head">
				<h2>Images</h2>
			</div>
			<div className="gallery__container" style={{ margin: 15 }}>
				<Images {...props} />
			</div>
		</div>
	);
};

export default ImageSectionChrome;
