import { createReducer, mergeWithCurrent } from "redux-rsi";
import Immutable from "seamless-immutable";

import { normalizeLotNumber } from "@civicsource/auctions";

export default createReducer(Immutable({}), {
	onAuctionFetch(state, lotNumber) {
		return mergeWithCurrent(
			state,
			normalizeLotNumber(lotNumber),
			{
				isLoading: true,
				isLoadError: false
			},
			init
		);
	},

	onAuctionFetchCompleted(state, auction, lotNumber) {
		return mergeWithCurrent(
			state,
			normalizeLotNumber(lotNumber),
			Immutable(auction).merge({
				isLoading: false,
				isLoaded: true
			}),
			init
		);
	},

	onAuctionFetchFailed(state, err, lotNumber) {
		return mergeWithCurrent(
			state,
			normalizeLotNumber(lotNumber),
			{
				isLoading: false,
				isLoadError: true
			},
			init
		);
	}
});

function init(lotNumber) {
	return Immutable({
		lotNumber,
		isLoading: false,
		isLoaded: false,
		isLoadError: false
	});
}

export function getAuctionByLotNumber(state, lotNumber) {
	return state[normalizeLotNumber(lotNumber)] || init(lotNumber);
}
