import $ from "jquery";
import "jquery.cookie";
import "./less/auctioneer.less";
import "./less/views/home/main.less";

if (process.env.BROWSER) {
	// add IE document mode class to html element for style forking,
	// because IE10 doesn't support conditional comments.
	if (document.documentMode) {
		document.documentElement.className += ` ie${document.documentMode}`;
	}

	// http://getbootstrap.com/getting-started/#support-ie10-width
	if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
		var msViewportStyle = document.createElement("style");
		msViewportStyle.appendChild(
			document.createTextNode("@-ms-viewport{width:auto!important}")
		);
		document.querySelector("head").appendChild(msViewportStyle);
	}

	$(document).ready(function() {
		// Nuclear option for CS-11657
		$(".ie11 .landing-hero__container .text-phrase").removeClass("text-phrase");
	});
}
