import React from "react";
import moment from "moment";

const DATE_FORMAT = "MMM Do";
const TIME_FORMAT = "h:mm a";

const formatTime = t => {
	t = moment(t);
	return (
		<time dateTime={t.format()}>
			<span className="text-phrase">{t.format(DATE_FORMAT)}</span>{" "}
			<span className="text-phrase">{t.format(TIME_FORMAT)}</span>
		</time>
	);
};

export default formatTime;
