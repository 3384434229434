﻿define(["knockout", "knockout.url", "accounting", "knockout.integer", "es5-shim"], function (ko, url, acct) {
	return function Pager(data) {
		this.mode = typeof (data.totalCount) !== "undefined" ? "server" : "client";

		this.pageNumber = ko.observable(1).extend({ integer: 1 });
		this.pageSize = ko.observable(20).extend({ integer: 25 });
		this.numberOfPagesToDisplay = ko.observable(5).extend({ integer: 5 });

		this.description = ko.observable();

		this.data = ko.observable(data);

		this.totalCount = ko.computed(function () {
			if (this.mode === "client") {
				var items = ko.utils.unwrapObservable(this.data());

				if (!items) {
					return 0;
				}

				return items.length;
			}

			return ko.utils.unwrapObservable(data.totalCount); //totalCount passed from server paging
		}, this).extend({ integer: true });

		this.totalPages = ko.computed(function () {
			return Math.ceil(this.totalCount() / this.pageSize());
		}, this).extend({ integer: true });

		this.itemsOnCurrentPage = ko.computed(function () {
			var items = ko.utils.unwrapObservable(this.data());

			if (!items) {
				return [];
			}

			if (this.mode === "client") {
				var startIndex = this.pageSize() * (this.pageNumber() - 1);
				return items.slice(startIndex, startIndex + this.pageSize());
			}

			return items; //paged on server
		}, this);

		this.hasNextPage = ko.computed(function () {
			return this.pageNumber() < this.totalPages();
		}, this);

		this.hasPreviousPage = ko.computed(function () {
			return this.pageNumber() > 1;
		}, this);

		this.pageLink = function (number) {
			var link = url.current();

			if (link) {
				link = link.clone().setSearch("page", number);
				return link.toString();
			}
		}.bind(this);

		this.nextPage = function () {
			this.pageNumber(this.pageNumber() + 1);
		};

		this.nextPageLink = ko.computed(function () {
			return this.pageLink(this.pageNumber() + 1);
		}, this);

		this.previousPageLink = ko.computed(function () {
			return this.pageLink(this.pageNumber() - 1);
		}, this);

		this.previousPage = function () {
			this.pageNumber(this.pageNumber() - 1);
		};

		this.firstPageLink = ko.computed(function () {
			return this.pageLink(1);
		}, this);

		this.lastPageLink = ko.computed(function () {
			return this.pageLink(this.totalPages());
		}, this);

		this.firstOnPage = ko.computed(function () {
			return ((this.pageNumber() - 1) * this.pageSize()) + 1;
		}, this).extend({ integer: true });

		this.lastOnPage = ko.computed(function () {
			var possibleLast = this.pageNumber() * this.pageSize();
			return possibleLast <= this.totalCount() ? possibleLast : this.totalCount();
		}, this).extend({ integer: true });

		this.pageBoundsDescription = ko.computed(function () {
			if (this.totalCount() > 1) {
				return [
					this.firstOnPage.formatted(),
					'to',
					this.lastOnPage.formatted(),
					'of'
				].join(' ');
			} else {
				return '';
			}
		}, this);

		this.description.formatted = ko.computed(function () {
			return this.description() ? this.description() + (this.totalCount() !== 1 ? 's' : '') : '';
		}, this);

		this.totalCountDescription = ko.computed(function () {
			return [
				this.totalCount.formatted(),
				this.description.formatted()
			].join(' ');
		}, this);

		this.pageBounds = ko.computed(function () {
			var start = 1, end = this.totalPages();

			if (this.totalPages() > this.numberOfPagesToDisplay()) {
				var middle = Math.ceil(this.numberOfPagesToDisplay() / 2.0) - 1;
				var below = this.pageNumber() - middle;
				var above = this.pageNumber() + middle;

				if (below < 4) {
					above = this.numberOfPagesToDisplay();
					below = 1;
				} else if (above > (this.totalPages() - 4)) {
					above = this.totalPages();
					below = this.totalPages() - this.numberOfPagesToDisplay();
				}

				start = below;
				end = above;
			}

			return ko.utils.range(start, end);
		}, this);

		this.pageBounds.formatted = ko.computed(function () {
			return ko.utils.arrayMap(this.pageBounds(), function (item) {
				return {
					value: item,
					formatted: acct.formatNumber(item)
				};
			});
		}, this);

		this.showFirstPageLink = ko.computed(function () {
			return this.pageBounds().indexOf(1) === -1;
		}, this);

		this.showLastPageLink = ko.computed(function () {
			return this.pageBounds().indexOf(this.totalPages()) === -1;
		}, this);
	};
});