import React from "react";
import { AuctionIcon, Address as AddressDisplay } from "@civicsource/ui";
import style from "./style";

const AuctionInfoBlock = ({
	auction,
	taxAuthority,
	auctionUrl,
	taxAuthorityUrl,
	classes: cx
}) => {
	if (!isAuctionLoaded(auction)) {
		return <span>{auction.lotNumber}</span>;
	}

	const taInfo =
		taxAuthority && taxAuthority.isLoaded ? (
			<div className={cx.taInfo}>
				<a href={taxAuthorityUrl}>{taxAuthority.politicalSubDivision}</a>
				{", "}
				<a href="#">{taxAuthority.state}</a>{" "}
				<span className={cx.accountNumber}>
					{auction.property.accountNumber}
				</span>
			</div>
		) : null;

	return (
		<div className={cx.container}>
			<div className={cx.inner}>
				<AuctionIcon size="4em" type={auction.auctionType} />
			</div>
			<div className={cx.inner}>
				<a
					href={auctionUrl ? auctionUrl : `/${auction.lotNumber}`}
					className={cx.titleLink}
				>
					<span className={cx.address}>
						<AddressDisplay
							singleLine
							showState={false}
							address={auction.property.address}
						/>
					</span>{" "}
					<span className={cx.lotNumber}>{auction.lotNumber}</span>
				</a>
				{taInfo}
			</div>
		</div>
	);
};

function isAuctionLoaded(auction) {
	// don't just look at auction.isLoaded
	// take a more nuanced approach and check the things we care about for this component

	if (!auction.auctionType) {
		return false;
	}

	if (!auction.property || !auction.property.address) {
		return false;
	}

	return true;
}

export default style(AuctionInfoBlock);
