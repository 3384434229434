import { bootstrap } from "toetag";

const textStyle = {
	textDecoration: "none",
	color: bootstrap.textColor
};

export default {
	helpLink: {
		display: "inline-block",
		...textStyle,
		"&:hover": { ...textStyle },
		"&:focus": { ...textStyle },
		"&>u": {
			color: bootstrap.linkColor
		}
	},
	videoContainer: {
		position: "relative",
		paddingTop: `${100 * 9 / 16}%`
	},
	iframe: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%"
	}
};
