import React, { Component } from "react";
import CancelBidModal from "./modal";
import CancelButton from "./button";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { cancelBid } from "../actions";

const CancelBid = ({
	bid,
	cancelAll,
	showModal,
	hideModal,
	isModalOpen,
	onCancelBid,
	...props
}) => {
	if (
		!bid ||
		bid.isLoading ||
		bid.isCanceled ||
		bid.cancellation.isCanceled ||
		!bid.isMaxBid
	) {
		return null;
	}

	return (
		<div>
			<CancelButton
				bid={bid}
				cancelAll={cancelAll}
				showIcon
				size="sm"
				onCancelBid={showModal}
			/>
			<CancelBidModal
				bid={bid}
				cancelAll={cancelAll}
				{...props}
				onCancelBid={onCancelBid}
				onHide={hideModal}
				isModalOpen={isModalOpen}
			/>
		</div>
	);
};

class CancelBidStateContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showModal: false,
			cancelAll: props.auction.isEnded, // default to false unless the auction has ended, then it should be true by default
			cancelReason: ""
		};

		this.onShowModal = this.onShowModal.bind(this);
		this.onHideModal = this.onHideModal.bind(this);
		this.onToggleCancelAll = this.onToggleCancelAll.bind(this);
		this.onReasonChange = this.onReasonChange.bind(this);
		this.onCancelBid = this.onCancelBid.bind(this);
	}

	onShowModal() {
		this.setState({
			showModal: true
		});
	}

	onHideModal() {
		this.setState({
			showModal: false
		});
	}

	onToggleCancelAll() {
		this.setState(state => ({
			cancelAll: !state.cancelAll
		}));
	}

	onReasonChange(reason) {
		this.setState({
			cancelReason: reason
		});
	}

	onCancelBid() {
		this.props.onCancelBid(
			this.props.auction.lotNumber,
			this.props.bid.id,
			this.state.cancelReason,
			this.state.cancelAll
		);
	}

	render() {
		return (
			<CancelBid
				{...this.props}
				cancelAll={this.state.cancelAll}
				cancelReason={this.state.cancelReason}
				isModalOpen={this.state.showModal}
				showModal={this.onShowModal}
				hideModal={this.onHideModal}
				onToggleCancelAll={this.onToggleCancelAll}
				onReasonChange={this.onReasonChange}
				onCancelBid={this.onCancelBid}
			/>
		);
	}
}

const CancelBidReduxContainer = connect(null, dispatch =>
	bindActionCreators({ onCancelBid: cancelBid }, dispatch)
)(CancelBidStateContainer);

export default CancelBidReduxContainer;
