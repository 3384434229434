import React, { Component } from "react";
import { PropTypes as t } from "prop-types";

export default class BidAmountInput extends Component {
	componentDidUpdate(prevProps) {
		var inputEl = this._bidAmountInput;
		// if input doesn't have focus and it just went from disabled to enabled
		if (
			inputEl !== document.activeElement &&
			!this.props.isDisabled &&
			prevProps.isDisabled
		) {
			setTimeout(() => inputEl.focus(), 0);
		}
	}

	onBidAmountChanged = ev => {
		const { bidType } = this.props.auction;
		const amount = ev.target.value;
		var value = normalizePercentagesForCalculations(
			bidType,
			// Money can now be a decimal, so leave it alone, but make percentage an int
			bidType == "Percentage" ? parseInt(amount, 10) : amount
		);

		if (this.props.onChange) {
			this.props.onChange(value);
		}
	};

	render() {
		const auction = this.props.auction;

		const increment = normalizePercentagesForDisplay(
			auction.bidType,
			auction.thresholds.bidIncrement || 1
		);
		const min = normalizePercentagesForDisplay(auction.bidType, this.props.min);
		const max = normalizePercentagesForDisplay(auction.bidType, this.props.max);
		const amount = normalizePercentagesForDisplay(
			auction.bidType,
			this.props.amount
		);

		return (
			<input
				type="number"
				pattern={auction.bidType == "Money" ? "\\d*.?\\d{0,2}" : "\\d*"}
				className="form-control"
				min={min}
				max={max}
				step={increment}
				value={amount}
				onChange={this.onBidAmountChanged}
				ref={e => (this._bidAmountInput = e)}
				disabled={this.props.isDisabled}
			/>
		);
	}
}

function normalizePercentagesForDisplay(type, value) {
	if (type === "Percentage") {
		return Number((value * 100.0).toFixed(0));
	}

	return value;
}

function normalizePercentagesForCalculations(type, value) {
	if (type === "Percentage") {
		return Number((value / 100.0).toFixed(2));
	}

	return value;
}

BidAmountInput.propTypes = {
	auction: t.object.isRequired,
	amount: t.number.isRequired,
	isDisabled: t.bool.isRequired,
	onAmountChanged: t.func,
	min: t.number.isRequired,
	max: t.number.isRequired
};
