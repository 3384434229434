import ko from "knockout";
import "knockout-postbox";
import $ from "jquery";
import AuctionBidding from "./bidding";
import AuctionType from "./type";
import AuctionStatus from "./status";
import Certificate from "./certificate";
import Bid from "./../bid/model";
import AuctionUser from "./user";
import currentUser from "./../user";
import searchPref from "./../search/preference";
import Document from "./../document/model";
import Address from "./../address";
import acct from "accounting";
import moment from "moment";
import { debounce, map } from "lodash";
import Emitter from "events";
import "../../knockout-integration";
import "lazy";
import "bid/amount";
import "knockout.integer";
import "knockout.moment";

import { getAuctionsForUser, fetchUserAuctions } from "@civicsource/auctions";
import store from "../store";

var URL = "/api/auctions/";
var identityMap = {};

function Model(data) {
	var existing = checkIdentityMap(data);
	if (existing) {
		return existing;
	}

	// Reset moment's relative time thresholds.
	moment.relativeTimeThreshold("s", 60);
	moment.relativeTimeThreshold("m", 60);
	moment.relativeTimeThreshold("h", 24);
	moment.relativeTimeThreshold("d", 30);
	moment.relativeTimeThreshold("M", 12);

	this.loadStatus = ko.observable();
	this.isLoading = ko.computed(function() {
		return this.loadStatus() === "loading";
	}, this);
	this.isError = ko.computed(function() {
		return this.loadStatus() === "error-loading";
	}, this);

	this.id = ko.observable();

	this.isOffline = ko.observable();

	this.auctionType = new AuctionType(
		data ? (data.taxAuthority ? data.taxAuthority.code : null) : null
	);

	this.sellerNotesHtml = ko.observable();

	this.hasLinkedSaleMessage = ko.observable();

	this.caveats = ko.observableArray();

	this.redemptivePeriodDescription = ko.observable();

	this.hasRedemptivePeriod = ko.observable();

	this.isAdjoiningLandOwner = ko.observable();
	this.showAdjoiningNote = ko.observable();

	this.taxAuthority = {
		name: ko.observable(),
		code: ko.observable(),
		feeModel: ko.observable(),
		w9Info: {
			hasW9Info: ko.observable(false),
			processingInstructions: ko.observable(),
			w9FormUrl: ko.observable(),
			email: ko.observable(),
			emailLink: ko.observable(),
			faxNumber: ko.observable(),
			physicalAddress: ko.observable(),
			mailingAddress: ko.observable()
		}
	};

	this.cityFipsCode = ko.observable();
	this.countyFipsCode = ko.observable();
	this.stateFipsCode = ko.observable();
	this.politicalSubDivisionLink = ko.computed(function() {
		var link = `state=${this.stateFipsCode()}`;

		if (this.countyFipsCode) {
			link += `&politicalSubDivision=${this.countyFipsCode()}`;
		}

		if (this.cityFipsCode()) {
			link += `&city=${this.cityFipsCode()}`;
		}

		return link;
	}, this);

	this.accountNumber = ko.observable();
	this.lotNumber = ko.observable();
	this.address = new Address();
	this.legalDescription = ko.observable();

	this.templateName = ko.computed(function() {
		return "watchlist-auction-item";
	}, this);

	this.sale = {
		id: ko.observable(),
		code: ko.observable(),
		name: ko.observable(),
		type: ko.observable(),
		isOffline: ko.observable(),
		location: ko.observable(),
		displayName: ko.computed(function() {
			if (this.auctionType.isTaxSale()) {
				return `${this.taxAuthority.name()} - ${this.sale.name()}`;
			}

			if (this.auctionType.isAdjudicationSale()) {
				return `${this.taxAuthority.name()} - Adjudication Sale`;
			}

			if (this.auctionType.isSecondaryMarket()) {
				return `${
					this.taxAuthority.name() ? `${this.taxAuthority.name()} - ` : ""
				}Secondary Sale`;
			}
		}, this),
		searchLink: ko.computed({
			read: function() {
				var urlModel = {
					taxAuthority: this.taxAuthority.code()
				};

				return `${searchPref.get()}?${$.param(urlModel)}`;
			},
			owner: this,
			deferEvaluation: true
		})
	};

	this.location = ko.observable();
	this.location.equalityComparer = function(a, b) {
		// force undefined == null for location
		if (!a && !b) return true;
		if (!a || !b) return false;
		return a.latitude === b.latitude && a.longitude === b.longitude;
	};

	this.parcelBoundary = ko.observable();

	this.owner = {
		name: ko.observable(),
		address: ko.observable()
	};

	this.title = ko.observable();
	this.description = ko.observable();

	this.showLegalDescription = ko.observable(false);
	this.showSaleTypeIcon = ko.observable(true);

	this.isMapMode = ko.observable();

	this.link = ko.observable();
	this.link.target = ko.computed(function() {
		return this.isMapMode && this.isMapMode() ? "_blank" : "";
	}, this);

	this.imageId = ko.observable();
	this.imageLink = ko.observable();

	this.adjCertificateDoc = ko.lazyObservable(
		function() {
			Document.getAdjCertificateForAuctionId(this.id).then(
				function(doc) {
					this.adjCertificateDoc(doc.length ? doc[0] : null);
				}.bind(this)
			);
		}.bind(this)
	);

	this.showAdjCertificateLink = ko.pureComputed({
		read: function() {
			return (
				this.auctionType.isAdjudicationSale() && !!this.adjCertificateDoc()
			);
		},
		owner: this,
		deferEvaluation: true // do not evaluate immediately when created
	});

	this.mapLink = ko.observable();

	this.adjudicationDate = ko.observable().extend({
		moment: true
	});
	this.instrumentFilingDate = ko.observable().extend({
		moment: true
	});
	this.redemptionPeriodExpiresOn = ko.observable().extend({
		moment: true
	});
	this.purchasedOn = ko.observable().extend({
		moment: true
	});

	this.certificateInfo = new Certificate(this.taxAuthority.code);

	this.isRecent = ko.observable();

	this.madePublicOn = ko.observable().extend({
		moment: true
	});

	this.madePublicOn.fromNow = ko.computed(function() {
		return this.madePublicOn()
			? `Added ${this.madePublicOn().fromNow()}.`
			: null;
	}, this);

	this.startDate = ko.observable().extend({
		moment: true
	});
	this.startDate.isToday = ko.computed(function() {
		if (this.startDate()) {
			return moment().isSame(this.startDate(), "day");
		}
	}, this);

	this.startDate.isThisYear = ko.computed(function() {
		if (this.startDate()) {
			return moment().isSame(this.startDate(), "year");
		}
	}, this);

	this.startDate.display = ko.computed(function() {
		if (this.startDate()) {
			var pattern = `${(this.startDate.isToday() ? "[today] " : "MMM DD ") +
				(this.startDate.isThisYear() ? "" : "YYYY ")}[at] hh:mm:ss A`;
			return this.startDate().format(pattern);
		}
	}, this);

	this.displaySpecialNote = ko.computed(function() {
		return (
			this.taxAuthority.code() === "CDS" &&
			this.auctionType.isAdjudicationSale()
		);
	}, this);

	this.endDate = ko.observable().extend({
		moment: true
	});

	this.depositPrice = ko.observable();
	this.depositPrice.formatted = ko.computed(function() {
		return acct.formatMoney(this.depositPrice());
	}, this);

	this.hasDeposit = ko.observable();

	this.showDepositPanel = ko.pureComputed(function() {
		return !(
			!this.hasDeposit() &&
			(this.status.isPreResearch() ||
				this.status.isPreSale() ||
				this.status.isDuringSale() ||
				this.status.isPostSale())
		);
	}, this);

	this.price = ko.observable();
	this.price.formatted = ko.computed(function() {
		return acct.formatMoney(this.price());
	}, this);

	this.startingPrice = ko.observable();
	this.startingPrice.formatted = ko.computed(function() {
		return acct.formatMoney(this.startingPrice());
	}, this);

	this.status = new AuctionStatus();

	this.seller = {
		name: ko.observable(),
		username: ko.observable()
	};

	this.showDescription = ko.computed(function() {
		return this.description() || this.seller.name();
	}, this);

	this.timeline = {
		madePublicOn: ko.observable().extend({
			moment: true
		}),
		depositedOn: ko.observable().extend({
			moment: true
		}),
		depositPendingOn: ko.observable().extend({
			moment: true
		}),

		researchPendingOn: ko.observable().extend({
			moment: true
		}),
		researchCompleteOn: ko.observable().extend({
			moment: true
		}),

		activeOn: ko.observable().extend({
			moment: true
		}),
		closedOn: ko.observable().extend({
			moment: true
		}),
		canceledOn: ko.observable().extend({
			moment: true
		}),
		closingScheduledDate: ko.observable().extend({
			moment: true
		})
	};

	this.bidding = new AuctionBidding(this);
	this.biddingStatus = ko.observable();
	this.biddingType = ko.observable();
	this.user = new AuctionUser(this);

	this.sortableEnd = ko.computed(function() {
		var result = Infinity;

		if (this.endDate()) {
			var m = this.endDate().clone();

			if (this.bidding.isOpen()) {
				result = m.unix();
			} else {
				result = m.add(10, "years").unix();
			}
		}
		return result;
	}, this);

	this.auctionItemCssClass = ko.computed(function() {
		return {
			"auction-item--active": this.bidding.isOpen()
		};
	}, this);

	this.onBidPlaced = function(bidData) {
		if (bidData.auctionId === this.id()) {
			var bid = new Bid(bidData);

			if (currentUser && currentUser.username() === bid.bidder()) {
				if (bid.isWinning()) {
					this.bidding.winningBid(bid.amount());
				}

				this.user.currentBid(bid);
				this.user.startingBid(bid.amount.incremented());

				this.bidding.bidCount(this.bidding.bidCount() + 1);
			}

			this.refresh();
		}
	}.bind(this);

	this.bidderListId = ko.observable();
	this.bidderListCurrentTermsVersion = ko.observable();
	this.bidderListDeadline = ko.observable();
	this.hasBidderListDeadlinePassed = ko.observable();

	this.actionRequired = ko.observable();
	this.bidderListActionRequired = ko.observable();
	this.canPlaceBids = ko.observable();

	if (data) {
		refreshFromData(this, data);
		this.loadStatus("loaded");
		identityMap[data.id] = this;
	} else {
		this.loadStatus("new");
	}

	ko.postbox.subscribe(
		"bidderListApplicationAgreed",
		function() {
			this.refresh();
		}.bind(this)
	);
}

Model.prototype = new Emitter();

function checkIdentityMap(data) {
	if (data && identityMap[data.id]) {
		var auction = identityMap[data.id];
		refreshFromData(auction, data);
		auction.loadStatus("loaded");
		return auction;
	}
}

function getParcelBoundaryByLotNumber(lotNumber) {
	return $.ajax(URL, {
		type: "GET",
		traditional: true,
		headers: {
			Accept: "application/vnd.geo+json"
		},
		data: {
			lotNumber: lotNumber
		}
	});
}

function refreshFromData(auction, data, parent) {
	var prop;

	if (!data) {
		for (prop in auction) {
			if (
				auction.hasOwnProperty(prop) &&
				ko.isWriteableObservable(auction[prop])
			) {
				auction[prop](null);
			}
		}
	} else {
		for (prop in data) {
			if (parent === "user" && prop === "currentBid") {
				auction[prop](data[prop] ? new Bid(data[prop]) : null);
			} else if (parent === "taxAuthority" && prop === "w9Info") {
				refreshFromData(auction[prop], data[prop], prop);
			} else if (
				!parent &&
				(prop === "address" ||
					prop === "bidding" ||
					prop === "user" ||
					prop === "seller" ||
					prop === "taxAuthority" ||
					prop == "sale" ||
					prop == "owner" ||
					prop == "timeline" ||
					prop == "certificateInfo")
			) {
				refreshFromData(auction[prop], data[prop], prop);
			} else if (data.hasOwnProperty(prop) && auction[prop]) {
				auction[prop](data[prop]);
			}
		}
	}
}

Model.prototype.refresh = debounce(function(data) {
	if (data) {
		refreshFromData(this, data);
	} else {
		const id = ko.unwrap(this.id);
		getServerAuctionById(this, id);
	}
}, 300);

Model.clearIdentityMap = function() {
	identityMap = {};
};

Model.getBySeller = function(results) {
	var auctions = results || ko.observableArray();

	auctions.loaded = $.ajax(URL, {
		type: "GET"
	}).done(function(data) {
		auctions(
			ko.utils.arrayMap(data.auctions, function(auction) {
				return new Model(auction);
			})
		);
	});

	return auctions;
};

Model.getBidsForUser = function(username, results) {
	var auctions = results || ko.observableArray();

	var resolve, reject;

	var prom = new Promise((res, rej) => {
		resolve = res;
		reject = rej;
	});

	const unsub = store.subscribe(() => {
		const result = getAuctionsForUser(store.getState(), username);
		if (result.isLoaded) {
			resolve(result.auctions);
		}

		if (result.isError) {
			reject(result);
		}
	});

	store.dispatch(fetchUserAuctions(username));

	auctions.loaded = prom
		.then(auctions =>
			Promise.all(
				map(auctions, auction =>
					$.ajax("/api/auctions", {
						type: "GET",
						data: {
							lotNumber: auction.lotNumber
						}
					})
				)
			)
		)
		.then(data => {
			auctions(
				ko.utils.arrayMap(data, function(auction) {
					return new Model(auction);
				})
			);
		})
		.then(unsub);

	return auctions;
};

Model.getById = function(id) {
	if (identityMap[id]) {
		return identityMap[id];
	}

	var auction = new Model();
	getServerAuctionById(auction, id);

	identityMap[id] = auction;
	return auction;
};

Model.getByLatLon = function(lat, lon, results) {
	var auctions = results || ko.observableArray();

	auctions.loaded = $.ajax(URL, {
		type: "GET",
		data: {
			lat: lat,
			lon: lon
		}
	})
		.done(function(data) {
			auctions(
				ko.utils.arrayMap(data, function(auction) {
					return new Model(auction);
				})
			);
		})
		.fail(function() {
			var failureAuction = new Model();
			failureAuction.loadStatus("error-loading");
			auctions([failureAuction]);
		});

	return auctions;
};

Model.find = function(params) {
	var promise = $.Deferred();

	const auctionPromise = $.ajax(URL, {
		type: "GET",
		data: params
	});

	const parcelPromise = getParcelBoundaryByLotNumber(params.lotNumber);

	$.when(auctionPromise, parcelPromise)
		.done(function(auctionResult, parcelResult) {
			const auctionData = auctionResult[0];
			const parcelData = parcelResult[0];

			auctionData.parcelBoundary = parcelData;
			promise.resolve(new Model(auctionData));
		})
		.fail(promise.reject);

	return promise;
};

Model.getAuctionById = function(id) {
	var promise = $.Deferred();

	if (identityMap[id]) {
		return promise.resolve(identityMap[id]);
	}

	$.ajax(URL + id, {
		type: "GET"
	})
		.done(function(data) {
			identityMap[id] = new Model(data);
			promise.resolve(identityMap[id]);
		})
		.fail(promise.reject);

	return promise;
};

function getServerAuctionById(auction, id) {
	$.ajax(URL + id, {
		type: "GET"
	})
		.done(function(data) {
			refreshFromData(auction, data);
			auction.loadStatus("loaded");
		})
		.fail(function() {
			auction.loadStatus("error-loading");
		});

	auction.loadStatus("loading");
}

export default Model;
