const getCookieByName = (name = "") =>
	document && document.cookie
		? document.cookie.split(";").find(c => c.includes(name))
		: null;

const getCookieValue = (cookie = "") =>
	cookie && cookie.includes("=") ? cookie.split("=")[1] : null;

const setCookieValue = (name, value) => {
	document.cookie = `${name}=${value};`;
};

export const USER_SEARCH_PREF = {
	SEARCH: "search",
	MAP: "map"
};

export const setSearchPreferencePath = path => {
	if (process.env.BROWSER) {
		path
			? setCookieValue("user-search-preference", path)
			: toggleSearchPreferencePath();
	}
};

export const toggleSearchPreferencePath = () => {
	if (process.env.BROWSER) {
		const oldValue = getCookieValue(getCookieByName("search-preference"));
		return Object.values(USER_SEARCH_PREF).find(v => v !== oldValue);
	}
};

export const getSearchPreferencePath = () => {
	if (!process.env.BROWSER) {
		return USER_SEARCH_PREF.SEARCH;
	}

	const searchPref = getCookieValue(getCookieByName("search-preference"));
	return searchPref && searchPref.includes(USER_SEARCH_PREF.MAP)
		? USER_SEARCH_PREF.MAP
		: USER_SEARCH_PREF.SEARCH;
};
