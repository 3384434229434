import shoppingCartIcon from "./cart.svg";
import { bootstrap } from "toetag";

const smMinBreakpoint = "@media only screen and (max-width: 1200px)";

const styles = {
	headroom: {
		zIndex: bootstrap.zindexNavbar,
		position: "absolute",
		left: 0,
		right: 0
	},
	nav: {
		borderStyle: "none",
		borderRadius: 0,
		textTransform: "capitalize",
		".modal-open &": {
			borderRight: "8px solid #222"
		}
	},
	topLinks: {
		composes: "col-md-9 col-sm-9",
		height: "51px"
	},
	navLeftLinks: {
		fontSize: "14px",
		fontWeight: 400,
		height: "51px",
		paddingLeft: "10px",
		paddingRight: "10px",
		[smMinBreakpoint]: {
			paddingLeft: "2px",
			paddingRight: "2px"
		},
		"&:hover": {
			color: "#cecece",
			textDecoration: "none"
		},
		"&>a": {
			display: "block"
		}
	},
	navRightLinks: {
		height: "51px",
		paddingLeft: "10px",
		paddingRight: "10px",
		cursor: "default",
		[smMinBreakpoint]: {
			paddingLeft: "2px",
			paddingRight: "2px",
			fontSize: ".9em"
		},
		"&:hover": {
			color: "#cecece",
			textDecoration: "none"
		},
		"&>a": {
			display: "block",
			paddingTop: "11px !important",
			"&:hover": {
				color: "#cecece",
				textDecoration: "none"
			}
		}
	},
	navOverlayEnter: {
		transform: "translateY:(100%)",
		bottom: "-100%"
	},
	navOverlayEnterActive: {
		transform: "translateY(100%)",
		transition: "transform 200ms ease-in-out"
	},
	navOverlayExit: { transform: "translateY(0%)" },
	navOverlayExitActive: {
		transform: "translateY(-100%)",
		transition: "transform 200ms ease-in-out"
	},

	linkText: {
		color: "#cecece",
		"&:hover": {
			color: "#ADD6FF"
		}
	},
	shoppingCart: {
		display: "inline-block",
		width: "1em",
		height: "1em",
		backgroundImage: `url(${shoppingCartIcon})`,
		backgroundSize: "100% auto",
		svg: { color: "inherit" },
		composes: "fa-2x fa",
		lineHeight: "12px"
	}
};

export default styles;
