import React, { Component } from "react";
import { PropTypes as t } from "prop-types";
import customTooltip from "./tooltip.style";

export default class BidWaiting extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showWarning: false
		};
	}

	componentDidMount() {
		this.timeout = setTimeout(() => {
			this.setState({
				showWarning: true
			});
		}, !isNaN(this.props.threshold) ? this.props.threshold : 5000);
	}

	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	render() {
		if (!this.state.showWarning) {
			return null;
		}

		const tooltipPlacement = this.props.tooltipPlacement;
		const styles = customTooltip(tooltipPlacement, null);

		const submittingMessage = (
			<span>
				It's taking longer than expected to submit your bid.
				<br />
				<strong>Please don't leave or refresh the page.</strong>
			</span>
		);

		const processingMessage = (
			<span>
				<strong>
					Your bid has been submitted and you can safely leave this page
				</strong>
				, but it's taking longer than expected to hear back from the server if
				you're the winning bidder.
			</span>
		);

		return (
			<div
				className={`tooltip in ${tooltipPlacement}`}
				style={{ ...styles.tooltipZ }}
			>
				<div className={`tooltip-arrow ${styles.arrow}`} />
				<div
					className="tooltip-inner"
					style={{
						...styles.large,
						...styles.hasIcon,
						...styles.inner
					}}
				>
					{this.props.isProcessing ? processingMessage : submittingMessage}
				</div>
			</div>
		);
	}
}

BidWaiting.propTypes = {
	threshold: t.number,
	isProcessing: t.bool.isRequired,
	tooltipPlacement: t.oneOf(["top", "right", "bottom", "left"])
};

BidWaiting.defaultProps = {
	tooltipPlacement: "bottom"
};
