import $ from "jquery";
import ko from "knockout";
import "knockout.moment";

var URL = "/api/documents";
var identityMap = {};

function Document(data) {
	var existing = checkIdentityMap(data);
	if (existing) {
		return existing;
	}

	this.loadStatus = ko.observable();

	this.id = ko.observable();
	this.taxAuthority = ko.observable();

	this.name = ko.observable();
	this.docTypeName = ko.observable();

	this.isImage = ko.observable();
	this.fileExtension = ko.observable();

	this.mailingType = ko.observable();
	this.recipient = ko.observable();

	this.downloadUrl = ko.computed(function() {
		return `${URL}/${this.id()}?taxAuthority=${this.taxAuthority()}`;
	}, this);

	this.thumbUrl = ko.computed(function() {
		var id = this.id(),
			dims = {
				small: "24x26",
				medium: "75x75",
				large: "250x250"
			},
			buildURL = function(dim) {
				return `${URL}/${id}/${dim}.jpg`;
			};

		return {
			small: buildURL(dims.small),
			medium: buildURL(dims.medium),
			large: buildURL(dims.large)
		};
	}, this);

	this.iconCssClass = ko.computed(function() {
		var cssClasses = {
			"icon-ffmt": true
		};
		cssClasses[`icon-ffmt-${this.fileExtension()}`] = true;

		return cssClasses;
	}, this);

	this.mailingTypeCssClass = function() {
		var type = this.mailingType(),
			cssClass = "mail-";
		if (type === "Certified") cssClass += "certified";
		if (type === "First Class") cssClass += "firstclass";

		return cssClass;
	}.bind(this);

	if (data) {
		refreshFromData(this, data);
		this.loadStatus("loaded");
	} else {
		this.loadStatus("new");
	}
}

function checkIdentityMap(data) {
	if (data && identityMap[data.id]) {
		var doc = identityMap[data.id];
		refreshFromData(doc, data);
		doc.loadStatus("loaded");
		return doc;
	}
}

function refreshFromData(document, data) {
	for (var prop in data) {
		if (data.hasOwnProperty(prop) && document.hasOwnProperty(prop)) {
			document[prop](data[prop]);
		}
	}
	document.createdOn = ko.observable().extend({
		moment: true
	});
	document.createdOn(data.createdOn);
}

Document.clearIdentityMap = function() {
	identityMap = {};
};

Document.getById = function(id) {
	if (identityMap[id]) {
		return identityMap[id];
	}

	var doc = new Document();

	$.ajax(URL, {
		type: "GET",
		data: {
			id: id
		}
	})
		.done(function(data) {
			refreshFromData(doc, data);
			doc.loadStatus("loaded");
		})
		.fail(function() {
			doc.loadStatus("error-loading");
		});

	doc.loadStatus("loading");
	identityMap[id] = doc;

	return doc;
};

Document.getByAuctionId = function(id, docType) {
	var deferred = $.Deferred();

	$.ajax(URL, {
		type: "GET",
		data: {
			auctionId: id,
			docType: docType,
			onlyImages: false
		}
	})
		.done(function(data) {
			deferred.resolve(
				ko.utils.arrayMap(data, function(docData) {
					if (identityMap[docData.id]) {
						var doc = identityMap[docData.id];
						refreshFromData(doc, docData);
						return doc;
					}

					return new Document(docData);
				})
			);
		})
		.fail(deferred.reject);

	return deferred.promise();
};

Document.getImagesByAuctionId = function(id, docType) {
	var deferred = $.Deferred();

	$.ajax(URL, {
		type: "GET",
		data: {
			auctionId: id,
			docType: docType,
			onlyImages: true
		}
	})
		.done(function(data) {
			deferred.resolve(
				ko.utils.arrayMap(data, function(docData) {
					if (identityMap[docData.id]) {
						var doc = identityMap[docData.id];
						refreshFromData(doc, docData);
						return doc;
					}

					return new Document(docData);
				})
			);
		})
		.fail(deferred.reject);

	return deferred.promise();
};

Document.getAdjCertificateForAuctionId = function(id) {
	return Document.getByAuctionId(id, "Adjudication Certificate");
};

export default Document;
