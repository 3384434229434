import React from "react";
import { PropTypes as t } from "prop-types";
import { WaitingIndicator } from "@civicsource/ui";
import useSheet from "react-jss";
import { bootstrap } from "toetag";

const styles = {
	button: {
		margin: `-${bootstrap.paddingBaseVertical} 0`
	}
};

const BidCancelButton = ({ classes, bid, ...props }) => {
	const isProcessing = bid.cancellation.isQueuing || bid.cancellation.isQueued;
	const buttonText = isProcessing ? "Canceling" : "Cancel Bid";
	const spinner = isProcessing ? (
		<div style={{ display: "inline-block" }}>
			<WaitingIndicator reverse={bid.cancellation.isQueued} />
		</div>
	) : null;
	const helpText = bid.cancellation.isNotCanceled
		? "Failed to cancel bid(s)"
		: bid.cancellation.isNotQueued
			? "Failed to queue bid for cancellation"
			: null;
	const helpBlock = helpText ? (
		<span>
			<br />
			<small className="text-danger">{helpText}</small>
		</span>
	) : null;
	const action = props.onCancelBid || function() {};
	const icon =
		props.showIcon && !spinner ? <i className="fa fa-times fa-fw" /> : null;
	const isDisabled = props.isDisabled || isProcessing || props.isProxy;

	const btnClassName = `${props.className || ""} ${
		classes.button
	} btn btn-${props.size || ""} btn-${props.type || "default"}`;

	return (
		<span>
			<button
				className={btnClassName}
				type="button"
				onClick={action}
				disabled={isDisabled}
			>
				{spinner} {icon} {buttonText}
			</button>
			{helpBlock}
		</span>
	);
};

BidCancelButton.propTypes = {
	bid: t.object.isRequired,
	onCancelBid: t.func,
	showIcon: t.bool,
	size: t.string,
	type: t.string,
	isDisabled: t.bool,
	cancelAll: t.bool
};

export default useSheet(styles)(BidCancelButton);
