import React from "react";
import { Modal } from "react-bootstrap";
import CancelButton from "./button";
import { UserDisplay } from "@civicsource/users";
import formatBid from "../../bid/amount/format-bid";
import useSheet from "react-jss";
import styles from "./modal.style";
import moment from "moment";
import { compose, withHandlers } from "recompose";

const handlers = withHandlers({
	handleChange: ({ onReasonChange }) => ev => {
		onReasonChange(ev.target.value);
	}
});

const BidCancelModal = ({ classes, bid, auction, handleChange, ...props }) => {
	// only show if the bid is not being canceled, queued for cancel, or failed to queue for cancel
	const isModalOpen =
		props.isModalOpen &&
		!bid.cancellation.isQueued &&
		!bid.cancellation.isNotCanceled &&
		!bid.cancellation.isCanceled;

	const textareaId = `bid-cancel-reason__${bid.id}`;
	const canCancel = props.cancelReason.length > 2;
	const modalTitle = (
		<span>
			Cancel <UserDisplay username={bid.bidder} />
			{"'s bid"}
		</span>
	);
	const isProcessing = bid.cancellation.isQueued || bid.cancellation.isQueuing;
	const auctionEndedWarningText = `
		WARNING: This will only cancel one bid and may award the same bidder with a new, lower price.
		Typically, when an auction is over all of a bidder's bids should be canceled to ensure a new bidder is selected as the winner.
		Are you sure you didn't mean to check "Cancel all bids for this user"?
	`;
	const auctionEndedWarning =
		auction.isEnded && !props.cancelAll ? (
			<div className="text-danger">{auctionEndedWarningText}</div>
		) : null;

	return (
		<Modal animation bsSize="large" onHide={props.onHide} show={isModalOpen}>
			<Modal.Header closeButton>{modalTitle}</Modal.Header>
			<div className="modal-body">
				<div className={classes.scaryText}>
					<div className={classes.extraScaryText}>
						<span className={`h3 ${classes.heading}`}>
							Canceling a bid cannot be undone!
						</span>
						<br />
						<span className={`h1 ${classes.heading}`}>Stop now</span>
						<br />
						<span className={`h3 ${classes.heading}`}>
							If you have even the slightest doubt!
						</span>
					</div>
					<p>
						<span className="text-phrase">
							Development cannot magically undo cancellations,
						</span>{" "}
						<span className="text-phrase">
							so ask <u>before</u> you cancel, not after!
						</span>
					</p>
				</div>
				<dl>
					<dt>Amount</dt>
					<dd>{formatBid(bid.amount, auction.bidType)}</dd>
					<dt>Placed On</dt>
					<dd>{moment(bid.time).format("MMM Do hh:mm:ss A")}</dd>
				</dl>
				<div className="form-group">
					<label className="control-label" htmlFor={textareaId}>
						Cancel reason
					</label>
					<textarea
						className="form-control"
						id={textareaId}
						onChange={handleChange}
						rows={5}
						value={props.cancelReason}
					/>
				</div>
				<div className="checkbox">
					<label htmlFor={`${bid.id}__cancelAll`}>
						<input
							id={`${bid.id}__cancelAll`}
							onChange={props.onToggleCancelAll}
							type="checkbox"
							checked={props.cancelAll}
						/>
						Cancel all bids for this user
					</label>
				</div>
				{auctionEndedWarning}
			</div>
			<div className="modal-footer">
				<button
					className="btn btn-link"
					disabled={isProcessing}
					onClick={props.onHide}
				>
					Don't Cancel Bid
				</button>
				<CancelButton
					className={canCancel ? classes.scaryButton : null}
					bid={bid}
					isDisabled={!canCancel}
					onCancelBid={props.onCancelBid}
					cancelAll={props.cancelAll}
					type="primary"
				/>
			</div>
		</Modal>
	);
};

const styled = useSheet(styles);

export default compose(styled, handlers)(BidCancelModal);
