﻿define(["knockout"], function (ko) {
	//NOTE: the dependency on knockout here is only for the array utility methods
	var t;

	//singleton object that ticks each second calling specified callbacks
	var ticker = {
		callbacks: [],
		subscribe: function (cb) {
			this.callbacks.push(cb);
			start.call(this);
		},
		unsubscribe: function (cb) {
			ko.utils.arrayRemoveItem(this.callbacks, cb);

			if (this.callbacks.length < 1) {
				stop.call(this);
			}
		}
	};

	function start() {
		if (!this.isRunning) {
			this.isRunning = true;
			t = setTimeout(delegate(this, tick), 1000);
		}
	}

	function stop() {
		if (this.isRunning) {
			this.isRunning = false;
			clearTimeout(t);
			this.startTime = null;
			this.nextTickAt = null;
		}
	}

	//need to employ techniques here to avoid timer drift: http://stackoverflow.com/questions/8173580/setinterval-timing-slowly-drifts-away-from-staying-accurate
	function tick() {
		if (this.isRunning) {
			if (!this.startTime) {
				this.startTime = new Date().getTime();
				this.nextTickAt = this.startTime;
			}
			this.nextTickAt += 1000;

			var self = this;
			ko.utils.arrayForEach(this.callbacks, function (cb) {
				if (cb) {
					cb.call(self);
				}
			});

			t = setTimeout(delegate(this, tick), this.nextTickAt - new Date().getTime());
		}
	}

	function delegate(scope, method) {
		return function () {
			return method.call(scope);
		};
	}

	return ticker;
});