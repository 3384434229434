import React from "react";
import moment from "moment";
import formattedTime from "./format-time";
import { HighlightOnChange } from "@civicsource/ui";
import Going from "../going";

import ExtensionHelp from "./extension-help";

const BASE = "auction-time__description";

const AuctionTime = ({ auction, showHelp = false, showGoing = true }) => {
	if (!auction.isBiddingInfoLoaded) {
		return null;
	}

	if (!auction.schedule) {
		return <span>Not scheduled</span>;
	}

	var startTime = moment(auction.schedule.startTime);
	var endTime = moment(auction.schedule.endTime);

	var currentTime = moment();

	var isStarted = currentTime.isAfter(startTime);
	var { isEnded, isOpen } = auction;

	var extendWithHelp = showHelp ? (
		<span>
			<br />
			<ExtensionHelp endTime={auction.schedule.endTime} />
		</span>
	) : null;

	var extendedOrActive = (
		<span>
			Ends {formattedTime(endTime)}
			{extendWithHelp}
		</span>
	);

	var result = null;
	var goingComponent = null;

	if ((isEnded || isOpen) && !!showGoing) {
		goingComponent = (
			<div>
				<Going auction={auction} />
			</div>
		);
	}

	if (!isStarted) {
		result = (
			<span className={`${BASE} ${BASE}--not-started`}>
				Starts {formattedTime(startTime)}
			</span>
		);
	}

	if (isEnded) {
		result = (
			<span className={`${BASE} ${BASE}--ended`}>
				Ended {formattedTime(endTime)} {goingComponent}
			</span>
		);
	}

	if (isOpen) {
		result = (
			<span className={`${BASE} ${BASE}--active`}>
				{extendedOrActive} {goingComponent}
			</span>
		);
	}

	return (
		<div>
			<HighlightOnChange
				subscribeTo={endTime.toString()}
				highlighDuration={5000}
			>
				{result}
			</HighlightOnChange>
		</div>
	);
};

export default AuctionTime;
