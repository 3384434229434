import React from "react";
import MenuItems from "./menu-items";
import useSheet from "react-jss";

const styles = {
	navOverlay: {
		position: "absolute",
		background: "#424242",
		opacity: 0.98,
		right: 0,
		zIndex: -5
	}
};

const NavOverlay = ({ tabItems, search, classes, mapView, user }) => {
	return (
		<div
			className={classes.navOverlay}
			style={mapView ? { paddingTop: "50px" } : {}}
		>
			<div
				className={"container"}
				style={
					!user
						? { maxWidth: search ? null : "700px", paddingBottom: "15px" }
						: {}
				}
			>
				<MenuItems tabItems={tabItems} search={search} user={user} />
			</div>
		</div>
	);
};

export default useSheet(styles)(NavOverlay);
