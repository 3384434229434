import { configure } from "@civicsource/logger";
import pkg from "../../package";
import { getCurrentUser } from "@civicsource/users";

export default function(state) {
	const user = getCurrentUser(state);
	_configure(user);
}

export function configureLegacyLogging(user) {
	// legacy entry points don't have a store setup and get the user from payload data
	_configure(user);
}

function _configure(user) {
	configure({
		accessToken: process.env.ROLLBAR_ACCESS_TOKEN,
		reportLevel: process.env.LOG_LEVEL,
		payload: {
			environment: process.env.ENVIRONMENT,
			client: {
				javascript: {
					source_map_enabled: true,
					guess_uncaught_frames: true,
					code_version: pkg.version
				}
			},
			person: user
		}
	});
}
