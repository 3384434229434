import cost, * as fromCost from "./cost";
import registerNotifications from "./notification";
import bidderListTerms, * as fromBidderListTerms from "./user/bidder-list-terms";
import checkout, * as fromCheckout from "./checkout";
import auction, * as fromAuction from "./auction";

import { registerReducer } from "redux-rsi";

import { combineReducers } from "redux-seamless-immutable";

const TREE_ROOT = "auctioneer";

registerReducer(
	TREE_ROOT,
	combineReducers({
		cost,
		bidderListTerms,
		checkout,
		auction
	})
);

registerNotifications();

export function getCostsForAuction(state, lotNumber) {
	return fromCost.getCostsForAuction(state[TREE_ROOT].cost, lotNumber);
}

export function getUsersCurrentBidderListTermsByBidderListId(
	state,
	bidderListId
) {
	return fromBidderListTerms.getUsersCurrentBidderListTermsByBidderListId(
		state[TREE_ROOT].bidderListTerms,
		bidderListId
	);
}

export function getCheckoutByUsername(state, username) {
	return fromCheckout.getCheckoutByUsername(
		state[TREE_ROOT].checkout,
		username
	);
}

export function getAuctionByLotNumber(state, lotNumber) {
	return fromAuction.getAuctionByLotNumber(state[TREE_ROOT].auction, lotNumber);
}
