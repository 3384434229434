﻿define(["knockout", "./pager",
"knockout.punches",
"knockout-template?name=pager!html?-minimize!./pager.html",
"knockout-template?name=pager-links!html?-minimize!./pager-links.html",
"knockout-template?name=pager-legacy!html?-minimize!./pager-legacy.html",
"knockout-template?name=pager-links-legacy!html?-minimize!./pager-links-legacy.html",
"./../less/_pager.less"], function (ko, Pager) {
	ko.punches.enableAll();

	ko.bindingHandlers.pager = {
		init: function () {
			return { controlsDescendantBindings: true };
		},
		update: function (el, valueAccessor, allBindingsAccessor) {
			var model = ko.utils.unwrapObservable(valueAccessor());
			var isLegacy = ko.utils.unwrapObservable(allBindingsAccessor().legacyPager);
			var description = ko.utils.unwrapObservable(allBindingsAccessor().description);

			if (description === undefined) {
				description = "";
			}

			model.description(description);

			var templateName = isLegacy ? "pager-legacy" : "pager";
			ko.renderTemplate(templateName, model, null, el, "replaceChildren");
		}
	};

	ko.virtualElements.allowedBindings.pager = true;

	return Pager;
});