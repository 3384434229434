import ko from "knockout";
import init from "knockout.init";

function PageAlert(data) {
	if (!data.templateName && !data.subject) {
		throw new TypeError(
			"If you don't specify a template, alert expects to use a default template which requires 'subject'."
		);
	}
	if (!data.date) {
		throw new TypeError(
			"PageAlert expects a date for sorting alerts as they are supposed to be time-sensitive. If you don't have one pass an empty value to let it fall to the bottom."
		);
	}

	var model = init(this, data, {
		dates: ["date"]
	});

	model.isDefaultTemplate = ko.computed(function() {
		return !this.templateName || !this.templateName();
	}, model);

	return model;
}

export default PageAlert;
