import ko from "knockout";
import "knockout.integer";

function BidCount() {
	var bidCount = ko.observable().extend({
		integer: true
	});

	bidCount.friendly = ko.computed(function() {
		var count = bidCount(),
			formatted = bidCount.formatted();
		return `${formatted} bid${count !== 1 ? "s" : ""}`;
	});

	return bidCount;
}

export default BidCount;
