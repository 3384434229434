define(["jquery"], function ($) {
	//inspired by http://jehiah.cz/a/ntp-for-javascript

	var URL = "/time/sync";

	var requiredResponses = 2;
	var resyncTime = 10; //minutes

	var server = {
		times: []
	};

	function sync(onSuccess) {
		if (server.deferred && server.deferred.state() === "pending") {
			//still have a pending request going on
			return server.deferred.promise();
		}

		// if the time was set within the last x minutes; ignore this set request; time was synce recently enough
		if (server.offset && server.timestamp) {
			var diffInMilliseconds = fixTime() - parseInt(server.timestamp, 10);
			var resyncTimeInMilliseconds = 60000 * resyncTime;

			if (diffInMilliseconds < resyncTimeInMilliseconds) {
				//just return the existing promise
				return server.deferred.promise();
			}
		}

		server.times = [];
		server.deferred = $.Deferred();
		getServerTime(server.deferred);

		return server.deferred.promise();
	}

	function getNow() {
		var date = new Date();
		return date.getTime();
	}

	function getServerTime(promise) {
		$.ajax({
			url: URL,
			data: {
				t: getNow()
			}
		}).done(function (data) {
			var offset = parseInt(data.split(":")[0], 10);
			var origtime = parseInt(data.split(":")[1], 10);
			var delay = ((getNow() - origtime) / 2);

			offset = offset - delay;
			server.times.push(offset);

			// if we have enough responces set cookie
			if (server.times.length >= requiredResponses) {
				// build average
				var average = 0;

				var i = 0;
				for (i = 0; i < server.times.length; i++) {
					average += server.times[i];
				}

				average = Math.round(average / i);

				server.offset = average; // set the new offset
				server.timestamp = fixTime(); // save the timestamp that we are setting it

				promise.resolve(fixTime);
			}
			else {
				getServerTime(promise);
			}
		}).fail(promise.reject);
	}

	function fixTime(timeStamp) {
		if (!timeStamp) {
			timeStamp = getNow();
		}

		var offset = parseInt(server.offset || 0, 10);

		if (isNaN(offset)) {
			return timeStamp;
		}

		return timeStamp + offset;
	}

	return {
		sync: sync,
		fixTime: fixTime
	};
});