import React from "react";
import { WaitingIndicator } from "@civicsource/ui";
import { formatNumber, formatMoney } from "accounting";

import connect from "./connect";

const Cost = ({ quantity, description, amount }) => {
	let amountText = formatMoney(amount);

	return (
		<tr>
			<td className="text-right">{formatNumber(quantity)}</td>
			<td>{description}</td>
			<td className="text-right">{amountText}</td>
		</tr>
	);
};

const CostViewer = ({ isLoading, isLoaded, isError, costs, total }) => {
	if (isLoading) {
		return <WaitingIndicator decorate>Loading Costs</WaitingIndicator>;
	}

	if (isError) {
		return (
			<WaitingIndicator noSpinner>Problem loading costs.</WaitingIndicator>
		);
	}

	if (!isLoaded || !costs) {
		return null;
	}

	if (!costs.length) {
		return <WaitingIndicator noSpinner>No costs to display.</WaitingIndicator>;
	}

	return (
		<table className="table table-condensed">
			<thead>
				<tr>
					<th className="text-right">Quantity</th>
					<th>Description</th>
					<th className="text-right">Cost</th>
				</tr>
			</thead>
			<tbody>
				{costs.map(({ id, ...rest }) => <Cost key={id} {...rest} />)}
			</tbody>
			<tfoot>
				<tr>
					<td />
					<td>Total</td>
					<td className="text-right">
						<strong>{formatMoney(total)}</strong>
					</td>
				</tr>
			</tfoot>
		</table>
	);
};

export default connect(CostViewer);
