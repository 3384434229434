import React from "react";
import { PropTypes as t } from "prop-types";

const BidAmountInputAddon = props => (
	<span className="input-group-addon">{props.isMoney ? "$" : "%"}</span>
);

BidAmountInputAddon.propTypes = {
	isMoney: t.bool.isRequired
};

export default BidAmountInputAddon;
