import { registerNotification } from "@civicsource/ui";

export default function registerNotifications() {
	registerNotification(
		"FETCH_USER_CURRENT_BIDDER_LIST_TERMS_FAILED",
		"Error Loading User's Current Bidder List Terms"
	);
	registerNotification(
		"AGREE_TO_BIDDER_LIST_TERMS_FAILED",
		"Error Agreeing To New Terms"
	);

	registerNotification(
		"CHECKOUT_FOR_USER_FETCH_FAILED",
		"Error Loading Checkout"
	);
	registerNotification("CHECKOUT_MAKE_PAYMENT_FAILED", "Error Making Payment");
	registerNotification("CHECKOUT_MAKE_PAYMENT_ITEMS_CHANGED", {
		headline: "Payment Not Made",
		message:
			"The items in your cart have changed. Please review your new total before submitting payment.",
		type: "warning"
	});

	registerNotification("AUCTION_FETCH_FAILED", "Error Loading Auction");
}
