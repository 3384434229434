import { createReducer, mergeWithCurrent } from "redux-rsi";
import Immutable from "seamless-immutable";
import { normalize } from "../../normalize";
import { withContext } from "../../user-context";

export default createReducer(Immutable({}), {
	onChannelListen(state, { lotNumber }) {
		return mergeWithCurrent(
			state,
			normalize(lotNumber),
			{
				isBiddingInfoLoading: true
			},
			init
		);
	},

	onAuctionBiddingInfoFetch(state, lotNumber) {
		return mergeWithCurrent(
			state,
			normalize(lotNumber),
			{
				isBiddingInfoLoading: true
			},
			init
		);
	},

	onAuctionBiddingInfoFetchCompleted(state, auction, lotNumber) {
		return mergeWithCurrent(
			state,
			normalize(lotNumber),
			defang(auction).merge({
				isBiddingInfoLoading: false,
				isBiddingInfoLoaded: true
			}),
			init
		);
	},

	onAuctionBiddingInfoFetchFailed(state, { message, response }, lotNumber) {
		let result = {
			isBiddingInfoLoading: false,
			isBiddingInfoLoaded: false,
			biddingInfoLoadError: message
		};

		if (response && response.status == 404) {
			result.biddingInfoLoadError = null;
			result.isBiddingInfoMissing = true;
		}

		return mergeWithCurrent(state, normalize(lotNumber), result, init);
	},

	onAuctionBiddingStatusChanged(state, auction) {
		return mergeWithCurrent(
			state,
			normalize(auction.lotNumber),
			defang(auction).merge({
				isBiddingInfoLoading: false,
				isBiddingInfoLoaded: true,
				biddingInfoLoadError: null
			}),
			init
		);
	},

	onBidAccepted(state, auction) {
		return this.onAuctionBiddingStatusChanged(state, auction);
	},

	onBidCanceled(state, auction) {
		return this.onAuctionBiddingStatusChanged(state, auction);
	},

	onUserAuctionsFetchCompleted(state, auctions, userContext) {
		// we need to make sure to store these auctions separately from the rest of the auctions in this identity map
		// the rest of the auctions in this map were loaded from API endpoints and websockets in regards to the current user
		// any userCurrentBid or userNextBidAmount set on these auctions are for the current user
		// however, when loading auctions/bids for another user, these properties will be different and we can't mix/overwrite the auctions

		let result = state;

		auctions.forEach(auction => {
			const key = withContext(auction.lotNumber, userContext);
			const currentAuction = state[key] || init(auction.lotNumber);
			result = result.set(
				key,
				currentAuction.merge(defang(auction, userContext)).merge({
					isBiddingInfoLoading: false,
					isBiddingInfoLoaded: true,
					biddingInfoLoadError: null
				})
			);
		});

		return result;
	}
});

function init(lotNumber) {
	return Immutable({
		lotNumber: normalize(lotNumber),

		isBiddingInfoLoading: false,
		isBiddingInfoLoaded: false,
		biddingInfoLoadError: null,
		isBiddingInfoMissing: false
	});
}

function defang(auction, userContext) {
	return (
		Immutable(auction)
			.without("latestBids")
			.without("canceledBids")
			.without("winningBid")
			.without("userCurrentBid")
			.set(
				"winningBidId",
				auction.winningBid
					? withContext(auction.winningBid.id, userContext)
					: null
			)
			.set(
				"userCurrentBidId",
				auction.userCurrentBid
					? withContext(auction.userCurrentBid.id, userContext)
					: null
			)
			// don't conflict with auction.type that comes from the civicsource API, renaming this one to bidType
			.without("type")
			.set("bidType", auction.type)
			// the sale data from bidding API is just the sale GUID with TA PREFIX prepended
			// ignore that data and use the data from CivicSource API
			.without("sale")
	);
}

export function getBiddingInfo(state, lotNumber) {
	return state[normalize(lotNumber)] || init(lotNumber);
}
