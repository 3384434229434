import { bootstrap } from "toetag";
import tc from "tinycolor2";
import { kebabCase } from "lodash";

const animationName = `${kebabCase(__filename)}-animation-tremble`;

export default {
	[`@keyframes ${animationName}`]: {
		"33%": {
			transform: "translateX(2px)"
		},
		"66%": {
			transform: "translateX(-2px)"
		},
		"100%": {
			transform: "translateX(0px)"
		}
	},
	scaryText: {
		textAlign: "center",
		fontWeight: "bold"
	},
	extraScaryText: {
		color: tc(bootstrap.brandDanger)
			.saturate(100)
			.toString(),
		textTransform: "uppercase"
	},
	heading: {
		fontWeight: "bold"
	},
	scaryButton: {
		"&:hover": {
			animation: `${animationName} 0.125s infinite alternate`
		}
	}
};
