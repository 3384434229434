import React from "react";
import BidStatsMain from "./main";

const BidStats = ({ isLoading, isError, isLoaded, auctions, ...props }) => {
	if (isLoading) {
		return <span>Loading user's bids…</span>;
	}

	if (isError) {
		return <span>Error loading user's bids.</span>;
	}

	if (!isLoaded) return null;

	const bids = auctions.map(auction => ({
		...auction.userCurrentBid,
		auction
	}));

	return <BidStatsMain bids={bids} {...props} />;
};

export default BidStats;
